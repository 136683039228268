import {createSlice} from '@reduxjs/toolkit'
import {GetCurrentUser, maskCardNumber} from '../../utils/DefaultFunctions'
import {STRINGS} from '../../utils/Enums'
import {API} from '../../utils/services'
import moment from 'moment'

let user = GetCurrentUser()

export const uploadFileRequest = (formData, processId, type, token) => (dispatch) => {
  let url = ''
  if (type == 'shred') {
    url = `/Card/${processId}/discard/Upload`
  }
  if (type == 'sendToOrganization') {
    url = `/Card/${processId}/sendtoorganization/Upload`
  }
  if (type == 'sendToCfc') {
    url = `/Card/${processId}/sendTocfc/Upload`
  }
  if (type == 'sendToOtherCfc') {
    url = `/Card/${processId}/sendTootherbankcfc/Upload`
  }
  if (type == 'deliverToCustomer') {
    url = `/Card/${processId}/delivertocustomer/Upload`
  }
  if (type == 'sendToBranch') {
    url = `/Card/${processId}/sendtobranch/Upload`
  }

  url &&
    API.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      console.log('RESPONSEE Deliver TO customer ', res)
    })
}

export const startProcess = (data) => (dispatch) => {
  dispatch(requestPending(true))
  API.post(`/api/ProcessRun/Start`, data)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessRunStart(res.data))
        alert(res.data.message)
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
      dispatch(requestPending(false))
    })
}

export const getProcessById = (id) => (dispatch) => {
  API.get(`/api/ProcessFile/${id}`)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessFilesList(res.data))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const getProcessDetailById = (id) => (dispatch) => {
  API.get(`/api/ProcessRun/${id}`)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessDetails(res.data))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const getProcessRunsById = (id) => (dispatch) => {
  API.get(`/api/ProcessRuns/${id}`)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessRunsList(res.data))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}
export const GetAllDashboardData = (api_token, captBranchId) => (dispatch) => {
  if (captBranchId) {
    API.get(`/Card/dashboardBranchData?branchId=${captBranchId}`, {
      headers: {
        Authorization: `Bearer ${api_token}`,
      },
    })
      .then((res) => {
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
          dispatch(setDashboardDataList(res.data.result.$values))
        }
      })
      .catch((ex) => {
        alert('Something went wrong')
      })
  }
  if (captBranchId === undefined) {
    API.get(`/Card/dashboardCFCData`, {
      headers: {
        Authorization: `Bearer ${api_token}`,
      },
    })
      .then((res) => {
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
          dispatch(setDashboardDataList(res.data.result.$values))
        }
      })
      .catch((ex) => {
        alert('Something went wrong')
      })
  }
}
export const GetAllDashboardDataChecker = (api_token, captBranchId) => (dispatch) => {
  if (captBranchId) {
    API.get(`/Card/dashboardForApprovalData?branchId=${captBranchId}`, {
      headers: {
        Authorization: `Bearer ${api_token}`,
      },
    })
      .then((res) => {
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
          dispatch(setDashboardDataList(res.data.result.$values))
        }
      })
      .catch((ex) => {
        alert('Something went wrong')
      })
  }
}
export const GetAllDashboardBranches = (api_token, captBranchId) => (dispatch) => {
  API.get(`/Branch/getCurrentUserBranches`, {
    headers: {
      Authorization: `Bearer ${api_token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setDashboardBranchDataList(res.data.result.$values))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const CardApprovalModal = (note, isApproved, cardId, token) => (dispatch) => {
  let url = ''

  if (isApproved) {
    url = `/Card/process/approve`
  } else {
    url = `/Card/process/reject`
  }

  API.put(
    url,
    {
      note: note,
      capturedCardId: cardId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => {
      // console.log(res)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(refreshApprovalApi())
        return true
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}
export const ForApproval = (token, current_id) => (dispatch) => {
  API.get(`/Card/forapproval?branchId=${current_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setForApproval(res.data.result.data))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const GetHoliday = (token, bankId) => (dispatch) => {
  API.get(`/Holiday/getByBankId?BankId=${bankId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        // dispatch(setForApproval(res.data.result.data))
        // console.log(res?.data?.result?.$values, 'respsonse')
        dispatch(setCalenderData(res?.data?.result?.$values))
      }
    })
    .catch((ex) => {
      console.log(ex, 'Something went wrong')
    })
}

export const GetBankById = (token, bankId) => (dispatch) => {
  API.get(`/Bank/getById?BankId=${bankId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res.data.success, 'response bank')
      if (res.data.success) {
        dispatch(
          setWeekEndHoliday({
            isSaturdayOff: res.data.result.isSaturdayOff,
            isSundayOff: res.data.result.isSundayOff,
          })
        )
      }
    })
    .catch((ex) => {
      console.log(ex, 'Something went wrong')
    })
}

export const GetBranchById = (token, branchId) => (dispatch) => {
  API.get(`/Branch/getById?BranchId=${branchId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res.data.success, 'response bank')
      if (res.data.success) {
        dispatch(
          setWeekEndHoliday({
            isSaturdayOff: res.data.result.isSaturdayOff,
            isSundayOff: res.data.result.isSundayOff,
          })
        )
      }
    })
    .catch((ex) => {
      console.log(ex, 'Something went wrong')
    })
}

export const GetBranchHoliday = (token, branchId) => (dispatch) => {
  API.get(`/Holiday/getByBranchId?BranchId=${branchId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        const holidays = res?.data?.result?.$values.filter((item) => !item.isHolidayExcluded)
        dispatch(setCalenderData(holidays))
      }
    })
    .catch((ex) => {
      console.log(ex, 'Something went wrong')
    })
}

export const GetBankHolidayById = (token, bankId) => (dispatch) => {
  API.get(`/Holiday/getByBankId?BankId=${bankId}`)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        // dispatch(setForApproval(res.data.result.data))
        // console.log(res, 'respsonse GetBankHolidayById')
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const CreateHoliday = (token, payload) => (dispatch) => {
  API.post(`/Holiday/Create`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        if ('branchId' in payload) {
          dispatch(GetBranchHoliday(token, payload.branchId))
        } else {
          dispatch(GetHoliday(token, 'ZNJBaQBMy'))
        }
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const GetBranchHolidayById = (token, branchId) => (dispatch) => {
  API.get(`/Holiday/getByBranchId?BranchId=${branchId}`)
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        // dispatch(setForApproval(res.data.result.data))
        // console.log(res, 'respsonse GetBranchHolidayById')
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const DeleteHoliday = (token, holidayId) => (dispatch) => {
  API.delete(`/Holiday/HardDelete?HolidayId=${holidayId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(deleteHoliday(holidayId))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const ExcludeBranchHoliday = (token, holidayId, branchId) => (dispatch) => {
  API.put(`/Holiday/exclude`, {
    holidayId: holidayId,
    branchIds: [branchId],
  })
    .then((res) => {
      // console.log('res', res)
      if (res.data.success) {
        dispatch(GetBranchHoliday(token, branchId))
      }
    })
    .catch((err) => {
      console.log(err, 'err')
    })
}

export const DeleteBranchHoliday = (token, holidayId) => (dispatch) => {
  API.delete(`/Holiday?HolidayId=${holidayId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(deleteHoliday(holidayId))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const confirmation = (payload) => async (dispatch) => {
  dispatch(confirmDialog(payload))
}
export const openSnackBar = (payload) => async (dispatch) => {
  dispatch(setSnackBar(payload))
}
export const resetUploadResponse = (payload) => async (dispatch) => {
  dispatch(resetUploadFileResponse(payload))
}
export const resetRunProcessStart = (payload) => async (dispatch) => {
  dispatch(resetProcessRunStart(payload))
}
export const resetPFilesList = (payload) => async (dispatch) => {
  dispatch(resetProcessFilesList(payload))
}

export const resetDashboard = (payload) => async (dispatch) => {
  dispatch(resetDashboardData(payload))
}

export const GetAllBpcData = (token, branchId) => (dispatch) => {
  API.get(`/Card/bpc?branchId=${branchId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    const data = res.data.result.$values?.map((element, index) => {
      return {
        ...element,
        SNo: index + 1,
        // cardNumber: maskCardNumber(element.cardNumber),
      }
    })

    dispatch(setBpcData(data))
  })
}

export const GetAllBpcDataCfc = (token, branchId) => (dispatch) => {
  let url = `/Card/bpc`
  if (branchId) {
    url = `/Card/bpc?branchId=${branchId}`
  }
  API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    const data = res.data.result.$values?.map((element, index) => {
      return {
        ...element,
        SNo: index + 1,
        // cardNumber: maskCardNumber(element.cardNumber),
      }
    })

    dispatch(setBpcData(data))
  })
}

const initialState = {
  DashboardData: [],
  DashboardBranchesData: [],
  isError: false,
  message: '',
  variant: '',
  selectedId: '',
  isPending: false,
  startProcessSuccess: false,
  // ---confirmation---
  confirmationText: '',
  openConfirmationDialog: false,
  alertIcon: '',
  alertBtnText: '',
  showCancelButton: false,
  refreshApi: false,
  //
  fileUploadFailed: false,
  fileUploadSuccess: null,
  processFileIds: [],
  processFilesLists: [],
  processRunsList: [],
  processDetails: {},
  forApprovalData: [],
  snackProps: {
    open: false,
    duration: 4000,
    message: '',
    action: false,
    actionLbl: 'Submit',
  },
  calendarData: [],
  bpcData: [],
  weekend: {
    isSaturdayOff: false,
    isSundayOff: false,
  },
}

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setDashboardDataList: (state, {payload}) => {
      state.DashboardData = payload
    },
    setDashboardBranchDataList: (state, {payload}) => {
      state.DashboardBranchesData = payload
    },
    setForApproval: (state, {payload}) => {
      state.forApprovalData = payload.$values
    },
    refreshApprovalApi: (state, {payload}) => {
      state.refreshApi = true
    },
    confirmDialog: (state, {payload}) => {
      state.confirmationText = payload.message
      state.openConfirmationDialog = payload.open
      state.alertIcon = payload.alertIcon
      state.showCancelButton = payload.showCancelButton
      state.alertBtnText = payload.alertBtnText
    },
    ErrorAlert: (state, {payload}) => {
      state.isError = true
    },
    requestPending: (state, {payload}) => {
      state.isPending = payload
    },
    selectStatus: (state, {payload}) => {
      state.selectedBox = payload
    },
    setDeviceIp: (state, {payload}) => {
      state.deviceIPAddress = payload.data.ip
    },
    setProcessDetails: (state, {payload}) => {
      state.processDetails = payload.data
    },
    uploadFileSuccess: (state, {payload}) => {
      state.fileUploadSuccess = true
      state.fileData = payload.data
      state.selectedId = payload.selectedId
      state.processFileIds = [
        ...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId),
        {
          process: payload.data.data,
          selected_id: payload.selectedId,
        },
      ]
    },
    resetApprovalData: (state, {payload}) => {
      state.forApprovalData = []
    },
    setProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload.data
    },
    resetProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload
      state.processFileIds = payload
    },
    setProcessRunsList: (state, {payload}) => {
      state.processRunsList = payload.data
    },
    setProcessRunStart: (state, {payload}) => {
      state.isPending = false
      state.startProcessSuccess = true
    },
    resetProcessRunStart: (state, {payload}) => {
      state.startProcessSuccess = false
    },
    uploadBulkFilesSuccess: (state) => {
      state.fileBulkUploadSuccess = true
    },
    resetUploadFileResponse: (state, {payload}) => {
      state.fileUploadFailed = payload.failed
      state.fileUploadSuccess = null
      state.PO_ID = null
    },
    resetUploadBulkFilesResponse: (state) => {
      state.fileBulkUploadSuccess = false
    },
    setGeneratedOtp: (state, {payload}) => {
      state.otp_generated = payload === 0
    },
    setVerifiedOtp: (state, {payload}) => {
      state.otp_verified = payload === 0
    },
    resetDashboardData: (state, {payload}) => {
      state.DashboardData = []
    },
    resetOtp: (state) => {
      state.otp_verified = null
      state.otp_generated = null
    },
    setSnackBar: (state, {payload}) => {
      state.snackProps = {
        open: payload.open,
        message: payload.message,
        duration: payload.duration === undefined ? 4000 : state.snackProps.duration,
        action: payload.action !== undefined ? payload.action : state.snackProps.action,
        actionLbl: payload.actionLbl !== undefined ? payload.actionLbl : state.snackProps.actionLbl,
      }
    },
    setCalenderData: (state, {payload}) => {
      // let newPayload = [...payload]
      const newPayload = payload.map((el) => {
        if (el.calendarDayFrom === el.calendarDayTo) {
          return {
            title: el.holidayName,
            start: el.calendarDayFrom,
            end: el.calendarDayTo,
            description: el.description,
            id: el.id,
            branch: el.branch ? el.branch : null,
          }
        } else {
          const newDate = moment(el.calendarDayTo).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
          // console.log(newDate)
          return {
            title: el.holidayName,
            start: el.calendarDayFrom,
            end: newDate,
            description: el.description,
            id: el.id,
            branch: el.branch ? el.branch : null,
          }
        }
      })
      // console.log(newPayload, 'newPayload')
      state.calendarData = newPayload
    },
    deleteHoliday: (state, {payload}) => {
      const newCalendarData = state.calendarData.filter((el) => el.id !== payload)
      state.calendarData = newCalendarData
    },
    setWeekEndHoliday: (state, {payload}) => {
      state.weekend = payload
    },
    resetWeekendHoliday: (state, {payload}) => {
      state.weekend = {
        isSaturdayOff: false,
        isSundayOff: false,
      }
    },
    setBpcData: (state, {payload}) => {
      state.bpcData = payload
    },
  },
})

export const {
  requestPending,
  selectStatus,
  confirmDialog,
  setSnackBar,
  uploadFileSuccess,
  resetUploadFileResponse,
  uploadBulkFilesSuccess,
  resetUploadBulkFilesResponse,
  setVerifiedOtp,
  setProcessFilesList,
  resetProcessFilesList,
  setProcessRunsList,
  setProcessRunStart,
  resetProcessRunStart,
  setProcessDetails,
  setDashboardDataList,
  resetApprovalData,
  setDashboardBranchDataList,
  resetOtp,
  setForApproval,
  refreshApprovalApi,
  resetDashboardData,
  setCalenderData,
  deleteHoliday,
  setBpcData,
  setWeekEndHoliday,
} = globalSlice.actions

export default globalSlice.reducer
