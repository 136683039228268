import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {addCardInventorySchema, sameBank} from '../../../../../schemas'
import WhiteLabel from './items/WhiteLabel'
import OtherBank2 from './items/OtherBank'
import CardSearch from '../../../../SharedComponent/CardSearch/CardSearch'
import {useDispatch, useSelector} from 'react-redux'
import {
  AddCardBranch,
  CardChannel,
  CardStatus,
  CardType,
  NativeBranch,
  ResetState,
} from '../../BranchSlice/branch.slice'
import {
  convertISODateWithSlashed,
  formatAMPM,
  GetCurrentUser,
} from '../../../../../utils/DefaultFunctions'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {API} from '../../../../../utils/services'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../../../utils/Enums'
// import DatePicker from '../../../../SharedComponent/DatePicker'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import '../../../Branch/items/index.css'
import OtherOrganization from './items/OtherOrganization'

const AddFormInventory = () => {
  const [init, setInit] = useState({
    tabs: [
      'Same Bank',
      'Other Bank',
      'Suspicious Card (Suspicious, White Label...)',
      'Other Organization (Jazzcash, Easy Paisa, Benazir Income, etc...)',
    ],
    selectedTab: 0,
  })

  const notifyError = (value) =>
    toast.error(value, {
      position: 'bottom-right',
      theme: 'colored',
    })
  const navigate = useNavigate()
  const {branchSlice} = useSelector(({Reducers}) => Reducers)

  const initialValues = {
    acq_bank: '',
    acq_branch: '',
    note: '',
    // cardNumber: '',
    // number: '',
    customerName: '',
    expiry: '',
    cardType: '',
    cif: '',
    cardStatus: '',
    captureReason: 'Anonymous',
    issueBank: '',
    issueBranch: '',
    captureDate: '',
  }

  var cityList = []

  const [acquirerBranch, setAcquirerBranch] = useState('')
  const [acquirerBank, setAcquirerBank] = useState('')
  const [acquirerCity, setAcquirerCity] = useState('')
  const [number, setNumber] = useState('')
  const [cardTypes, setCardTypes] = useState()
  const [cardChannels, setCardChannels] = useState()
  const [cardChannelValue, setCardChannelValue] = useState()
  const [currentCardTypeId, setCurrentCardTypeId] = useState()
  const [currentCardChannelId, setCurrentCardChannelId] = useState()
  const [expiryState, setExpiryState] = useState()
  const [cardStatus, setCardStatus] = useState()
  const [currentCardStatusId, setCurrentCardStatusId] = useState('')
  const [issuerBranch, setIssuerBranch] = useState([])
  const [value, setValue] = useState()
  const [cities, setCities] = useState([])
  const [currentNativeBranchId, setCurrentNativeBranchId] = useState('')
  const [issuerCity, setIssuerCity] = useState('')
  let current_id = new URL(window.location).searchParams.get('id')

  let dispatch = useDispatch()

  let user = GetCurrentUser().api_token

  const {tabs, selectedTab} = init

  // const [date, setDate] = useState(dayjs())
  const [date, setDate] = useState(dayjs().format())
  const [dateSelected, setDateSelected] = useState(false)
  const [convertedDate, setConvertedDate] = useState(dayjs('2023-01-01T00:00:00'))

  const handleDateChange = (newValue) => {
    setDateSelected(true)
    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let selectedTime = formatAMPM(new Date(newValue))
    let convertedDateTime = new Date(`${selectedDate} ${selectedTime} UTC`).toISOString()
    setDate(newValue)
    setConvertedDate(convertedDateTime)
  }

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: sameBank,
    onSubmit: (values) => {
      console.log('values', values)
    },
  })

  cities.forEach((item) => {
    cityList.push(item.city)
  })

  useEffect(() => {
    localStorage.setItem('selectedTab', 0)
    const ApiCalls = async () => {
      if (user !== null) {
        try {
          const res = await API.get(`/City`, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          setCities(res.data.result.data.$values)
        } catch (error) {
          notifyError('Runtime Error Login Again')
        }
        // API.get('/City', {
        //   headers: {
        //     Authorization: `Bearer ${user}`,
        //   },
        // }).then((res) => {
        //   setCities(res.data.result.data.$values)
        // })

        API.get('/Card/cardtypes', {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
          .then((res) => {
            setCardTypes(res.data.result.$values)
          })
          .catch((err) => {
            console.log(err, 'err')
          })

        API.get('/Card/cardchannels', {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
          .then((res) => {
            setCardChannels(res.data.result.$values)
          })
          .catch((err) => {
            console.log(err, 'err')
          })

        API.get('/Card/cardstatus', {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
          .then((res) => {
            setCardStatus(res.data.result.$values)
          })
          .catch((err) => {
            console.log(err, 'err')
          })

        let body = {
          pageNumber: 1,
          pageSize: 100,
        }

        API.post('/Branch/Search', body, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
          .then((res) => {
            setIssuerBranch(
              res.data.result.data.$values.sort(function (a, b) {
                if (a.branchName.toLowerCase() < b.branchName.toLowerCase()) return -1
                if (a.branchName.toLowerCase() > b.branchName.toLowerCase()) return 1
                return 0
              })
            )
          })
          .catch((err) => {
            console.log(err, 'err')
          })
      }
    }
    ApiCalls()
  }, [])

  useEffect(() => {
    ResetState()
    API.post(
      '/Branch/Search',
      {
        pageNumber: 1,
        pageSize: 100,
      },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    )
      .then((res) => {
        res.data.result.data.$values.forEach((element) => {
          if (element.id === current_id) {
            setAcquirerBranch(element.branchName)
            setAcquirerCity(element.city)
            setAcquirerBank(element.bank)
          }
        })
      })
      .catch((err) => {
        console.log(err, 'err')
      })

    currentCardTypeId && dispatch(CardType(currentCardTypeId))
    dispatch(CardStatus(currentCardStatusId))
    currentNativeBranchId && dispatch(NativeBranch(currentNativeBranchId))
    currentCardChannelId && dispatch(CardChannel(currentCardChannelId))
  }, [
    currentCardTypeId,
    currentCardStatusId,
    currentNativeBranchId,
    branchSlice.cardTypeId,
    currentCardChannelId,
  ])

  useEffect(() => {
    return () => {
      dispatch(NativeBranch(''))
      dispatch(CardStatus(''))
    }
  }, [])

  const formSubmit = (values) => {
    let {acq_bank, note, captureReason, customerName, expiry, cif, cnic} = values
    let {cardTypeId, cardStatusId, nativeBranchId, cardChannelId} = branchSlice
    // const isoDate = date.toISOString()

    let body = {
      convertedDate,
      current_id,
      acquirerBank,
      customerName,
      number,
      expiryState: expiryState?.toString(),
      cif,
      cnic,
      cardTypeId,
      cardStatusId,
      nativeBranchId,
      cardChannelId,
      captureReason,
      note,
      user,
    }

    //validation for date
    if (!dateSelected) {
      toast.error('Select Captured Date!', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for card number
    if (!number) {
      toast.error('Card number is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (!(number?.length == 16) && !(number?.length == 20)) {
      toast.error('Card Number must be of 16 or 20 digits', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }
    //validation for customer name
    if (!customerName) {
      toast.error('Customer name is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validations for card expiry
    if (!expiryState) {
      toast.error('Card Expiry is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length !== 4) {
      toast.error('Card Expiry is not correct', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length == 4) {
      const firstTwoChars = expiryState?.slice(0, 2)
      if (firstTwoChars == '00') {
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
      if (Number(firstTwoChars) > 12) {
        console.log('ttt')
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
    }

    //validation for issuer branch
    if (!nativeBranchId) {
      toast.error('Issuer branch is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation is card status
    if (!cardStatusId) {
      toast.error('Card Status is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (!note) {
      toast.error('Notes is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    dispatch(AddCardBranch(body))
    ResetState()
    //TODO: seperate validations for card num, customer number, card expiry, issuer branch,
    // card status, notes and date
    // if (
    //   dateSelected &&
    //   note &&
    //   customerName &&
    //   expiryState?.length == 4 &&
    //   nativeBranchId &&
    //   (number?.length === 16 || number?.length === 20)
    // ) {
    //   //TODO: check if expiry states first two characters are not 00
    //   console.log('if blokc')
    //   const firstTwoChars = expiryState?.slice(0, 2)

    //   if (firstTwoChars != '00') {
    //     dispatch(AddCardBranch(body))
    //   } else {
    //     toast.error('Month must be between one and twelve', {
    //       position: 'bottom-right',
    //       theme: 'colored',
    //     })
    //   }
    // } else {
    //   toast.error('Fill all mandatory fields!', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    // }

    // if (!note || !customerName || !number || !expiryState || !cardStatusId || !nativeBranchId) {
    //   toast.error('Fill all mandatory fields!', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    // }
  }

  useEffect(() => {
    if (branchSlice.cardAddResult) {
      toast.success('Card Added Successfully', {
        position: 'bottom-right',
        theme: 'colored',
      })

      navigate(`${STRINGS.ROUTES.BRANCH.MAIN}?id=${current_id}`)
      //reset func
    }
  }, [branchSlice.cardAddResult])

  const onChange = (event) => {
    setValue(event.target.value)
  }

  const onSearch = (searchTerm) => {
    setValue(searchTerm)
  }

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  const handleChangeExpiry = (e) => {
    setExpiryState(e.target.value)
    if (e.target.value.length !== 4) {
      setCurrentCardStatusId('')
    }
    const currentDate = new Date()
    if (e.target.value.length === 4) {
      const userInputMonth = parseInt(e.target.value.substring(0, 2))
      const userInputYear = parseInt('20' + e.target.value.substring(2))
      if (
        userInputYear < currentDate.getFullYear() ||
        (userInputYear == currentDate.getFullYear() && userInputMonth < currentDate.getMonth() + 1)
      ) {
        setCurrentCardStatusId('rL6BbdV1e')
      } else {
        setCurrentCardStatusId('')
      }
    }
  }

  return (
    <div id='kt_app_content' class='app-content flex-column-fluid'>
      {/*<!--begin::Content container-->*/}
      <div id='kt_app_content_container' class='app-container container-xx'>
        {/*<!--begin::Navbar-->*/}
        <div class='card mb-6 mb-xl-9'>
          <div class='card-body pt-9 pb-0'>
            {/*<!--begin::Details-->*/}
            <div class='d-flex flex-wrap flex-sm-nowrap mb-6'>
              {/*<!--begin::Nav-->*/}
              <ul class='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {/*<!--begin::Nav item-->*/}
                {tabs?.map((tb, index) => {
                  return (
                    <li
                      class='nav-item'
                      onClick={() => {
                        setInit({
                          ...init,
                          selectedTab: index,
                        })
                      }}
                    >
                      <div
                        class={`nav-link text-active-primary py-5 me-6 ${
                          selectedTab === index && 'active'
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_1'
                        aria-selected='false'
                        role='tab'
                        tabindex='-1'
                      >
                        {tb}
                      </div>
                    </li>
                  )
                })}
              </ul>
              {/*<!--end::Nav-->*/}
            </div>
            {/*<!--end::Details-->*/}
          </div>
        </div>
        {/*<!--end::Navbar-->*/}
        {/*<!--begin::Row-->*/}
        {/*<!--Mytabcontent start-->*/}
        <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}
          {selectedTab === 0 && (
            <div
              className='container me-3'
              style={{backgroundColor: 'white', border: '1px solid #E4E4E4', borderRadius: '10px'}}
            >
              <div className='my-5' style={{marginLeft: '-2rem', padding: '15px'}}>
                <form
                  class='form fv-plugins-bootstrap5 fv-plugins-framework'
                  onSubmit={handleSubmit}
                >
                  <div className='d-flex mb-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Capture Date / Time</strong>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DateTimePicker
                            // label='Date&Time picker'
                            maxDate={date}
                            minDate={minDate}
                            className='form-control form-control-lg form-control-solid'
                            value={date}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Card Number</strong>
                      </label>
                      <input
                        type='number'
                        class='form-control form-control-solid'
                        name='number'
                        id='number'
                        maxLength={20}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 20) {
                            e.preventDefault()
                          }
                        }}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder='1111 2222 3333 4444 5555'
                        onBlur={handleBlur}
                      ></input>
                      {/* {errors.number && touched.number ? (
                        <p className='form-error text-danger my-3'>{errors.number}</p>
                      ) : null} */}
                    </div>
                  </div>

                  <div className='d-flex my-10'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Customer Name</strong>
                      </label>
                      <input
                        type='text'
                        name='customerName'
                        id='customerName'
                        value={values.customerName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        class='form-control form-control-lg form-control-solid'
                        placeholder=' Muhammad Juanid Ahmed'
                        // onChange={(e) => setCustomerName(e.target.value)}
                      />
                      {errors.customerName && touched.customerName ? (
                        <p className='form-error text-danger my-1'>{errors.customerName}</p>
                      ) : null}
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>CNIC</strong>
                      </label>
                      <input
                        type='number'
                        name='cnic'
                        class='form-control form-control-lg form-control-solid'
                        placeholder='4210178912345'
                        maxLength={13}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 13) {
                            e.preventDefault()
                          }
                        }}
                        value={values.cnic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // onChange={(e) => setCnic(e.target.value)}
                      />
                      {errors.cnic && touched.cnic ? (
                        <p className='form-error text-danger my-3'>{errors.cnic}</p>
                      ) : null}
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Card Expiry</strong>
                      </label>
                      <input
                        type='number'
                        // name='expiry'
                        // id='expiry'
                        maxLength={4}
                        onKeyPress={(e) => {
                          if (e.target.value.length === 4) {
                            e.preventDefault()
                          }
                        }}
                        class='form-control form-control-lg form-control-solid'
                        placeholder='0223'
                        value={expiryState}
                        onChange={handleChangeExpiry}
                      />
                    </div>
                  </div>

                  <div className='d-flex my-10'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Acquiring Bank</strong>
                      </label>
                      <input
                        type='text'
                        name='company'
                        class='form-control form-control-lg form-control-solid'
                        placeholder='Bank Name'
                        value={acquirerBank}
                        readOnly
                      />
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Acquiring Branch</strong>
                      </label>
                      <input
                        type='text'
                        name='company'
                        class='form-control form-control-lg form-control-solid'
                        placeholder='Branch Name'
                        value={acquirerBranch}
                        // onChange={(e) => setAcquirerBranch(e.target.value)}
                      />
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Acquiring City</strong>
                      </label>
                      <input
                        type='text'
                        name='company'
                        class='form-control form-control-lg form-control-solid'
                        placeholder='Bank Name'
                        value={acquirerCity}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className='d-flex my-10'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Issuer Bank</strong>
                      </label>
                      <input
                        type='text'
                        name='issueBank'
                        id='issueBank'
                        value={acquirerBank}
                        // onBlur={handleBlur}
                        // onChange={handleChange}
                        class='form-control form-control-lg form-control-solid'
                        placeholder='Bank Name'
                        // onChange={(e) => setIssuerBank(e.target.value)}
                      />
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Issuer Branch</strong>
                      </label>
                      {/* <div style={{ maxHeight: "200px", overflowY: "auto" }}> */}
                      <select
                        name='issueBranch'
                        onChange={(e) => {
                          let temp = e.target.value.split(',')
                          setCurrentNativeBranchId(temp[0])
                          setIssuerCity(temp[1])
                        }}
                        data-select2-id='select2-data-121-2l6u'
                        tabIndex='-1'
                        aria-hidden='true'
                        className='form-select form-select-solid form-select-lg '
                        data-control='select2'
                        id='issuerData'
                      >
                        <option selected hidden>
                          Select Branch
                        </option>
                        {issuerBranch &&
                          issuerBranch.map((item, index) => {
                            return (
                              <>
                                <option value={`${item.id},${item.city}`}>{item.branchName}</option>
                              </>
                            )
                          })}
                      </select>
                      {/* </div> */}
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1 required' className='form-label required'>
                        <strong>Issuer City</strong>
                      </label>
                      <input
                        type='text'
                        value={issuerCity}
                        onChange={onChange}
                        class='form-control form-control-lg form-control-solid'
                      />
                      <div className='dropdown'>
                        {value &&
                          cityList
                            .filter((item) => {
                              const searchTerm = value.toLowerCase()
                              const city = item.toLowerCase()

                              return (
                                searchTerm && city.startsWith(searchTerm) && city !== searchTerm
                              )
                            })
                            .slice(0, 10)
                            .map((item) => (
                              <div
                                onClick={() => onSearch(item)}
                                className='dropdown-row'
                                key={item}
                              >
                                {item}
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>

                  <div className='d-flex my-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Card Type</strong>
                      </label>
                      <select
                        name='cardType'
                        id='cardType'
                        // value={values.cardType}
                        onChange={(e) => {
                          setCurrentCardChannelId(e.target.value)
                        }}
                        // defaultValue={cardChannelValue}
                        // onBlur={handleBlur}
                        // onChange={handleChange}
                        class='form-select form-select-solid'
                        data-control='select2'
                        data-placeholder='Select Card Type'
                        data-hide-search='true'
                      >
                        <option hidden>Select Card Type</option>
                        {cardChannels &&
                          cardChannels.map((item, index) => {
                            return (
                              <>
                                <option value={item.id}>{item.description}</option>
                              </>
                            )
                          })}
                      </select>
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>CIF</strong>
                      </label>
                      <input
                        type='number'
                        onKeyPress={(e) => {
                          console.log(e.target.value.length)
                          if (e.target.value.length === 6) {
                            e.preventDefault()
                          }
                        }}
                        name='cif'
                        id='cif'
                        class='form-control form-control-lg form-control-solid'
                        placeholder='12345'
                        value={values.cif}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // onclick = {() => (change)}
                        // onChange={(e) =>
                        //   setCIFNumber(e.target.value)
                        //   (change)
                        // }
                      />
                    </div>
                  </div>

                  <div className='d-flex my-10'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Reason For Captured</strong>
                      </label>
                      <input
                        type='text'
                        name='captureReason'
                        id='captureReason'
                        value='Anonymous'
                        // onBlur={handleBlur}
                        // onChange={handleChange}
                        readOnly
                        class='form-control form-control-lg form-control-solid'
                        placeholder='Reason For Captured'
                        // onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Card Status</strong>
                      </label>
                      <select
                        name='cardStatus'
                        id='cardStatus'
                        onChange={(e) => setCurrentCardStatusId(e.target.value)}
                        disabled={currentCardStatusId && true}
                        class='form-select form-select-solid'
                        data-control='select2'
                        data-placeholder='Select Card Type'
                        data-hide-search='true'
                      >
                        {currentCardStatusId ? (
                          <option selected hidden>
                            Expired
                          </option>
                        ) : (
                          <option selected hidden>
                            Select Card Status
                          </option>
                        )}
                        {cardStatus &&
                          cardStatus.map((item, index) => {
                            return (
                              <>
                                {!item.description.includes('xpire') && (
                                  <option value={item.id}>{item.description}</option>
                                )}
                              </>
                            )
                          })}
                      </select>
                      {errors.cardStatus && touched.cardStatus ? (
                        <p className='form-error text-danger my-1'>{errors.cardStatus}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className='d-flex ' style={{marginBottom: '5rem'}}>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label required'>
                        <strong>Note</strong>
                      </label>
                      <textarea
                        class='form-control form-control-solid'
                        name='note'
                        id='note'
                        aria-label='With textarea'
                        spellcheck='false'
                        value={values.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></textarea>
                      {errors.note && touched.note ? (
                        <p className='form-error text-danger my-3'>{errors.note}</p>
                      ) : null}
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      values && formSubmit(values)
                    }}
                    type='onSubmit'
                    class='btn btn-primary btn-sm'
                    style={{float: 'right', marginBottom: '2rem'}}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className=' my-3 mb-5'>{/* <CityTable data={country} type="country" /> */}</div>
            </div>
          )}
          {/*<!--endtab1-->*/}
          {/*<!--begintab2-->*/}
          {selectedTab === 1 && (
            <div class='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
              <OtherBank2 />
            </div>
          )}
          {/*<!--endtab2-->*/}
          {/*<!--begintab3-->*/}
          {selectedTab === 2 && (
            <div class='tab-pane fade active show' id='kt_tab_pane_3' role='tabpanel'>
              <WhiteLabel />
            </div>
          )}
          {selectedTab === 3 && (
            <div class='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
              <OtherOrganization />
            </div>
          )}

          {/*<!--end tab3-->*/}
        </div>
        {/*<!--Mytabcontent end-->*/}
        {/*<!--end::Row-->*/}
        {/*<!--begin::Table-->*/}
      </div>
      {/*<!--end::Content container-->*/}
      <ToastContainer />
    </div>
  )
}

export default AddFormInventory
