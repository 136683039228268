import {createSlice} from '@reduxjs/toolkit'
import {API} from '../../../../../utils/services'
import {requestPending} from '../../../../GlobalSlices/global.slice'
import {toast} from 'react-toastify'

const initialState = {
  loginSuccess: false,
  invalid: null,
  token: '',
  invalidMessage: '',
  userType: '',
  statusCode: '',
  refreshToken: '',
}

export const getLogin =
  ({email, password}) =>
  (dispatch) => {
    dispatch(requestPending(true))
    const url =
      process.env.REACT_APP_AD_ENABLED === 'true'
        ? '/Authentication/Ldaplogin'
        : '/Authentication/user'
    API.post(url, {userName: email, password: password})
      .then((res) => {
        // console.log('Response', res)
        if (res.data.success) {
          dispatch(loginDetails(res.data))
          dispatch(requestPending(false))
        }
        if (!res.data.success) {
          dispatch(invalidDetails(res.data))
          dispatch(requestPending(false))
          toast.error('Wrong Credentials', {
            position: 'bottom-right',
            theme: 'colored',
          })
        } else {
          dispatch(requestPending(false))
          toast.error('Something went wrong!!', {
            position: 'bottom-right',
            theme: 'colored',
          })
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

export const resetlogin = () => (dispatch) => {
  dispatch(resetloginDetails())
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginDetails: (state, {payload}) => {
      state.loginSuccess = true
      state.token = payload.result.token
      state.userType = payload.result.userType
      state.statusCode = payload.statusCode
      state.refreshToken = payload.result.refreshToken
    },
    resetloginDetails: (state, {payload}) => {
      state.loginSuccess = false
      state.token = ''
    },
    invalidDetails: (state, {payload}) => {
      state.invalid = false
      state.invalidMessage = 'Invalid Email Password'
    },
  },
})

const {loginDetails, invalidDetails, resetloginDetails} = authSlice.actions

export default authSlice.reducer
