import React, {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {Notifications} from '../../../../app/pages/BranchMain/BranchSlice/branch.slice'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {useAppSelector} from '../../../../utils/hook'
import {API} from '../../../../utils/services'
import {KTSVG} from '../../../helpers'
import {Item1} from '../../content/activity/Item1'
import {Item2} from '../../content/activity/Item2'
import {Item3} from '../../content/activity/Item3'
import {Item4} from '../../content/activity/Item4'
import {Item5} from '../../content/activity/Item5'
import {Item6} from '../../content/activity/Item6'
import {Item7} from '../../content/activity/Item7'
import {Item8} from '../../content/activity/Item8'

const ActivityDrawer = () => {
  let dispatch = useDispatch()
  let user = GetCurrentUser()

  let currentBranchId = localStorage.getItem('branchId')

  const {branchSlice, globalSlice, authSlice} = useAppSelector(({Reducers}) => Reducers)

  let escalations_notifications = []
  const [notifications, setNotifications] = useState([])
  const [escalation, setEscalation] = useState([])
  const [completeEscalation, setCompleteEscalation] = useState()

  useEffect(() => {
    if (currentBranchId !== null) {
      console.log(currentBranchId, 'iffffff')
      try {
        API.get(`/Notification/branchnotifications?branchId=${currentBranchId}`, {
          headers: {
            Authorization: `Bearer ${user.api_token}`,
          },
        })
          .then((res) => {
            setNotifications(res.data.result.$values)
          })
          .catch((err) => {
            console.log(err, 'err')
          })
      } catch (e) {
        console.log(e)
      }
    }

    API.get(`/Escalation`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setEscalation(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [authSlice.token, currentBranchId])

  // escalation?.map((item) => {
  //   return escalations_notifications.push({
  //     escalationName: item.escalationName,
  //     activity: item.activity,
  //     type: 'escalation',
  //     id:item.id
  //   })
  // })

  notifications.map((item) => {
    return escalations_notifications.push({
      shortDescription: item.shortDescription,
      detailDescription: item.detailDescription,
      notificationAt: item.notificationAt,
      escalationTitle: item.escalationTitle,
      customerName: item.customerName, //was missing
      cardNumber: item.cardNumber, //was missing
      type: 'notification',
    })
  })

  return (
    <>
      <div
        id='kt_activities'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg': '600px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_activities_toggle'
        data-kt-drawer-close='#kt_activities_close'
      >
        <div className='card shadow-none rounded-0'>
          <div className='card-header mb-5' id='kt_activities_header'>
            <h1 className='my-7 fw-bolder text-dark' style={{marginLeft: '-1rem'}}>
              Escalation / Notifications
            </h1>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_activities_close'
                style={{color: 'black', backgroundColor: '#18A689'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
          </div>
          <div className='position-relative' id='kt_activities_body' style={{padding: '5px'}}>
            <div
              id='kt_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_activities_body'
              data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='timeline'>
                {/* <Item1 /> */}
                {escalations_notifications &&
                  escalations_notifications.map((element) => {
                    return <Item2 data={element} />
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ActivityDrawer}
