/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {STRINGS} from '../../../../utils/Enums'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
// import branchSlice from '../../../../app/pages/BranchMain/BranchSlice/branch.slice'
import {useAppSelector} from '../../../../utils/hook'

type Slices = {branchSlice: any}

export function AsideMenuMain() {
  const intl = useIntl()
  const auth = GetCurrentUser()
  console.log(auth.type, 'auth.type')
  // const {branchSlice} = useAppSelector(({Reducers}) => Reducers as Slices)
  // let current_id = new URL(window.location as any).searchParams.get('id')
  let branchId = localStorage.getItem('branchId')

  return (
    <>
      {auth.type == 'cfc' ? (
        <AsideMenuItem
          to={`/dashboard`}
          icon='/media/icons/duotune/general/gen025.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : auth.type == 'branch' || auth.type === 'branch checker' ? (
        <AsideMenuItem
          to={`/dashboard?id=${branchId}`}
          icon='/media/icons/duotune/general/gen025.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        <></>
      )}

      {auth.type !== 'administrator' && auth.type !== 'sambaphone checker' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Process</span>
          </div>
        </div>
      )}

      {auth.type === 'cfc' && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.ATM_CAPTURED_CARD}`}
            title='ATM Captured Card'
            hasBullet={true}
          />
          <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH_SCREEN}`} title='Branch' hasBullet={true} />
          <AsideMenuItem to={`${STRINGS.ROUTES.CFC_SCREEN}`} title='CFC' hasBullet={true} />
          <AsideMenuItem to={`${STRINGS.ROUTES.HISTORY_SCREEN}`} title='History' hasBullet={true} />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}?id=${branchId}`}
            title='Card Not Found Approved'
            hasBullet={true}
          />
        </>
      )}

      {auth.type === 'management' && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH_SCREEN}`}
            title='Branch Tasks'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.MANAGEMENT.MAIN}`}
            title='CFC Tasks'
            hasBullet={true}
          />
          <AsideMenuItem to={`${STRINGS.ROUTES.HISTORY_SCREEN}`} title='History' hasBullet={true} />
        </>
      )}

      {(auth.type === 'branch' || auth.type === 'branch maker') && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.INVENTORY}?id=${branchId}`}
            title='Inventory'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}?id=${branchId}`}
            title='Card Not Found Approved'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.MAIN}?id=${branchId}`}
            title='Main Screen'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.HISTORY}?id=${branchId}`}
            title='History'
            hasBullet={true}
          />
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.CALENDAR}/${branchId}`}
            title='Branch Calendar'
            hasBullet={true}
          /> */}
        </>
      )}

      {auth.type == 'branch checker' && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.INVENTORY}?id=${branchId}`}
            title='Inventory'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.MAIN_SCREEN}?branch/id=${branchId}`}
            title='Main Screen'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.CARD_NOT_FOUND}?id=${branchId}`}
            title='Card Not Found'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}?id=${branchId}`}
            title='Card Not Found Approved'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.FORCE_TASK}?id=${branchId}`}
            title='Force Task'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.HISTORY}?id=${branchId}`}
            title='History'
            hasBullet={true}
          />
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.HISTORY}?id=${branchId}`}
            title='History'
            hasBullet={true}
          /> */}
        </>
      )}

      {['samba phone', 'amd'].includes(auth.type) && (
        <>
          <AsideMenuItem to={`${STRINGS.ROUTES.SAMBACARE.MAIN}`} title='Main ' hasBullet={true} />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.SAMBACARE.SAMBA_CARE_HISTORY}`}
            title='History '
            hasBullet={true}
          />
        </>
      )}

      {/* {['cfc'].includes(auth.type) && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}/cfc`}
            title='Request Form'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.ALL_CARDS}`}
            title='All Cards'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.ADDED_BY_CFC}`}
            title='Card Added By CFC/BRANCH'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.ON_APPROVAL}`}
            title='On Approval'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`}
            title='Main Screen'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.GENERATE_CARDS}`}
            title='Generate Cards'
            hasBullet={true}
          />
          <AsideMenuItem to={`${STRINGS.ROUTES.CFC.HOLD}`} title='Hold Cards' hasBullet={true} />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.DISCARD}`}
            title='Discard Cards'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CFC.CARD_EMOBOSE_HISTORY}`}
            title='History'
            hasBullet={true}
          />
        </>
      )} */}

      {/* {auth.type == 'branch checker' && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`}
            title='Main Screen'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.ON_APPROVAL}`}
            title='On Approval'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.CARD_EMOBOSE_HISTORY}`}
            title='History'
            hasBullet={true}
          />
        </>
      )} */}

      {auth.type === 'branch' ||
        auth.type === 'branch maker' ||
        (auth.type === 'administrator' && (
          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
              </div>
            </div>
            <AsideMenuItemWithSub
              to={`${STRINGS.ROUTES.SETTINGS.CONFIG}`}
              title='Configuration'
              fontIcon='bi-chat-left'
              icon='/media/icons/duotune/communication/com012.svg'
            >
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_INFO}`}
                title='Configure Country Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.ON_APPROVAL}`}
                title='On Approval'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
                title='Configure User Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH_INFO}`}
                title='Configure Bank/Branch Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_ORGANIZATION_INFO}`}
                title='Configure Organization Info'
                hasBullet={true}
              />
              {/* {/* <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_ROLES}`}
                title='Create Roles'
                hasBullet={true}

                <AsideMenuItem
                  to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}`}
                  title='Cards Timeline'
                  hasBullet={true}
                />
                */}
              <AsideMenuItem
                to={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`}
                title='Create alert & escalation'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CALENDER}`}
                title='Administrator Calendar'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.TAT}`}
                title='Administrator TAT'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CARD_TYPE}`}
                title='Card Types'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.COURIER_CHANNEL}`}
                title='Courier Channel'
                hasBullet={true}
              />

              {/* <AsideMenuItem
                  to={`${STRINGS.ROUTES.ADMINISTRATOR.CARD_CHANNEL}`}
                  title='Card Channel'
                  hasBullet={true}
                /> */}
            </AsideMenuItemWithSub>
          </>
        ))}
    </>
  )
}
