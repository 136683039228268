import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'
import {getUserById} from '../../modules/apps/user-management/users-list/core/_requests'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const UserConfigTable = ({
  data,
  type,
  getUserById,
  AddButton,
  PageSize,
  getDataById,
  PageNumber,
  pageNumber,
  pageSize,
  totalRecords,
  deleteRecord = () => {},
}) => {
  console.log(`DATAA ${type}===========`, data)

  const designation = []
  const department = []
  const jobTitle = []
  const users = []

  let user = GetCurrentUser()
  console.log(user, 'userrrr')

  const designationColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'designation', headerName: 'Designation', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong className='d-flex gap-2'>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'designation')
            }}
          >
            Edit
          </Button>

          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const departmentColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'department', headerName: 'Department', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'department')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const jobTitleColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'jobTitle', headerName: 'JobTitle', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'jobTitle')
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ]

  const usersColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'fullName', headerName: 'Full Name', width: 150},
    {field: 'userName', headerName: 'User Name', width: 150},
    // {field: 'action', headerName: 'Action', width: 150},
    // {field: 'firstName', headerName: 'First Name', width: 150},
    // {field: 'lastName', headerName: 'Last Name', width: 150},
    // {field: 'email', headerName: 'Email', width: 150},
    // {field: 'designation', headerName: 'Designation', width: 150},
    // {field: 'department', headerName: 'Department', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (e) => (
        <>
          {console.log(e.row.userName, user.first_name, 'hello')}
          <strong className='d-flex gap-2'>
            <Button
              //   className='btn btn-primary btn-sm'
              variant='contained'
              size='small'
              style={{backgroundColor: '#18A689'}}
              onClick={() => {
                AddButton(false)
                getUserById(e.id)
              }}
            >
              Edit
            </Button>
            <Button
              //   className='btn btn-primary btn-sm'
              variant='contained'
              color='error'
              size='small'
              onClick={() => {
                // AddButton(false)
                console.log(e.row.id, 'id of record')
                deleteRecord(e.row.id)
              }}
              disabled={e.row.userName === user.first_name ? true : false}
            >
              Delete
            </Button>
          </strong>
        </>
      ),
    },
  ]

  if (type == 'designation') {
    // setColumns(countryColumns)
    data?.forEach((element, index) => {
      designation.push({
        id: element.id,
        SNo: index + 1,
        designation: element.designation,
      })
    })
    // setRows(designation)
  }

  if (type == 'department') {
    // setColumns(countryColumns)
    data?.forEach((element, index) => {
      department.push({
        id: element.id,
        SNo: index + 1,
        department: element.department,
      })
    })
    // setRows(designation)
  }

  if (type == 'jobTitle') {
    // setColumns(countryColumns)
    data?.forEach((element, index) => {
      jobTitle.push({
        id: element.id,
        SNo: index + 1,
        jobTitle: element.jobTitle,
      })
    })
    // setRows(designation)
  }

  if (type == 'user') {
    data?.forEach((element, index) => {
      users.push({
        id: element.id,
        SNo: index + 1,
        fullName: element.fullName,
        userName: element.userName,
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        designation: element.designation,
        department: element.department,
        type: element.userType,
      })
    })
  }

  return (
    <div style={{height: '70vh', width: '100%'}}>
      {type == 'designation' && (
        <DataGrid
          rows={designation}
          columns={designationColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[1, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type == 'user' && (
        <DataGrid
          rows={users}
          columns={usersColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[1, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type == 'department' && (
        <DataGrid
          rows={department}
          columns={departmentColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[1, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type == 'jobTitle' && (
        <DataGrid
          rows={jobTitle}
          columns={jobTitleColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[1, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}
    </div>
  )
}

export default UserConfigTable
