import {createSlice} from '@reduxjs/toolkit'
import {API} from '../../../../utils/services'

const initialState = {
  cardAddMessage: '',
  cardTypeId: '',
  cardStatusId: '',
  nativeBranchId: '',
  cardChannelId: '',
  captureBranchId: '',
  cardAddResult: '',
  notifications: [],
  cardEmbosingResponse: '',
  addRequestResponse: '',
}

export const AddCardBranch = (data) => (dispatch) => {
  let {
    convertedDate,
    current_id,
    customerName,
    number,
    expiryState,
    cnic,
    cif,
    cardTypeId,
    cardStatusId,
    cardChannelId,
    nativeBranchId,
    captureReason,
    note,
    user,
    currentCardOwnerBankId,
    acquirerBank,
    organizationId,
  } = data

  console.log('aaaasdasda', cardChannelId)

  API.post(
    '/card/create',
    {
      capturedDate: convertedDate,
      capturedBranchId: current_id,
      customerName: customerName,
      cardNumber: `${number}`,
      cardExpiry: expiryState,
      cifNumber: `${cif}`,
      cardTypeId: cardTypeId,
      nativeBranchId: nativeBranchId ? nativeBranchId : null,
      cardStatusId: cardStatusId,
      reasonForCaptured: captureReason,
      notes: note,
      organizationId: organizationId,
      cnic: `${cnic}`,
      cardOwnerBankId: currentCardOwnerBankId,
      cardChannelId: cardChannelId,
    },
    {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }
  )
    .then(async (res) => {
      console.log('res=======', res)
      dispatch(CardAddResponse(res.data))
      ResetState()
    })
    .catch((e) => {
      alert(e)
    })
}

export const CardAddResponse = (payload) => async (dispatch) => {
  dispatch(setCardAddResponse(payload))
}
export const AddRequestResponse = (payload) => async (dispatch) => {
  dispatch(setAddRequestResponse(payload))
}

export const Notifications = (payload) => async (dispatch) => {
  dispatch(setNotifications(payload))
}

export const CardType = (payload) => async (dispatch) => {
  dispatch(setCardTypeId(payload))
}

export const CardStatus = (payload) => async (dispatch) => {
  dispatch(setCardStatusId(payload))
}

export const NativeBranch = (payload) => async (dispatch) => {
  dispatch(setNativeBranchId(payload))
}
export const CardChannel = (payload) => async (dispatch) => {
  dispatch(setCardChannelId(payload))
}

export const CaptureBranch = (payload) => async (dispatch) => {
  dispatch(setCaptureBranchId(payload))
}

export const ResetState = () => async (dispatch) => {
  dispatch(setResetState())
}

const branchSlice = createSlice({
  name: 'branchSlice',
  initialState,
  reducers: {
    setAddRequestResponse: (state, {payload}) => {
      state.addRequestResponse = payload.data
    },
    setCardTypeId: (state, {payload}) => {
      state.cardTypeId = payload
    },
    setCardStatusId: (state, {payload}) => {
      state.cardStatusId = payload
    },
    setNativeBranchId: (state, {payload}) => {
      state.nativeBranchId = payload
    },
    setCardChannelId: (state, {payload}) => {
      state.cardChannelId = payload
    },
    setCaptureBranchId: (state, {payload}) => {
      state.captureBranchId = payload
    },
    setCardAddResponse: (state, {payload}) => {
      state.cardAddResult = payload.result
      state.cardAddMessage = payload.message
    },
    setNotifications: (state, {payload}) => {
      state.notifications = payload
    },
    setResetState: (state, {payload}) => {
      state.cardAddResult = ''
      state.cardTypeId = ''
      state.nativeBranchId = ''
      state.cardChannelId = ''
      state.cardStatusId = ''
      // state.captureBranchId = ''
      state.addRequestResponse = false
    },
  },
})

export const {
  setCardTypeId,
  setNotifications,
  setAddRequestResponse,
  setCardStatusId,
  setNativeBranchId,
  setCaptureBranchId,
  setCardAddResponse,
  setResetState,
  setCardChannelId,
} = branchSlice.actions

export default branchSlice.reducer
