import React, {useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material'
import {Delete} from '@mui/icons-material'
import {useFormik} from 'formik'
import moment from 'moment'
import {Select} from 'antd'
import {API} from '../../../../../utils/services'

const CalendarModal = ({
  data,
  open,
  onClose,
  onDelete,
  handleCreateHoliday = () => {},
  selectedBranch,
  branchList,
  toast,
}) => {
  const [excludedBranches, setExcludedBranches] = useState([])
  const [selectedExcludedBranches, setSelectedExcludedBranches] = useState([])
  const [includedBranches, setIncludedBranches] = useState([])
  const [selectedIncludedBranches, setSelectedIncludedBranches] = useState([])

  const getBranchesExcludedOrIncludedHoliday = async (id, type, setList) => {
    API.get(`Holiday/${type}?HolidayId=${id}`)
      .then((res) => {
        let newArr = res.data.result.$values?.map((el) => {
          return {
            value: el.id,
            label: el.branchName,
          }
        })
        setList(newArr)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    getBranchesExcludedOrIncludedHoliday(data.publicId, 'GetExcludedBranches', setExcludedBranches)
    getBranchesExcludedOrIncludedHoliday(data.publicId, 'GetIncludedBranches', setIncludedBranches)
  }, [data.publicId])

  const initialValues = {
    title: '',
    description: '',
  }

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    onSubmit: (values, {resetForm}) => {
      // console.log('TESTT', values)
      resetForm()
    },
  })
  const CardHoldSubmit = (values) => {
    // console.log('TESTTTT', values)
    let payload
    if (selectedBranch) {
      if (data?.dateStr !== undefined) {
        //oneDay date
        payload = {
          isRange: false,
          holidayName: values.title,
          bankId: 'ZNJBaQBMy',
          branchId: selectedBranch,
          calendarDayFrom: data?.dateStr,
          calendarDayTo: data?.dateStr,
          description: values.description,
        }
      } else {
        //range day
        let originalDate = moment(data?.endStr)
        // Subtract 1 day from the original date
        let endDate = originalDate.subtract(1, 'day')
        endDate = endDate.format('YYYY-MM-DD')
        payload = {
          isRange: true,
          holidayName: values.title,
          bankId: 'ZNJBaQBMy',
          branchId: selectedBranch,
          calendarDayFrom: data?.startStr,
          calendarDayTo: endDate,
          description: values.description,
        }
      }
    } else {
      if (data?.dateStr !== undefined) {
        //oneDay date
        payload = {
          isRange: false,
          holidayName: values.title,
          bankId: 'ZNJBaQBMy',
          calendarDayFrom: data?.dateStr,
          calendarDayTo: data?.dateStr,
          description: values.description,
        }
      } else {
        //range day
        let originalDate = moment(data?.endStr)
        // Subtract 1 day from the original date
        let endDate = originalDate.subtract(1, 'day')
        endDate = endDate.format('YYYY-MM-DD')
        payload = {
          isRange: true,
          holidayName: values.title,
          bankId: 'ZNJBaQBMy',
          calendarDayFrom: data?.startStr,
          calendarDayTo: endDate,
          description: values.description,
        }
      }
    }

    //TODO: create Payload and pass it to the api
    // console.log(payload, 'payload****')
    handleCreateHoliday(payload)
    onClose()
  }

  const dateGet = (date, subBool) => {
    if (subBool) {
      // Original date
      const originalDate = moment(date)
      // Subtract 1 day from the original date
      const previousDay = originalDate.subtract(1, 'day')
      return previousDay.format('D MMM YYYY')
    } else {
      const originalDate = moment(date)
      return originalDate.format('D MMM YYYY')
    }
  }

  const callIncludeOrExclude = async (type, branches) => {
    API.put(`Holiday/${type}`, {
      holidayId: data.publicId,
      branchIds: branches,
    })
      .then((res) => {
        // console.log('res', res)
        if (res.data.success) {
          toast.success(res.data.message, {
            position: 'bottom-right',
            theme: 'colored',
          })
          getBranchesExcludedOrIncludedHoliday(
            data.publicId,
            'GetExcludedBranches',
            setExcludedBranches
          )
          getBranchesExcludedOrIncludedHoliday(
            data.publicId,
            'GetIncludedBranches',
            setIncludedBranches
          )
        }
        setSelectedExcludedBranches([])
        setSelectedIncludedBranches([])
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const {title, extendedProps, publicId, dateStr, endStr, startStr} = data
  if (startStr !== undefined || dateStr !== undefined) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: '60vw',
            height: '60vh',
            maxHeight: '80vh',
          },
        }}
      >
        <div style={{padding: '40px'}}>
          <form onSubmit={handleSubmit}>
            <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
              {/* <!--begin::Label--> */}
              <label class='fs-6 fw-semibold form-label mb-2'>Title</label>
              {/* <!--end::Label--> */}
              {/* <!--begin::Input wrapper--> */}
              <div class='position-relative'>
                {/* <!--begin::Input--> */}
                <input
                  type='text'
                  class='form-control form-control-solid'
                  placeholder='Title'
                  name='title'
                  onChange={handleChange}
                  value={values.title}
                />
                {/* <!--end::Input--> */}
              </div>
              <div class='fv-plugins-message-container invalid-feedback'></div>
            </div>
            {/* <!--end::Input--> */}

            {/* <!--begin input--> */}
            {/* <!--begin::Input group--> */}
            <div class='d-flex flex-column fv-row fv-plugins-icon-container'>
              {/* <!--begin::Label--> */}
              <label class='fs-6 fw-semibold form-label mb-2'>Description</label>
              {/* <!--end::Label--> */}
              {/* <!--begin::Input wrapper--> */}
              <div class='position-relative'>
                {/* <!--begin::Input--> */}
                <textarea
                  class='form-control'
                  name='description'
                  id='description'
                  aria-label='With textarea'
                  placeholder='Write description Here...'
                  value={values.description}
                  onChange={handleChange}
                ></textarea>

                {/* <!--end::Input--> */}
              </div>
              <div class='fv-plugins-message-container invalid-feedback'></div>
            </div>
            {/* <!--end::Input group--> */}
            {/* <!--end input--> */}
            {/* <!--begin::input--> */}
            <br />
            <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
              {/* <!--begin::Label--> */}
              <label class='fs-6 fw-semibold form-label mb-2'>Days</label>
              {data.endStr !== undefined ? (
                <span>{` ${dateGet(data?.startStr, false)} - ${dateGet(data.endStr, true)}`}</span>
              ) : (
                <span>{` ${dateGet(data?.dateStr, false)}`}</span>
              )}
            </div>
            {/* <!--end::Input--> */}
            <div class='modal-footer' style={{gridColumnGap: '1rem'}}>
              <button type='' class='btn btn-secondary' onClick={onClose}>
                Close
              </button>
              <button onClick={() => CardHoldSubmit(values)} type='submit' class='btn btn-primary'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    )
  } else {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: '50vw',
            height: `${selectedBranch ? '30vh' : '40vh'}`,
            maxHeight: '80vh',
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div className=''>
            <span>Description: </span>
            <div className='' style={{width: '100%', height: '5em', border: '1px solid #e5e7eb'}}>
              <Typography className='p-1' variant='body1'>
                {extendedProps?.description}
              </Typography>
            </div>
          </div>
          {!selectedBranch && (
            <>
              <div className='mt-4 d-flex align-items-center gap-2'>
                <span>Excluded Branches: </span>
                <Select
                  showSearch
                  mode='multiple'
                  value={selectedExcludedBranches}
                  style={{
                    width: 200,
                    position: 'relative', // Ensure relative positioning
                  }}
                  placeholder='Search to Select'
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={(e) => setSelectedExcludedBranches(e)}
                  options={excludedBranches}
                  allowClear={true}
                  dropdownStyle={{
                    position: 'absolute',
                    zIndex: 1600, // Set a higher value than the modal's zIndex
                  }}
                />
                <Button
                  variant='contained'
                  onClick={() => callIncludeOrExclude('include', selectedExcludedBranches)}
                >
                  Include
                </Button>
              </div>
              <div className='mt-4 d-flex align-items-center gap-2'>
                <span>Included Branches: </span>
                <Select
                  showSearch
                  mode='multiple'
                  value={selectedIncludedBranches}
                  style={{
                    width: 200,
                    position: 'relative', // Ensure relative positioning
                  }}
                  placeholder='Search to Select'
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={(e) => setSelectedIncludedBranches(e)}
                  options={includedBranches}
                  allowClear={true}
                  dropdownStyle={{
                    position: 'absolute',
                    zIndex: 1600, // Set a higher value than the modal's zIndex
                  }}
                />
                <Button
                  variant='contained'
                  onClick={() => callIncludeOrExclude('exclude', selectedIncludedBranches)}
                >
                  Exclude
                </Button>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} startIcon={<Delete />} color='error'>
            Delete
          </Button>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CalendarModal
