import {Suspense, useEffect} from 'react'
import {Outlet, Route} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import storeToolkit from './Store/storeToolkit'
import {Provider} from 'react-redux'
import {AUTH_LOCAL_STORAGE_KEY} from './modules/auth'
import './App.css'

const App = () => {
  //   useEffect(() => {
  //     // Add an event listener to the window object for the beforeunload event
  //     window.addEventListener('beforeunload', handleBeforeUnload);

  //     // Remove the event listener when the component is unmounted
  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  //   }, []);

  //   const handleBeforeUnload = () => {
  //     // Clear any cached data that you don't want to persist between sessions
  //     localStorage.clear(AUTH_LOCAL_STORAGE_KEY) // for example
  //   };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={storeToolkit}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Provider>
    </Suspense>
  )
}

export {App}
