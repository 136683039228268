import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { STRINGS } from '../../../../../utils/Enums'
import { API } from '../../../../../utils/services'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllDashboardBranches, GetAllDashboardData, GetAllDashboardDataChecker } from '../../../../GlobalSlices/global.slice'
import { GetCurrentUser } from '../../../../../utils/DefaultFunctions'
import moment from 'moment'
import { Col, Divider, Row } from 'antd';


const defaultState = {
  menuTabs: [],
  selectedTab: 0,
  selectedBranchId: ''
}

const Dashboard = ({ tabs }) => {
   

  useEffect(() => {

    // if (globalSlice.DashboardData.length !== 0 && user !== null && selectedBranchId !== '') {
    //   dispatch(GetAllDashboardDataChecker(user.api_token, branchId))
    // }

  }, [ ])

  // console.log("AUTH SLCIE TYPEE",authSlice);

  return (
    
    <h2 style={{textAlign:"center"}}>DASHBOARD</h2>
  )
}

export default Dashboard
