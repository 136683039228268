import React, {useState, useEffect} from 'react'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import {API} from '../../../../../utils/services'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const initialValues = {
  reason: '',
  days_to_hold: '',
}

const validationSchema = Yup.object().shape({
  days_to_hold: Yup.number().required('Days to hold is required'),
})

const CardSendForApproval = (props) => {
  let user = GetCurrentUser()
  const [init, setInit] = useState({
    cardNumber: '',
    customerName: '',
  })

  useEffect(() => {
    if (props.cardId !== '' && props.cardId !== null && props.cardId !== undefined) {
      setInit({
        ...init,
        cardNumber: props.cardId.cardNumber,
        customerName: props.cardId.customerName,
      })
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      days_to_hold: '',
    },
    validationSchema: props.nextActivityId === 'dZ5p5rVQR' && validationSchema,
    onSubmit: (values) => {
      API.put(
        `/Card/process/sendforapproval`,
        {
          capturedCardId: props.cardId.id,
          nextActivityId: props.nextActivityId,
          holdDays: props.nextActivityId === 'dZ5p5rVQR' ? values.days_to_hold : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${user.api_token}`,
          },
        }
      )
        .then((res) => {
          // dispatch(CardHold(res.data))
        })
        .catch((err) => {
          console.log(err, 'err')
        })
      formik.resetForm()
    },
  })

  return (
    <div
      className='modal fade'
      id='cardActive3'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header' style={{backgroundColor: '#18A689'}}>
            <h1 className='modal-title fs-5' id='exampleModalLabel' style={{color: 'white'}}>
              Card Send For Approval
            </h1>
            <button
              type='button'
              className='btn-close'
              style={{color: 'white'}}
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <form onSubmit={formik.handleSubmit}>
              <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold form-label mb-2'>CARD NUMBER</label>
                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='card_number'
                    value={props.cardId.cardNumber}
                    readOnly
                  />
                </div>
                <div className='fv-plugins-message-container invalid-feedback'></div>
              </div>

              <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold form-label mb-2'>Customer Name</label>
                <div className='position-relative'>
                  <input
                    name='customerName'
                    id='customerName'
                    className='form-control form-control-solid'
                    placeholder='Customer Name'
                    value={props.cardId.customerName}
                    readOnly
                  />
                </div>
                <div className='fv-plugins-message-container invalid-feedback'></div>
              </div>

              {props.nextActivityId === 'dZ5p5rVQR' && (
                <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                  <label className='fs-6 fw-semibold form-label mb-2'>Hold Days</label>
                  <div className='position-relative'>
                    <input
                      name='days_to_hold'
                      id='days_to_hold'
                      className='form-control form-control-solid'
                      placeholder='Enter Hold Days'
                      type='number'
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.days_to_hold}
                    />
                    {formik.touched.days_to_hold && formik.errors.days_to_hold ? (
                      <p className='form-error text-danger my-3'>{formik.errors.days_to_hold}</p>
                    ) : null}
                  </div>
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
              )}

              <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold form-label mb-2'>
                  Are you sure you want to send it for approval?
                </label>
                <div className='fv-plugins-message-container invalid-feedback'></div>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                  onClick={() => formik.resetForm()}
                >
                  Close
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  // disabled={!formik.isValid}
                  disabled={
                    props.nextActivityId === 'dZ5p5rVQR' && !formik.values.days_to_hold
                      ? true
                      : false
                  }
                  data-bs-dismiss='modal'
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSendForApproval
