import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'

// { id: 5, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const CityTable = ({
  data,
  type,
  PageSize,
  PageNumber,
  pageNumber,
  pageSize,
  totalRecords,
  getDataById,
  deleteRecord = () => {},
}) => {
  // const [data, setData] = useState()

  console.log(`DATAA ${totalRecords}===========`, data)

  const handleClick = (event, cellValues) => {
    console.log(cellValues)
  }

  const tableRows = []

  let user = GetCurrentUser()

  const countryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'country')
            }}
          >
            Edit
          </Button>

          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]
  const cityColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {field: 'region', headerName: 'Region', width: 150},
    {field: 'city', headerName: 'City', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'city')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]
  const provinceColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {field: 'province', headerName: 'Province', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'province')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]
  const regionColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {field: 'province', headerName: 'Province', width: 150},
    {field: 'region', headerName: 'Region', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'region')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  if (type) {
    data?.forEach((element, index) => {
      tableRows.push({
        id: element.id,
        SNo: index + 1,
        city: element.city,
        country: element.country,
        region: element.region,
        province: element.province,
      })
    })
  }

  // if (type == 'city') {
  //   data?.forEach((element, index) => {
  //     cities.push({
  //       id: element.id,
  //       SNo: index + 1,
  //       city: element.city,
  //       country: element.country,
  //       region:element.region
  //     })
  //   })
  // }
  // if (type == 'province') {
  //   data?.forEach((element, index) => {
  //     provinces.push({
  //       id: element.id,
  //       SNo: index + 1,
  //       province: element.province,
  //       country: element.country,
  //     })
  //   })
  // }
  // if (type == 'region') {
  //   data?.forEach((element, index) => {
  //     regions.push({
  //       id: element.id,
  //       SNo: index + 1,
  //       region: element.region,
  //       province: element.province,
  //       country: element.country,
  //     })
  //   })
  // }

  return (
    <div style={{height: '70vh', width: '100%'}}>
      {type == 'country' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={countryColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'city' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={cityColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'province' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={provinceColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'region' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={regionColumns}
          showCellRightBorder={true}
        />
      )}
    </div>
  )
}

export default CityTable
