import {STRINGS} from './Enums'

export function getCurrentDate() {
  const date = new Date()
  // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
  const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(
    -2
  )}-${date.getFullYear()}`
  return convertedDate
}

export function convertISODate(st) {
  const date = new Date(st)
  // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
  const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(
    -2
  )}-${date.getFullYear()}`
  return convertedDate
}

export function convertISODateWithSlashed(st) {
  const date = new Date(st)
  // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
  const convertedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(
    -2
  )}/${date.getFullYear()}`
  return convertedDate
}

export function formatAMPM(st) {
  let date = new Date(st)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  let strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function convertISOTime(st) {
  const time = st !== undefined ? st.split('T')[1] : '00:00'
  let hours = time.split(':')[0]
  let minutes = time.split(':')[1]
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? minutes : minutes
  let strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function processingTime(s, e) {
  let startDate = new Date(s)
  let endDate = new Date(e)
  let min = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (60 * 1000))
  let sec = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / 1000)
  // let today = new Date(s);
  // let end = new Date(e);
  // let EndDate = new Date(today.getFullYear() + `-${(end.getMonth()+1)}-${end.getDate()}`);
  // let diffMs = (today - EndDate); // milliseconds between now & EndDate
  // let diffDays = Math.floor(diffMs / 86400000); // days
  // let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  // console.log("diffMs",diffMs)
  // console.log("diffMs", min)
  // console.log("diffMs =>", sec)
  return min === 0 ? `${sec} - sec` : `${min} - min`
}

export function convertLink(link) {
  if (link === undefined || link === '') return ''

  let path = link
  let path2 = path.replace(/\\/g, '/')
  const filePath = path2.split('/Upload/')[1]
  const basePath = `http://recon.3em.tech/upload/`
  return `${basePath}${filePath}`
}

export function GetCurrentUser() {
  let user = sessionStorage.getItem(STRINGS.AUTH_LOCAL_STORAGE_KEY)
  // let user = localStorage.getItem(STRINGS.AUTH_LOCAL_STORAGE_KEY);
  if (user === null) {
  }
  return JSON.parse(user)
}

export function updatePayloadArray(arr) {
  const updatedArr = arr?.map((el) => {
    if (el.nativeBranchId) {
      console.log('start Loader')
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardAcquirerBankBranchId,
        cardAcquirerBankId,
        cardChannel,
        cardChannelId,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        nativeBranchName,
        nativeBranchId,
        notes,
        reasonForCaptured,
        receivingDate,
        issuerBranchCity,
        acquirerBranchCity,
        $id,

        id,
      } = el

      let updatedData = {
        $id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardStatus,
        cardStatusId,
        cfcId,
        cifNumber,
        cardType,
        cnic,
        customerName,
        nativeBranchName,
        nativeBranchId,
        notes,
        id,
        cardImported: true,
      }
      return updatedData
    }

    //CASE 2: OTHER BANK
    if (el.cardOwnerBankName) {
      console.log('start Loader')
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        $id,
        id,
      } = el
      let updatedData = {
        $id,
        id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        cardImported: true,
      }
      return updatedData
    }

    //CASE 2: OTHER ORGANIZATION
    if (el.organization) {
      console.log('start Loader')
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        organizationId,
        organization,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        $id,
        id,
      } = el
      let updatedData = {
        $id,
        id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        organizationId,
        organization,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        cardImported: true,
      }
      return updatedData
    }
  })
  return updatedArr
}

export function maskCardNumber(cardNumber) {
  // Extract first 6 and last 4 digits
  if (cardNumber.includes('*')) {
    return cardNumber
  } else {
    var firstSix = cardNumber.substring(0, 6)
    var lastFour = cardNumber.substring(cardNumber.length - 4)

    // Concatenate the masked card number
    var maskedNumber = firstSix + '******' + lastFour

    return maskedNumber
  }
}

export function downloadFile(base64Data, reportName) {
  // Convert base64 to Blob
  const byteCharacters = atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }) // Set MIME type for Excel

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob)

  // Create a link element
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', reportName) // Set the filename and extension for Excel

  // Programmatically click the link to trigger the download
  document.body.appendChild(link)
  link.click()

  // Clean up
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}
