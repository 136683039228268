import React, {useEffect, useState} from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {API} from '../../../../utils/services'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {useDispatch, useSelector} from 'react-redux'

export function MenuInner() {
  const dispatch = useDispatch()
  const {globalSlice, branchSlice} = useSelector(({Reducers}) => Reducers)
  const [acquirerBranch, setAcquirerBranch] = useState('')

  let user = GetCurrentUser()
  // let user = GetCurrentUser()

  // let current_id = new URL(window.location).searchParams.get('id');

  var date = moment()

  var dashDate = date.format('D/MM/YYYY')

  let branchId = localStorage.getItem('branchId')

  useEffect(() => {
    API.post(
      '/Branch/Search',
      {
        pageNumber: 1,
        pageSize: 100,
      },
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        res.data.result.data.$values.forEach((element) => {
          if (element.id === branchId) {
            setAcquirerBranch(element.branchName)
          }
        })

        // console.log('test====',res);
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [branchId])

  // console.log("AUTH SLCIE TYPEE",authSlice);
  // console.log(acquirerBranch);

  return (
    <>
      <div className='my-4'>
        <h3 className='card-title'>
          {user.type === 'branch' && acquirerBranch != null && (
            <strong className='text-white'>{`Welcome to ${acquirerBranch}`}</strong>
          )}
          {user.type === 'cfc' && <strong className='text-white'>{`Welcome to CFC `}</strong>}
          {user.type === 'samba phone' && (
            <strong className='text-white'>{`Welcome to Samba Phone `}</strong>
          )}
          {user.type === 'administrator' && (
            <strong className='text-white'>{`Welcome to Administrator `}</strong>
          )}
          {user.type === 'management' && (
            <strong className='text-white'>{`Welcome to Management `}</strong>
          )}
          {user.type === 'branch checker' && (
            <strong className='text-white'>{`Welcome to Branch Checker `}</strong>
          )}
          {user.type === 'sambaphone checker' && (
            <strong className='text-white'>{`Welcome to Samba Phone Checker `}</strong>
          )}
        </h3>
        <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
          {/*<!--begin::Item-->*/}
          <li className='breadcrumb-item text-muted'>
            <a className='text-white'>Today's Date</a>
          </li>
          {/*<!--end::Item-->*/}
          {/*<!--begin::Item-->*/}
          <li className='breadcrumb-item'>
            <span className='bullet bg-gray-400 w-5px h-2px'></span>
          </li>
          {/*<!--end::Item-->*/}
          {/*<!--begin::Item-->*/}
          <li className='text-white breadcrumb-item'>{dashDate}</li>
          {/*<!--end::Item-->*/}
        </ul>
      </div>
    </>
  )
}
