import React, {useEffect, useState, useCallback} from 'react'
import CityTable from '../../../../SharedComponent/DataTable/CityTable'
import {API} from '../../../../../utils/services'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import BranchTable from '../../../../SharedComponent/DataTable/BranchTable'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {debounce} from 'lodash'

let initialSearch = {
  organizationSearch: '',
}

const ConfigureOrganizationInfo = () => {
  let user = GetCurrentUser()

  const [countryId, setCountryId] = useState('')
  const [cityId, setCityId] = useState('')
  const [bankId, setBankId] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [branchName, setBranchName] = useState('')
  const [branchCode, setBranchCode] = useState('')
  const [branchAccountNumber, setBranchAccountNumber] = useState('')
  const [bank, setBank] = useState([])
  const [organization, setOrganization] = useState([])
  const [search, setSearch] = useState(initialSearch)
  const [resp, setResp] = useState()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(30)

  const PageSize = (value) => {
    setPageSize(value)
  }
  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const successNotify = () =>
    toast.success('Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const errorNotify = () =>
    toast.error('Wrong Input!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const updateOrganization = () => {
    API.put(
      '/Organization/update',
      {
        id: organizationId,
        description: organizationName,
        isActive: true,
      },
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          setResp('success')
          setOrganizationName('')
          setEditButton(false)
          successNotify()
        }
      })
      .catch((e) => {
        errorNotify()
      })
  }
  const addOrganization = () => {
    API.post(
      '/Organization/create',
      {
        description: organizationName,
      },
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          setResp('success')
          setOrganizationName('')
          successNotify()
        }
      })
      .catch((e) => {
        errorNotify()
      })
  }

  const [organizationCount, setOrganizationCount] = useState(0)

  const [editButton, setEditButton] = useState(false)
  const [organizationId, setOrganizationId] = useState('')

  const getDataById = (id, type) => {
    setEditButton(true)
    console.log(id)
    API.get(`/${type}/getById?${type}Id=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setOrganizationName(res.data.result.description)
        setOrganizationId(res.data.result.id)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    API.get(`/Organization?PageNumber=${pageNumber}&PageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setOrganizationCount(res.data.result.totalRecords)
        setOrganization(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [resp, editButton, pageSize, pageNumber])

  const [init, setInit] = useState({
    tabs: ['Bank', 'Branch'],
    selectedTab: 0,
  })

  const {tabs, selectedTab} = init

  const setSearchFunc = useCallback(
    debounce((val, key) => {
      setSearch((prev) => {
        return {
          ...prev,
          [key]: val,
        }
      })
    }, 300),
    []
  )

  useEffect(() => {
    API.get(
      `/Organization?PageNumber=${pageNumber}&PageSize=${pageSize}&description=${search.organizationSearch}`,
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        setOrganizationCount(res.data.result.totalRecords)
        setOrganization(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [search.organizationSearch, pageNumber, pageSize])

  const deleteOrganization = (userId) => {
    API.delete(`/Organization?OrganizationId=${userId}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // getUsers()
          toast.success(`Deleted Successfuly!`, {
            position: 'bottom-right',
            theme: 'colored',
          })
        }
        //call api for records
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(`Failed to delete`, {
          position: 'bottom-right',
          theme: 'colored',
        })
      })
  }

  return (
    <>
      <div id='kt_app_content_container' class='app-container container-xx'>
        {/*<!--begin::Navbar-->*/}
        {/* <div class='card mb-3' style={{height: '70px', borderBottom: '1px solid #C6C7CC'}}>
          <div class='my-3' style={{paddingTop: '0.25rem !important', paddingLeft: '1.5rem'}}>
            <div class='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <ul class='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {tabs?.map((tb, index) => {
                  return (
                    <li
                      class='nav-item'
                      onClick={() => {
                        setInit({
                          ...init,
                          selectedTab: index,
                        })
                      }}
                    >
                      <div
                        class={`nav-link text-active-primary py-5 me-6 ${
                          selectedTab === index && 'active'
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_1'
                        aria-selected='false'
                        role='tab'
                        tabindex='-1'
                      >
                        {tb}
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div> */}
        {/*<!--end::Navbar-->*/}
        {/*<!--begin::Row-->*/}
        {/*<!--Mytabcontent start-->*/}
        <div class='row g-6 g-xl-12 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}
          <div className='my-3' style={{padding: '10px'}}>
            <div
              className='me-3'
              style={{
                backgroundColor: 'white',
                border: '1px solid #E4E4E4',
                borderRadius: '10px',
                padding: '15px',
              }}
            >
              <div className='my-5 d-flex justify-content-end' style={{marginLeft: '-2rem'}}>
                <div>
                  <label for='exampleInputEmail1' className='form-label'>
                    <strong>Search Organization</strong>
                  </label>
                  <div className='me-3'>
                    <input
                      class='form-control form-control-solid flatpickr-input'
                      placeholder='Search Organization'
                      type='text'
                      onChange={(e) => setSearchFunc(e.target.value, 'organizationSearch')}
                    />
                  </div>
                </div>
              </div>
              <div className='my-5'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>Create Organization</strong>
                </label>
                <div className='d-flex'>
                  <div className='me-3 d-flex' style={{width: '500%'}}>
                    {/* <select
                      class='form-select me-3 form-select-solid'
                      data-control='select2'
                      onChange={(e)=>setCountryId(e.target.value)}
                      value={countryId}
                      data-placeholder='Select City'
                      data-hide-search='true'
                    >
                      <option selected hidden>
                        Select Country
                      </option>
                      {
                        country && country.map(item=>{
                          return <option value={item.id}>{item.country}</option>
                          })
                      }
                    </select> */}

                    <input
                      class='form-control form-control-solid flatpickr-input'
                      value={organizationName}
                      onChange={(e) => setOrganizationName(e.target.value)}
                      placeholder='Enter Organization Name'
                      type='text'
                    />
                  </div>
                  {!editButton ? (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        addOrganization()
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        updateOrganization()
                      }}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
              <div className=' my-3 mb-5'>
                <BranchTable
                  getDataById={getDataById}
                  data={organization}
                  type='organization'
                  PageSize={PageSize}
                  PageNumber={PageNumber}
                  totalRecords={organizationCount}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  deleteRecord={deleteOrganization}
                />
                {/* <CityTable data={country} type="country" /> */}
              </div>
            </div>
          </div>
          {/*<!--endtab2-->*/}
        </div>
        {/*<!--Mytabcontent end-->*/}
        {/*<!--end::Row-->*/}
        {/*<!--begin::Table-->*/}
      </div>

      <div>
        {/* first */}

        {/* second  */}

        {/* Third  */}
      </div>
      <ToastContainer />
    </>
  )
}

export default ConfigureOrganizationInfo
