import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import Loader from '../Loader/Loader'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../utils/Enums'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const TimelineTable = ({data = {}}) => {
  let navigate = useNavigate()

  const [bookData, setBookData] = useState([])

  const columns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {field: 'processStatus', headerName: 'Process Status', width: 180},
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${
              status == 'Hot'
                ? 'Card Hot'
                : status == 'Active'
                ? 'Customer not Recieved'
                : status.includes('xpire')
                ? 'Expired'
                : 'Anonymous'
            }`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  //   const bookData = []

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let myData = {...data}
      myData.id = 1 //id will be 1 because there will be only one row in this so this will be unique
      const updatedBookData = [myData]
      setBookData(updatedBookData)
    }
  }, [data])

  return (
    <div style={{height: 200, width: '100%'}}>
      <DataGrid
        rows={bookData ? bookData : []}
        columns={columns}
        showCellRightBorder={true}

        // {...data}
      />
      {/* } */}
    </div>
  )
}

export default TimelineTable
