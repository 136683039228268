import React, {useEffect, useState} from 'react'
import TimelineTable from '../../../../SharedComponent/DataTable/TimlineTable'
import './timeline.css'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import {useLocation, useParams} from 'react-router-dom'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import {identity} from 'lodash'
import {API} from '../../../../../utils/services'

const CardTimeline = () => {
  const {id} = useParams()
  let user = GetCurrentUser()
  const location = useLocation()
  const [tableData, setTableData] = useState({})
  const [timeLineData, setTimeLineData] = useState([])

  const getCardActivityLog = async (getCardActivityLog) => {
    API.get(`/Card/getCaptureCardActivityLog?CaptureCardId=${getCardActivityLog}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.result.$values, 'CaptureCa')

          const newArr = res.data.result.$values?.map((el, id) => {
            return {
              ...el,
              jsonObject: JSON.parse(el.jsonObject),
            }
          })
          console.log(newArr, 'my new arr')
          const sortedArr = newArr.reverse()
          console.log(sortedArr, 'sortedArr')
          setTimeLineData(sortedArr)
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    if (id) {
      getCardActivityLog(id)
    }
  }, [id])

  useEffect(() => {
    if (location.state?.data) {
      setTableData(location.state?.data)
    }
  }, [location])

  return (
    <>
      {/*<!--begin::Header-->*/}
      <div class='card-header border-0 pt-5' style={{backgroundColor: 'white', height: '50px'}}>
        <h3 class='card-title align-items-start flex-column mb-5 ms-5'>
          <span class='card-label fw-bold fs-3 mb-1'>Process ID: SAMBA-{id}</span>
        </h3>
      </div>
      {/*<!--end::Header-->*/}
      <div className='my-3' style={{backgroundColor: 'white'}}>
        <TimelineTable data={tableData ? tableData : {}} />
      </div>
      <div>
        <div className='me-3 my-5' style={{backgroundColor: 'white'}}>
          <div className='my-5'>
            <h1 className='ms-5 my-5' style={{textAlign: 'center', paddingTop: '10px'}}>
              Card Timeline
            </h1>
          </div>
          <hr />
          <div class='timeline my-10'>
            {timeLineData.length > 0 &&
              timeLineData?.map((el, id) => (
                <div className={`container1 ${id % 2 === 0 ? ' left1' : 'right1'}`}>
                  <div className='content1'>
                    <h1 className='heading' style={{color: 'black'}}>
                      STAGE {timeLineData.length - id}
                    </h1>
                    <hr />
                    <h4 style={{color: 'grey'}}>Description : {el.description}</h4>
                    <h4 style={{color: 'grey'}}>Process Name : {el.cardLogAction}</h4>
                    {/* <h4 style={{color: 'grey'}}>Aging : 0</h4>
                    <h4 style={{color: 'grey'}}>Performed By : Umar</h4>
                    <h4 style={{color: 'grey'}}>Date & Time : 02-12-23 || 02:45PM</h4> */}
                  </div>
                </div>
              ))}

            {/* <div class="container1 right1">
                            <div class="content1">
                                <h2>2012</h2>
                                <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                            </div>
                        </div>
                        <div class="container1 left1">
                            <div class="content1">
                                <h2>2011</h2>
                                <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                            </div>
                        </div>
                        <div class="container1 right1">
                            <div class="content1">
                                <h2>2007</h2>
                                <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
                            </div>
                        </div> */}
          </div>
        </div>
        {/* <div style={{backgroundColor: 'white', height: '100vh'}}>
        
          <div>
            <div className='my-10'>
              <h1 className='ms-5' style={{paddingTop: '22px'}}>
                Attachments :{' '}
              </h1>
            </div>
            <div className='d-flex' style={{height: '2.5rem'}}>
              <div
                className='ms-5'
                style={{
                  borderRight: '1px solid grey',
                  padding: '10px',
                  backgroundColor: '#18A689',
                  color: 'white',
                  borderRadius: '10px',
                }}
              >
                <a href='#' style={{textDecoration: 'underline', color: 'white'}}>
                  <h5 style={{color: 'white'}}>Attachment 1</h5>
                </a>
              </div>
              <div
                className='ms-5'
                style={{
                  borderRight: '1px solid grey',
                  padding: '10px',
                  backgroundColor: '#18A689',
                  color: 'white',
                  borderRadius: '10px',
                }}
              >
                <a href='#' style={{textDecoration: 'underline', color: 'white'}}>
                  <h5 style={{color: 'white'}}>Attachment 2</h5>
                </a>
              </div>
              <div
                className='ms-5'
                style={{
                  borderRight: '1px solid grey',
                  padding: '10px',
                  backgroundColor: '#18A689',
                  color: 'white',
                  borderRadius: '10px',
                }}
              >
                <a href='#' style={{textDecoration: 'underline', color: 'white'}}>
                  <h5 style={{color: 'white'}}>Attachment 3</h5>
                </a>
              </div>
            </div>
          </div>
          
          <div className='my-20'>
            <div>
              <h1 className='ms-5 my-5'>Notes : </h1>
            </div>
            <div className='d-flex' style={{height: '2.5rem'}}>
          
              <div class='container2 left2'>
                <div class='content2'>
                  <h5 className='heading my-3 ms-1' style={{color: 'white'}}>
                    1st Process Note
                  </h5>
                  <hr />
                  <p className='ms-1' style={{color: 'white', fontSize: '13px', fontWeight: '200'}}>
                    Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto
                    mnesarchum, vim ea mazim fierent detracto
                  </p>
                </div>
              </div>

              <div class='container2 left2'>
                <div class='content2'>
                  <h5 className='heading my-3 ms-1' style={{color: 'white'}}>
                    2nd Process Note
                  </h5>
                  <hr />
                  <p className='ms-1' style={{color: 'white', fontSize: '13px', fontWeight: '200'}}>
                    Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto
                    mnesarchum, vim ea mazim fierent detracto
                  </p>
                </div>
              </div>

              <div class='container2 left2'>
                <div class='content2'>
                  <h5 className='heading my-3 ms-1' style={{color: 'white'}}>
                    3rd Process Note
                  </h5>
                  <hr />
                  <p className='ms-1' style={{color: 'white', fontSize: '13px', fontWeight: '200'}}>
                    Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto
                    mnesarchum, vim ea mazim fierent detracto
                  </p>
                </div>
              </div>
              <div class='container2'>
                <div class='content2'>
                  <h5 className='heading my-3 ms-1' style={{color: 'white'}}>
                    4th Process Note
                  </h5>
                  <hr />
                  <p className='ms-1' style={{color: 'white', fontSize: '13px', fontWeight: '200'}}>
                    Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto
                    mnesarchum, vim ea mazim fierent detracto
                  </p>
                </div>
              </div>

             
            </div>
          </div>
       
          <div style={{paddingTop: '10rem'}}>
            <div>
              <h1 className='ms-5 my-5'>Late Process : </h1>
            </div>
            <div className='d-flex' style={{height: '2.5rem'}}>
           
              <div class='container3'>
                <div class='content3 left3'>
                  <h1 className='heading' style={{color: 'black'}}>
                    SMCHS Branch
                  </h1>
                  <hr />
                  <h4 style={{color: 'grey'}}>Remaining Days : 4</h4>
                  <h4 style={{color: 'grey'}}>Process Name : Shred</h4>
                  <h4 style={{color: 'grey'}}>Aging : 0</h4>
                  <h4 style={{color: 'grey'}}>Performed By : Umar</h4>
                  <h4 style={{color: 'grey'}}>Date & Time : 02-12-23 || 02:45PM</h4>
                </div>
              </div>
              <div class='container3'>
                <div class='content3 left3'>
                  <h1 className='heading' style={{color: 'black'}}>
                    SMCHS Branch
                  </h1>
                  <hr />
                  <h4 style={{color: 'grey'}}>Remaining Days : 4</h4>
                  <h4 style={{color: 'grey'}}>Process Name : Shred</h4>
                  <h4 style={{color: 'grey'}}>Aging : 0</h4>
                  <h4 style={{color: 'grey'}}>Performed By : Umar</h4>
                  <h4 style={{color: 'grey'}}>Date & Time : 02-12-23 || 02:45PM</h4>
                </div>
              </div>

            
            </div>
          </div>
       
        </div> */}
      </div>
    </>
  )
}

export default CardTimeline
