import React, {useEffect, useState, useCallback} from 'react'
import CityTable from '../../../../SharedComponent/DataTable/CityTable'
import {API} from '../../../../../utils/services'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import BranchTable from '../../../../SharedComponent/DataTable/BranchTable'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {debounce} from 'lodash'

//TODO: GET ALL CARD TYPE API TO BE CHANGED FOR PAGINATION AND HISTORY

const ConfigureCardType = () => {
  let user = GetCurrentUser()
  const [cardTypes, setCardTypes] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [search, setSearch] = useState('')

  let initialForm = {
    description: '',
  }
  const [cardTypeForm, setcardTypeForm] = useState(initialForm)
  const [resp, setResp] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const PageSize = (value) => {
    setPageSize(value)
  }
  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const successNotify = () =>
    toast.success('Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const errorNotify = () =>
    toast.error('Wrong Input!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const [organizationCount, setOrganizationCount] = useState(0)

  const [editButton, setEditButton] = useState(false)
  const [organizationId, setOrganizationId] = useState('')

  const getDataById = (id, type) => {
    setEditButton(true)
    API.get(`/${type}/getById?CardTypeId=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        //   console.log(res.data.result, 'response get id')
        console.log(res.data.result, 'sfgasadadas')
        setcardTypeForm(res.data.result)
        //   setOrganizationName(res.data.result.description)
        //   setOrganizationId(res.data.result.id)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const GetAllCardTypes = () => {
    API.get(`/CardType?Description=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setCardTypes(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  // useEffect(() => {
  //   GetAllCardTypes()
  // }, [])

  const addCardType = () => {
    console.log(cardTypeForm, 'cardTypeForm')
    setButtonDisabled(true)

    API.post(`/CardType`, cardTypeForm, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setcardTypeForm(initialForm)
        successNotify()
        GetAllCardTypes()
        setButtonDisabled(false)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const updateCardType = () => {
    console.log(cardTypeForm, 'cardTypeForm')
    setButtonDisabled(true)
    const payload = {
      description: cardTypeForm.description,
      id: cardTypeForm.id,
    }
    API.put(`/CardType`, payload, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setcardTypeForm(initialForm)
        successNotify()
        GetAllCardTypes()
        setButtonDisabled(false)
        setEditButton(false)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const setSearchFunc = useCallback(
    debounce((val) => {
      setSearch(val)
    }, 300),
    []
  )

  //UseEffect for page number, page size and search
  useEffect(() => {
    //
    GetAllCardTypes()
  }, [pageNumber, pageSize, search])

  const deleteCardType = (userId) => {
    API.delete(`/CardType?CardTypeId=${userId}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // getUsers()
          toast.success(`Deleted Successfuly!`, {
            position: 'bottom-right',
            theme: 'colored',
          })
        }
        //call api for records
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(`Failed to delete`, {
          position: 'bottom-right',
          theme: 'colored',
        })
      })
  }

  return (
    <>
      <div id='kt_app_content_container' class='app-container container-xx'>
        <div class='row g-6 g-xl-12 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}

          <div className='my-3' style={{padding: '10px'}}>
            <div
              className='me-3'
              style={{
                backgroundColor: 'white',
                border: '1px solid #E4E4E4',
                borderRadius: '10px',
                padding: '15px',
              }}
            >
              <div className='my-5 d-flex justify-content-end' style={{marginLeft: '-2rem'}}>
                <div>
                  <label for='exampleInputEmail1' className='form-label'>
                    <strong>Search Card type</strong>
                  </label>
                  <div className='me-3'>
                    <input
                      class='form-control form-control-solid flatpickr-input'
                      placeholder='Search Bank'
                      type='text'
                      onChange={(e) => setSearchFunc(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='my-5'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>Create CardType</strong>
                </label>
                <div className='d-flex'>
                  <div className='me-3 d-flex'>
                    <input
                      class='form-control form-control-solid flatpickr-input'
                      value={cardTypeForm.description}
                      onChange={(e) =>
                        setcardTypeForm({
                          ...cardTypeForm,
                          description: e.target.value,
                        })
                      }
                      placeholder='Enter Type'
                      type='text'
                    />
                  </div>

                  {!editButton ? (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        addCardType()
                      }}
                      disabled={buttonDisabled ? true : false}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className='d-flex' style={{gridColumnGap: '1em'}}>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => {
                            setcardTypeForm(initialForm)
                            setEditButton(false)
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            updateCardType()
                          }}
                          disabled={buttonDisabled ? true : false}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className=' my-3 mb-5'>
                <BranchTable
                  getDataById={getDataById}
                  data={cardTypes}
                  type='cardType'
                  PageSize={PageSize}
                  PageNumber={PageNumber}
                  //   totalRecords={TAT.length()}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  deleteRecord={deleteCardType}
                />
                {/* <CityTable data={country} type="country" /> */}
              </div>
            </div>
          </div>
          {/*<!--endtab2-->*/}
        </div>
        {/*<!--Mytabcontent end-->*/}
        {/*<!--end::Row-->*/}
        {/*<!--begin::Table-->*/}
      </div>

      <div>
        {/* first */}

        {/* second  */}

        {/* Third  */}
      </div>
      <ToastContainer />
    </>
  )
}

export default ConfigureCardType
