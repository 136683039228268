import React, {useEffect, useState} from 'react'
import CityTable from '../../../../SharedComponent/DataTable/CityTable'
import {API} from '../../../../../utils/services'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import BranchTable from '../../../../SharedComponent/DataTable/BranchTable'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Card, Col, Row} from 'antd'
import RoleTable from '../../../../SharedComponent/DataTable/RoleTable'

const CreateRoles = () => {
  let user = GetCurrentUser()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)

  const PageSize = (value) => {
    setPageSize(value)
  }
  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const [init, setInit] = useState({
    tabs: ['Create Roles', 'List'],
    selectedTab: 0,
  })

  const {tabs, selectedTab} = init

  const [groupFeatureActions, setGroupFeatureActions] = useState([])
  const [applicationRolePermissions, setApplicationRolePermissions] = useState([])
  const [toggle, setToggle] = useState(true)
  const [checked, setChecked] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [roles, setRoles] = useState([])
  const [roleCount, setRoleCount] = useState(0)
  const [roleId, setRoleId] = useState()
  const [refresh, setRefresh] = useState(false)

  const [bankName, setBankName] = useState('')
  const [branchName, setBranchName] = useState('')
  const [bank, setBank] = useState([])
  const [groups, setGroups] = useState([])
  const [groupFeatures, setGroupFeatures] = useState([])

  const [resp, setResp] = useState()

  const successNotify = () =>
    toast.success('Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const errorNotify = () =>
    toast.error('Wrong Input!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const getDataById = (id) => {
    API.get(`/role/getById?roleId=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setInit({...init, selectedTab: 0})
        setRoleName(res.data.result.name)
        console.log('UPD ROle', res.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const deleteById = (id) => {
    API.delete(`/role?roleId=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setRefresh(!refresh)
        // console.log('Delete ROle',)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  // let groupFeatureActions=[];

  useEffect(() => {
    API.get(`/Group`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        //like dashboard ,inventory
        setGroups(res.data.result.data.$values.sort((a, b) => a.sortOrder - b.sortOrder))
      })
      .catch((err) => {
        console.log(err, 'err')
      })

    API.get(`/GroupFeature`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setGroupFeatures(res.data.result.data.$values.sort((a, b) => a.sortOrder - b.sortOrder))
        //like branch admin etc
        if (groupFeatureActions.length === 0) {
          for (let i = 0; i < res.data.result.data.$values.length; i++) {
            API.get(`/GroupFeature/getById?groupFeatureId=${res.data.result.data.$values[i].id}`, {
              headers: {
                Authorization: `Bearer ${user.api_token}`,
              },
            }).then((res) => {
              setGroupFeatureActions((current) => [...current, res.data])
              //like insert update
            })
          }
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [])

  useEffect(() => {
    API.get(`/role?PageNumber=${pageNumber}&PageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setRoleCount(res.data.result.totalRecords)
        setRoles(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [roleName, pageNumber, pageSize, refresh])

  const AddRole = () => {
    console.log('applicationRolePermissions', applicationRolePermissions)

    let body = {
      applicationRolePermissions,
      isActive: true,
      name: roleName,
      normalizedName: roleName,
      concurrencyStamp: 'string',
    }

    // API.post('/role', body, {
    //   headers: {
    //     Authorization: `Bearer ${user.api_token}`,
    //   },
    // }).then((res) => {
    //   setChecked(false)
    //   setRoleName('')
    //   res.data.result && setInit({...init, selectedTab: 1})
    // })
  }

  return (
    <>
      <div id='kt_app_content_container' class='app-container container-xx'>
        {/*<!--begin::Navbar-->*/}
        <div class='card mb-3' style={{height: '70px', borderBottom: '1px solid #C6C7CC'}}>
          <div class='my-3' style={{paddingTop: '0.25rem !important', paddingLeft: '1.5rem'}}>
            {/*<!--begin::Details-->*/}
            <div class='d-flex flex-wrap flex-sm-nowrap mb-6'>
              {/*<!--begin::Nav-->*/}
              <ul class='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {/*<!--begin::Nav item-->*/}
                {tabs?.map((tb, index) => {
                  return (
                    <li
                      class='nav-item'
                      onClick={() => {
                        setInit({
                          ...init,
                          selectedTab: index,
                        })
                      }}
                    >
                      <div
                        class={`nav-link text-active-primary py-5 me-6 ${
                          selectedTab === index && 'active'
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_1'
                        aria-selected='false'
                        role='tab'
                        tabindex='-1'
                      >
                        {tb}
                      </div>
                    </li>
                  )
                })}
              </ul>
              {/*<!--end::Nav-->*/}
            </div>
            {/*<!--end::Details-->*/}
          </div>
        </div>
        {/*<!--end::Navbar-->*/}
        {/*<!--begin::Row-->*/}
        {/*<!--Mytabcontent start-->*/}
        <div class='row g-6 g-xl-12 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}
          {selectedTab === 0 && (
            <div className='group'>
              <div class='card mb-5 mb-xl-10'>
                {/*<!--begin::Card header-->*/}

                {/*<!--begin::Card header-->*/}
                {/*<!--begin::Content-->*/}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/*<!--begin::Form-->*/}
                  <form
                    id='kt_account_profile_details_form'
                    class='form fv-plugins-bootstrap5 fv-plugins-framework'
                    novalidate='novalidate'
                  >
                    {/*<!--begin::Card body-->*/}
                    <div className='my-3' style={{padding: '10px'}}>
                      {/* second  */}
                      <div
                        className=''
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid #E4E4E4',
                          borderRadius: '10px',
                        }}
                      >
                        <div className='my-5'>
                          <div className='d-flex my-5'>
                            <div className='me-3' id='w-500'>
                              <label for='exampleInputEmail1' className='form-label'>
                                <strong>Role Name</strong>
                              </label>
                              <input
                                class='form-control form-control-solid flatpickr-input me-3'
                                placeholder='Enter Role Name'
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                                type='text'
                              />
                            </div>
                          </div>
                          {/* first  */}
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'space-between',
                            }}
                          >
                            {groups?.map((item, index) => {
                              return (
                                <div
                                  className='me-3 mb-3'
                                  style={{
                                    backgroundColor: 'white',
                                    width: '47%',
                                    border: '1px solid grey',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <div>
                                    <div className='d-flex my-10'>
                                      <div className='me-3' id='w-500'>
                                        <label for='exampleInputEmail1' className='form-label'>
                                          <h2>
                                            <strong>{item.groupName}</strong>
                                          </h2>
                                        </label>
                                      </div>
                                    </div>
                                    {groupFeatureActions?.map((nestedItem, idx) => {
                                      return (
                                        <>
                                          {nestedItem.result.groupId == item.id && (
                                            <div className='d-flex my-5'>
                                              <div className='me-3 d-flex' id='w-500'>
                                                <div className='me-5' style={{width: '30px'}}>
                                                  <label
                                                    for='exampleInputEmail1'
                                                    className='form-label me-5 my-1'
                                                  >
                                                    <h6>
                                                      <strong>
                                                        {nestedItem.result.featureDescription}
                                                      </strong>
                                                    </h6>
                                                  </label>
                                                </div>
                                                <div class='fv-row container ms-5'>
                                                  {/*<!--begin::Label-->*/}
                                                  {/*<!--end::Label-->*/}
                                                  {/*<!--begin::Input-->
                                                   */}
                                                  <div class='form-check form-check-custom form-check-solid mb-2'>
                                                    {nestedItem.result.groupFeatureActions.$values.map(
                                                      (nestedItem2, idx) => {
                                                        return (
                                                          <>
                                                            <input
                                                              class='form-check-input'
                                                              type='checkbox'
                                                              // value={nestedItem2}
                                                              id='expiration'
                                                              defaultChecked={checked}
                                                              style={{border: '2px solid grey'}}
                                                              onChange={(e) => {
                                                                // setChecked(true)
                                                                setApplicationRolePermissions(
                                                                  (current) => [
                                                                    ...current,
                                                                    {
                                                                      groupId: item.id,
                                                                      groupFeatureId:
                                                                        nestedItem2.groupFeatureId,
                                                                      databaseOperationCode:
                                                                        nestedItem2.databaseOperationCode,
                                                                      isActive: true,
                                                                    },
                                                                  ]
                                                                )
                                                              }}
                                                            />

                                                            <label
                                                              class='form-check-label me-2'
                                                              style={{fontSize: '12px'}}
                                                            >
                                                              {nestedItem2.databaseOperationCode ==
                                                                'I' && 'Insert'}
                                                              {nestedItem2.databaseOperationCode ==
                                                                'U' && 'Update'}
                                                              {nestedItem2.databaseOperationCode ==
                                                                'D' && 'Delete'}
                                                              {nestedItem2.databaseOperationCode ==
                                                                'V' && 'View'}
                                                              {nestedItem2.databaseOperationCode ==
                                                                'A' && 'Approved'}
                                                            </label>
                                                          </>
                                                        )
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </div>

                          <button
                            // onClick={AddBranch}
                            style={{float: 'right'}}
                            type='button'
                            onClick={AddRole}
                            className='btn btn-primary btn-sm my-5'
                          >
                            Add
                          </button>
                        </div>
                        <div className=' my-3 mb-5'>
                          {/* <CityTable data={country} type="country" /> */}
                        </div>
                      </div>
                    </div>
                    <input type='hidden' />
                    <div></div>
                  </form>
                  {/*<!--end::Form-->*/}
                </div>
                {/*<!--end::Content-->*/}
              </div>
            </div>
          )}

          {selectedTab == 1 && (
            <div className=' my-3 mb-5 card p-10'>
              <RoleTable
                data={roles}
                type='role'
                PageSize={PageSize}
                PageNumber={PageNumber}
                totalRecords={roleCount}
                pageNumber={pageNumber}
                pageSize={pageSize}
                getDataById={getDataById}
                deleteById={deleteById}
              />
            </div>
          )}

          {/*<!--end::Row-->*/}
          {/*<!--begin::Table-->*/}
        </div>
      </div>

      <div>
        {/* first */}

        {/* second  */}

        {/* Third  */}
      </div>
      <ToastContainer />
    </>
  )
}

export default CreateRoles
