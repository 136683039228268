import React, {useEffect, useState} from 'react'
import CityTable from '../../../../SharedComponent/DataTable/CityTable'
import {API} from '../../../../../utils/services'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import BranchTable from '../../../../SharedComponent/DataTable/BranchTable'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ConfigureTat = () => {
  let user = GetCurrentUser()
  const [TAT, setTat] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(30)

  let initialForm = {
    duration: 0,
    intraCity: false,
    interCity: false,
    remoteBranch: false,
  }
  const [tatForm, setTatForm] = useState(initialForm)
  const [resp, setResp] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const PageSize = (value) => {
    setPageSize(value)
  }
  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const successNotify = () =>
    toast.success('Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const errorNotify = () =>
    toast.error('Wrong Input!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const [organizationCount, setOrganizationCount] = useState(0)

  const [editButton, setEditButton] = useState(false)
  const [organizationId, setOrganizationId] = useState('')

  const getDataById = (id, type) => {
    setEditButton(true)
    API.get(`/${type}/getById?Id=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        console.log(res.data.result, 'response get id')
        setTatForm(res.data.result)
        //   setOrganizationName(res.data.result.description)
        //   setOrganizationId(res.data.result.id)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const GetAllTat = () => {
    API.get(`/TAT/search`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        console.log(res.data.result.$values, 'response tat')
        setTat(res.data.result.$values)
        //   setOrganizationName(res.data.result.description)
        //   setOrganizationId(res.data.result.id)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    GetAllTat()
  }, [])

  const onChangeRadio = (radio) => {
    console.log(radio, 'radio')
    if (radio === 'intraCity') {
      setTatForm({
        ...tatForm,
        intraCity: true,
        interCity: false,
        remoteBranch: false,
      })
    }
    if (radio === 'interCity') {
      setTatForm({
        ...tatForm,
        interCity: true,
        intraCity: false,
        remoteBranch: false,
      })
    }
    if (radio === 'remoteBranch') {
      setTatForm({
        ...tatForm,
        remoteBranch: true,
        interCity: false,
        intraCity: false,
      })
    }
  }

  const addTat = () => {
    console.log(tatForm, 'tat Form')
    setButtonDisabled(true)
    API.post(`/TAT`, tatForm, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setTatForm(initialForm)
        successNotify()
        GetAllTat()
        setButtonDisabled(false)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const updateTat = () => {
    console.log(tatForm, 'tat Form')
    setButtonDisabled(true)
    let payload = {
      id: tatForm.id,
      duration: tatForm.duration,
      interCity: tatForm.interCity,
      intraCity: tatForm.intraCity,
      remoteBranch: tatForm.remoteBranch,
    }
    API.put(`/TAT`, payload, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        setTatForm(initialForm)
        successNotify()
        GetAllTat()
        setButtonDisabled(false)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const deleteTat = (userId) => {
    API.delete(`/TAT?Id=${userId}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // getUsers()
          toast.success(`Deleted Successfuly!`, {
            position: 'bottom-right',
            theme: 'colored',
          })
        }
        //call api for records
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(`Failed to delete`, {
          position: 'bottom-right',
          theme: 'colored',
        })
      })
  }
  
  return (
    <>
      <div id='kt_app_content_container' class='app-container container-xx'>
        <div class='row g-6 g-xl-12 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}
          <div className='my-3' style={{padding: '10px'}}>
            <div
              className='me-3'
              style={{
                backgroundColor: 'white',
                border: '1px solid #E4E4E4',
                borderRadius: '10px',
                padding: '15px',
              }}
            >
              <div className='my-5'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>Create TAT</strong>
                </label>
                <div className='d-flex'>
                  <div className='me-3 d-flex'>
                    <input
                      class='form-control form-control-solid flatpickr-input'
                      value={tatForm.duration}
                      onChange={(e) =>
                        setTatForm({
                          ...tatForm,
                          duration: parseInt(e.target.value),
                        })
                      }
                      placeholder='Enter Duration'
                      type='number'
                    />
                  </div>
                  <div className='me-3 d-flex'>
                    <div
                      onChange={(e) => onChangeRadio(e.target.value)}
                      style={{display: 'flex', alignItems: 'center', gridColumnGap: '1em'}}
                    >
                      <strong>Please select</strong>
                      <input
                        type='radio'
                        name='tat'
                        value={'interCity'}
                        checked={tatForm.interCity ? true : false}
                      />
                      <label for='age1'>Inter City</label>
                      <input
                        type='radio'
                        name='tat'
                        value={'intraCity'}
                        checked={tatForm.intraCity ? true : false}
                      />
                      <label for='age2'>Intra City</label>
                      <input
                        type='radio'
                        name='tat'
                        value={'remoteBranch'}
                        checked={tatForm.remoteBranch ? true : false}
                      />
                      <label for='age3'>Remote Branch</label>
                    </div>
                  </div>
                  {!editButton ? (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        // addTat()
                      }}
                      disabled={buttonDisabled ? true : false}
                    >
                      Add
                    </button>
                  ) : (
                    <>
                      <div className='d-flex' style={{gridColumnGap: '1em'}}>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => {
                            setTatForm(initialForm)
                            setEditButton(false)
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            updateTat()
                          }}
                          disabled={buttonDisabled ? true : false}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className=' my-3 mb-5'>
                <BranchTable
                  getDataById={getDataById}
                  data={TAT}
                  type='tat'
                  PageSize={PageSize}
                  PageNumber={PageNumber}
                  //   totalRecords={TAT.length()}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  deleteRecord={deleteTat}
                />
                {/* <CityTable data={country} type="country" /> */}
              </div>
            </div>
          </div>
          {/*<!--endtab2-->*/}
        </div>
        {/*<!--Mytabcontent end-->*/}
        {/*<!--end::Row-->*/}
        {/*<!--begin::Table-->*/}
      </div>

      <div>
        {/* first */}

        {/* second  */}

        {/* Third  */}
      </div>
      <ToastContainer />
    </>
  )
}

export default ConfigureTat
