import {FC, useEffect, useState} from 'react'
import {convertISOTime, GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {API} from '../../../../utils/services'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {convertISODate} from '../../../../utils/DefaultFunctions'

const Item2 = (props) => {
  let {data} = props
  let user = GetCurrentUser()
  const [info, setInfo] = useState()
  const [date, setDate] = useState()
  const [currentTime, setCurrentTime] = useState()

  useEffect(() => {
    data.type == 'escalation' &&
      API.get(`/Escalation/getById?EscalationId=${data.id}`, {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          setInfo(res.data.result.escalationDetails.$values)
          setDate(res.data.buildDate)
          setCurrentTime(new Date().toLocaleTimeString())
        })
        .catch((err) => {
          console.log(err, 'err')
        })
  }, [])

  return (
    <>
      {data.type == 'escalation' ? (
        <div
          className='timeline-item  mb-5'
          style={{borderRadius: '10px', border: '3px solid #ff5c5c', boxShadow: '0px 8px #e4e4e4'}}
        >
          {/* <div className='timeline-line w-70px'></div>

        <div className='timeline-icon symbol pt-5   symbol-circle symbol-40px'>
          <div className='symbol-label bg-light'>
            <KTSVG
              path='/media/icons/duotune/communication/com009.svg'
              className='svg-icon-2 svg-icon-gray-500'
            />
          </div>
        </div> */}

          <div className='timeline-content mb-8 pt-5 pe-5 mt-n2 w-600px ms-5'>
            <div className='overflow-auto pe-3'>
              <div className='fs-5 fw-bold mt-2'>
                <h3>{data.escalationName}</h3>
              </div>
              <div className='me-2 fs-19'>
                <h4 className='text-muted'>{data.activity}</h4>
                {info?.map((item, index) => {
                  return (
                    <div className='my-3'>
                      {/* <h2>Level: {index + 1} </h2> */}
                      {/* <h2>BODY: {item.body} </h2> */}
                      {/* <h2>SUBJECT: {item.subject} </h2> */}
                      {item.notificationShortDescription && (
                        <>
                          <div className='d-flex'>
                            <h4>Short description :</h4>
                            <p style={{fontSize: '11'}} className='ms-2 my-1'>
                              {item.notificationShortDescription}
                            </p>
                          </div>
                        </>
                      )}
                      {item.notificationDetailDescription && (
                        <>
                          <div className='d-flex'>
                            <h3>Detail description :</h3>
                            <p style={{fontSize: '11'}} className='ms-2 my-1'>
                              {item.notificationDetailDescription}
                            </p>
                          </div>
                        </>
                      )}
                      <div className='d-flex'>
                        <h4>Aging :</h4>
                        <p style={{fontSize: '11'}} className='ms-2 my-1'>
                          {item.aging}
                        </p>
                      </div>
                      <div className='d-flex'>
                        <h4>Time :</h4>
                        <p style={{fontSize: '11'}} className='ms-2 my-1'>
                          {item.time}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='d-flex align-items-center mt-1 fs-6'>
                <div className='text-muted me-2 fs-7'>Sent at {currentTime}</div>

                <div
                  className='symbol symbol-circle symbol-25px'
                  data-bs-toggle='tooltip'
                  data-bs-boundary='window'
                  data-bs-placement='top'
                  title='Alan Nilson'
                >
                  {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='img' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='timeline-item  mb-3 my-3'
          style={{borderRadius: '10px', border: '3px solid #398a8b', boxShadow: '0px 8px #e4e4e4'}}
        >
          <div className='timeline-line w-70px'></div>

          <div className='timeline-icon symbol pt-5   symbol-circle symbol-40px'>
            <div className='symbol-label ms-2' style={{backgroundColor: '#18A689'}}>
              <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-2' />
            </div>
          </div>

          <div className='timeline-content mb-8 pt-5 pe-5 mt-n2 w-600px'>
            <div className='overflow-auto pe-3'>
              <div className='text-muted fs-19 mt-2'>
                <h4>Short Description</h4>
                {data.shortDescription}
              </div>
              <div className='text-muted me-2 mt-2 fs-19'>
                <h4>Detail Description</h4>
                {data.detailDescription}
              </div>
              <div className='text-muted me-2 fs-19 mt-2'>
                <h4>Title</h4>
                {data.escalationTitle}
              </div>
              <div className='text-muted me-2 fs-19 mt-2'>
                <h4>Customer Name</h4>
                {data.customerName}
              </div>
              <div className='text-muted me-2 fs-19 mt-2'>
                <h4>Card Number</h4>
                {data.cardNumber.length < 16
                  ? data.cardNumber
                  : data.cardNumber.substring(0, 4) +
                    '*******' +
                    data.cardNumber.substring(data.cardNumber.length - 4)}
              </div>
              <div className='text-muted me-2 fs-19 mt-2'>{data.activity}</div>

              <div className='d-flex align-items-center mt-1 fs-6'>
                <div className='text-muted me-2 fs-7'>
                  Sent at {convertISODate(data.notificationAt)}{' '}
                  {convertISOTime(data.notificationAt)}{' '}
                </div>
                <div
                  className='symbol symbol-circle symbol-25px'
                  data-bs-toggle='tooltip'
                  data-bs-boundary='window'
                  data-bs-placement='top'
                  title='Alan Nilson'
                >
                  {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='img' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {Item2}
