import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'

// { id: 5, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const RoleTable = ({data, type,
  PageSize,
  PageNumber,
  pageNumber,
  pageSize,
  getDataById,
  deleteById,
  totalRecords}) => {
  // const [data, setData] = useState()

//   console.log(`DATAA ${totalRecords}===========`, data)

  const handleClick = (event, cellValues) => {
    console.log(cellValues)
  }
 
  const tableRows = []
 
  let user = GetCurrentUser()

  
  
  const roleColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'name', headerName: 'Role Name', width: 150},
    {field: 'normalizedName', headerName: 'Normalized Name', width: 150},
    {field: 'isActive', headerName: 'Is Active', width: 150},
    {
      field: 'update',
      headerName: 'Update',
      width: 120,
      renderCell: (e) => (
        <strong>
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 120,
      renderCell: (e) => (
        <strong>
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              deleteById(e.id)
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  if (type ) {
    data?.forEach((element, index) => {
      tableRows.push({
        id: element.id,
        SNo: index + 1,
        name: element.name,
        normalizedName: element.normalizedName,
        isActive:element.isActive,
        // province: element.province,

      })
    })
  }
 
  return (
    <div style={{height: "70vh", width: '100%'}}>
      

      {type == 'role' && (
        <DataGrid  
        rows={tableRows}
        rowCount={totalRecords}
        // loading={pageState.isLoading}
        rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
        pagination
        page={pageNumber - 1}
        pageSize={pageSize}
        paginationMode='server'
        onPageChange={(newPage) => {
          PageNumber(newPage + 1)
        }}
        onPageSizeChange={(newPageSize) => {
          PageSize(newPageSize)
        }}
        // getRowClassName={(params) => 'red-row'}
        cellClassName='dark-text'
        headerClassName='dark-text'
        columns={roleColumns}
        showCellRightBorder={true} />
      )}
    </div>
  )
}

export default RoleTable
