const DOMAIN_PREFIX = ''

export const STRINGS = {
  IBFT_ID: 'ZNJBaQBMy',
  AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v',
  API_STATUS: {
    DEFAULT: 200,
    CREATE: 201,
    BAD_REQUEST: 400,
    FAIL: 1001,
    SUCCESS: 1000,
  },
  API_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IlpOSkJhUUJNeSIsImVtYWlsIjoibXVrYXJyYW1fc2hhcmlmQGhvdG1haWwuY29tIiwic3ViIjoibXVrYXJyYW1fc2hhcmlmQGhvdG1haWwuY29tIiwianRpIjoiNWZlOWM0MDgtNmNkZi00MmU3LTk1YjEtMDU0ZjU0MGYxZDlkIiwicm9sZSI6IlN5c3RlbSBBZG1pbiIsIm5iZiI6MTY3MTIxODQ0OSwiZXhwIjoxNjcxMjIyMDQ5LCJpYXQiOjE2NzEyMTg0NDl9.-eRvHvFe-6BQtfomof90agWub8i9uO9hoWyUHA177TM',
  ROUTES: {
    ROOT: `${DOMAIN_PREFIX}/`,
    AUTH: `${DOMAIN_PREFIX}/auth`,
    DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
    BRANCH_SCREEN: `${DOMAIN_PREFIX}/br`,
    CFC_SCREEN: `${DOMAIN_PREFIX}/cfc`,
    HISTORY_SCREEN: `${DOMAIN_PREFIX}/his`,
    CARD_EMBOSE: `${DOMAIN_PREFIX}/crdem`,
    ONE_LINK_PROCESS_DETAIL: `${DOMAIN_PREFIX}/crdem`,
    BPC_CARD_NOT_FOUND_APPROVED: `${DOMAIN_PREFIX}/bpc_card_not_found_approved`,
    BRANCH: {
      INVENTORY: `${DOMAIN_PREFIX}/br_inv`,
      CARDDETAILS: `${DOMAIN_PREFIX}/br_details`,
      INVENTORYADDCARD: `${DOMAIN_PREFIX}/br_inv/addcard`,
      INVENTORYUPDATECARD: `${DOMAIN_PREFIX}/br_inv/updatecard`,
      EDITFORM: `${DOMAIN_PREFIX}/br_inv/edit`,
      SHREDFORM: `${DOMAIN_PREFIX}/shred`,
      DELIVERYFORM: `${DOMAIN_PREFIX}/delivery`,
      SENDTOCFCFORM: `${DOMAIN_PREFIX}/send_to_cfc`,
      SENDTOBRANCHFORM: `${DOMAIN_PREFIX}/send_to_branch`,
      MAIN: `${DOMAIN_PREFIX}/br_main`,
      HISTORY: `${DOMAIN_PREFIX}/br_his`,
      REQUEST_FORM: `${DOMAIN_PREFIX}/req_form`,
      MAIN_CARDS: `${DOMAIN_PREFIX}/card_main`,
      ON_APPROVAL: `${DOMAIN_PREFIX}/on_approval`,
      // GENERATE_CARDS: `${DOMAIN_PREFIX}/generate_cards`,
      CALENDAR: `${DOMAIN_PREFIX}/branch_calendar`,
      CARD_EMOBOSE_HISTORY: `${DOMAIN_PREFIX}/card_embose_history`,
      SAMBA_PHONE_HISTORY: `${DOMAIN_PREFIX}/card_embose_history`,
    },
    CFC: {
      ADDCARDCFC: `${DOMAIN_PREFIX}/cfc/addcard`,
      MAIN: `${DOMAIN_PREFIX}/cfc`,
      DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      GENERATE_CARDS: `${DOMAIN_PREFIX}/generate_cards`,
      CARD_EMOBOSE_HISTORY: `${DOMAIN_PREFIX}/card_embose_history`,
      HOLD: `${DOMAIN_PREFIX}/hold_cards`,
      DISCARD: `${DOMAIN_PREFIX}/discard_cards`,
      ATM_CAPTURED_CARD: `${DOMAIN_PREFIX}/atm_captured_card`,
      ADDED_BY_CFC: `${DOMAIN_PREFIX}/added_by_cfc`,
      ALL_CARDS: `${DOMAIN_PREFIX}/all_cards`,
    },
    MANAGEMENT: {
      MANAGEMENT_DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      HISTORY: `${DOMAIN_PREFIX}/history`,
      MAIN: `${DOMAIN_PREFIX}/management`,
    },
    ADMINISTRATOR: {
      ADMIN: `${DOMAIN_PREFIX}/admin`,
      CONFIGURE_INFO: `${DOMAIN_PREFIX}/configure_info`,
      CONFIGURE_USER_INFO: `${DOMAIN_PREFIX}/configure_user_info`,
      CONFIGURE_BRANCH_INFO: `${DOMAIN_PREFIX}/configure_bank/branch_info`,
      CONFIGURE_ORGANIZATION_INFO: `${DOMAIN_PREFIX}/configure_organization_info`,
      CREATE_GROUP: `${DOMAIN_PREFIX}/create_group`,
      CREATE_ROLES: `${DOMAIN_PREFIX}/create_roles`,
      CARDS_TIMELINE: `${DOMAIN_PREFIX}/cards_timeline`,
      CALENDER: `${DOMAIN_PREFIX}/calendar`,
      TAT: `${DOMAIN_PREFIX}/TAT`,
      CARD_EMBOSE_CONFIG: `${DOMAIN_PREFIX}/CARD_EMBOSE_CONFIG`,
      ACCOUNT_CATEGORY: `${DOMAIN_PREFIX}/account_category`,
      ACCOUNT_STATUS: `${DOMAIN_PREFIX}/account_status`,
      ACCOUNT_TYPE: `${DOMAIN_PREFIX}/account_type`,
      AVAILABLE_CURRENCY: `${DOMAIN_PREFIX}/available_currency`,
      CARD_CHANNEL: `${DOMAIN_PREFIX}/card_channel`,
      CARD_EMBOSING_CHANNEL: `${DOMAIN_PREFIX}/card_embosing_channel`,
      CARD_STATUS: `${DOMAIN_PREFIX}/card_status`,
      CARD_TYPE: `${DOMAIN_PREFIX}/card_type`,
      CARD_EMBOSING_TYPE: `${DOMAIN_PREFIX}/card_embosing_type`,
      CIF_TYPE: `${DOMAIN_PREFIX}/cif_type`,
      COURIER_CHANNEL: `${DOMAIN_PREFIX}/courier_channel`,
      REQUESTED_CHANNEL: `${DOMAIN_PREFIX}/requested_channel`,
      ACCOUNT_OPERATION: `${DOMAIN_PREFIX}/account_operation`,
      USER_CONFIG: `${DOMAIN_PREFIX}/user_config`,
      CARD_EMBOSE_BRANCH: `${DOMAIN_PREFIX}/card_embosing_branch`,
      CARD_EMBOSE_CITY: `${DOMAIN_PREFIX}/card_embosing_city`,
      CARD_EMBOSE_REGION: `${DOMAIN_PREFIX}/card_embosing_region`,
      ON_APPROVAL: `${DOMAIN_PREFIX}/on_approval`,
    },
    SAMBACARE: {
      MAIN: `${DOMAIN_PREFIX}/sambacare`,
      SAMBA_CARE_HISTORY: `${DOMAIN_PREFIX}/history`,
      CARD_EMOBOSE_HISTORY: `${DOMAIN_PREFIX}/card_embose_history`,
    },
    CHECKER: {
      DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      INVENTORY: `${DOMAIN_PREFIX}/br_inv`,
      INVENTORYADDCARD: `${DOMAIN_PREFIX}/br_inv/addcard`,
      MAIN_SCREEN: `${DOMAIN_PREFIX}/main`,
      HISTORY: `${DOMAIN_PREFIX}/history`,
      FORCE_TASK: `${DOMAIN_PREFIX}/force_task`,
      CARD_NOT_FOUND: `${DOMAIN_PREFIX}/card_not_found`,
      CARD_EMOBOSE_HISTORY: `${DOMAIN_PREFIX}/card_embose_history`,
    },
    SAMBACARE_CHECKER: {
      DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      MAIN_SCREEN: `${DOMAIN_PREFIX}/main`,
      HISTORY: `${DOMAIN_PREFIX}/history`,
    },
    SETTINGS: {
      DEFAULT: `${DOMAIN_PREFIX}/settings`,
      CONFIG: {
        // CONFIGURE_INFO: `${DOMAIN_PREFIX}/settings/configure_info`,

        DEFAULT: `${DOMAIN_PREFIX}/settings/config`,
        MEMO: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/memo`,
          CARD_DES_MEMO: `${DOMAIN_PREFIX}/settings/config/memo/card_destruction_memo`,
          OTHER_BANK: `${DOMAIN_PREFIX}/settings/config/memo/samba_card_on_other_bank`,
          OTHER_BANK_CFC: `${DOMAIN_PREFIX}/settings/config/memo/other_bank_cfc_on_samba`,
        },
        MASTER: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/master`,
          CREATE_DESIGNATION: `${DOMAIN_PREFIX}/settings/config/master/create_designation`,
          USER_MANAGER: `${DOMAIN_PREFIX}/settings/config/master/user_manager`,
          GROUP_EMAIL: `${DOMAIN_PREFIX}/settings/config/master/group_email`,
        },
        ALERT_AND_ESCALATIONS: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/alert_escalations`,
          CREATE_ALERT_DESINATION: `${DOMAIN_PREFIX}/settings/config/alert_escalations/create_alert_designation`,
          LIST: `${DOMAIN_PREFIX}/settings/config/create_alert_designation/list`,
        },
      },
    },
  },
}
