import React, {useRef, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import './AdminCalender.css'
import {
  CreateHoliday,
  DeleteBranchHoliday,
  DeleteHoliday,
  ExcludeBranchHoliday,
  GetBankById,
  GetBranchById,
  GetBranchHoliday,
  GetHoliday,
} from '../../../../GlobalSlices/global.slice'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import CalendarModal from './CalendarModal'
import {API} from '../../../../../utils/services'
import {Select} from 'antd'
import {ToastContainer, toast} from 'react-toastify'

const AdminCalendar = () => {
  const calendarRef = useRef()
  const dispatch = useDispatch()
  const {globalSlice} = useSelector(({Reducers}) => Reducers)
  const [selectedRange, setSelectedRange] = useState(null) // Store the selected date range
  const [branch, setBranch] = useState([])
  const [selectedBranchId, setSelectedBranchId] = useState('')
  const [calenderData, setCalenderData] = useState([])

  // console.log('global slice', globalSlice)
  const {weekend} = globalSlice
  // console.log(weekend, 'weekend')
  let user = GetCurrentUser()

  const getAllBranches = () => {
    API.get(`/Branch?PageNumber=1&PageSize=100`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    })
      .then((res) => {
        // setBranch(res.data.result.data.$values)
        let branchArr = res.data.result.data.$values.map((el) => {
          return {
            value: el.id,
            label: el.branchName,
          }
        })

        // const all = {
        //   value: '1',
        //   label: 'All',
        // }
        // branchArr = [all, ...branchArr]
        setBranch(branchArr)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    getAllBranches()
  }, [])

  useEffect(() => {
    if (selectedBranchId) {
      if (selectedBranchId !== '1') {
        dispatch(GetBranchHoliday(user?.api_token, selectedBranchId))
        dispatch(GetBranchById(user?.api_token, selectedBranchId)) //samba bank Id
      } else {
        dispatch(GetHoliday(user?.api_token, 'ZNJBaQBMy'))
        dispatch(GetBankById(user?.api_token, 'ZNJBaQBMy')) //samba bank Id
      }
    } else {
      dispatch(GetHoliday(user?.api_token, 'ZNJBaQBMy'))
      dispatch(GetBankById(user?.api_token, 'ZNJBaQBMy')) //samba bank Id
    }
  }, [selectedBranchId])

  const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6
  }

  const dayRender = (info) => {
    if (isWeekend(info.date)) {
      const el = info.el
      el.classList.add('fc-holiday') // add custom class to weekend cells
    }
    // console.log('select range work')
    if (selectedRange) {
      // console.log('select range work')
      const {start, end} = selectedRange
      if (info.date >= start && info.date <= end) {
        const el = info.el
        el.classList.add('fc-selected') // add custom class to selected dates
      }
    }
  }

  const deleteHolidayEvent = () => {
    if (selectedBranchId) {
      // console.log(data.extendedProps, 'dataaaa')
      if (data.extendedProps.branch != null) {
        //delete holiday
        dispatch(DeleteBranchHoliday(user?.api_token, data.publicId))
      } else {
        //exclude holiday
        dispatch(ExcludeBranchHoliday(user?.api_token, data.publicId, selectedBranchId))
      }
    } else {
      dispatch(DeleteHoliday(user?.api_token, data.publicId))
    }

    setOpen(false)
    setData({})
  }

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})

  const handleSelect = (arg) => {
    const {start, end, startStr, endStr} = arg
    setSelectedRange({start, end})
    //TODO open modal
    setData(arg)
    setOpen(true)
  }

  const handleDateClick = (arg) => {
    // console.log(arg, 'bbbbbbbbb')
    setSelectedRange(arg)
    //TODO open modal
    setData(arg)
    setOpen(true)
  }

  const handleCreate = (dt) => {
    dispatch(CreateHoliday(user?.api_token, dt))
  }

  useEffect(() => {
    //there will be two dependencies one calender data and true/false for satoff and sunoff
    // const recurringEvents = generateRecurringEvents()
    // console.log('Generated recurring events:', recurringEvents)

    const {isSaturdayOff, isSundayOff} = weekend

    let ob = {
      title: 'Sunday Off',
      color: '#3F4254',
      rrule: {
        freq: 'weekly',
        interval: 1,
        byweekday: [6], //sunday
        dtstart: '2020-01-01T10:30:00', // will also accept '20120201T103000'
        until: '2026-01-01', // will also accept '20120201'
      },
    }

    let ob2 = {
      title: 'Saturday Off',
      color: '#3F4254',
      rrule: {
        freq: 'weekly',
        interval: 1,
        byweekday: [5], //Sat
        dtstart: '2020-01-01T10:30:00', // will also accept '20120201T103000'
        until: '2026-01-01', // will also accept '20120201'
      },
    }

    let pay = [...globalSlice.calendarData]

    if (isSundayOff) {
      // console.log('sunday off if')
      pay.push(ob)
    }

    if (isSaturdayOff) {
      // console.log('sat off if')
      pay.push(ob2)
    }
    setCalenderData(pay)
  }, [globalSlice.calendarData, weekend])

  // console.log(globalSlice.calendarData, 'globalSlice.calendarData')

  return (
    <>
      <div className='calender-parent'>
        <div className='d-flex'>
          <label className='form-label my-2 me-5' style={{fontSize: '1.2em'}}>
            Select Branch:
          </label>
          <Select
            showSearch
            style={{
              width: 200,
            }}
            placeholder='Search to Select'
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(e) => setSelectedBranchId(e)}
            options={branch}
            allowClear={true}
          />
        </div>

        <FullCalendar
          defaultView='dayGridMonth'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth',
          }}
          editable={true}
          selectable={true}
          firstDay={1}
          events={calenderData}
          eventClick={(info) => {
            // console.log('eventttt', info.event._def.title != 'Sunday Off')
            if (info.event._def.title != 'Saturday Off' && info.event._def.title !== 'Sunday Off') {
              // console.log('if block')
              setData(info.event._def)
              setOpen(true)
            }
          }}
          dayRender={dayRender}
          ref={calendarRef}
          dateClick={handleDateClick}
          select={handleSelect} // Call handleSelect when a date range is selected
        />
      </div>
      {open && (
        <CalendarModal
          open={open}
          onClose={() => setOpen(false)}
          onDelete={deleteHolidayEvent}
          data={data}
          handleCreateHoliday={handleCreate}
          selectedBranch={selectedBranchId}
          branchList={branch}
          toast={toast}
        />
      )}
      <ToastContainer />
    </>
  )
}

export default AdminCalendar
