import axios from 'axios'

export const BASE_DOMAIN = (() => {
  // return window.location.protocol + "//apicardcapture.samba.com.pk/api";
  if (window.location.hostname === 'cards.3em.tech' || window.location.hostname === 'localhost')
    return 'http://api.cards.3em.tech/api/'
  // return 'http://localhost:8080/api/'
  // return "http://159.69.72.39:5001";
  // else return 'http://apiuatcardcapture.samba.com.pk:8080/api/'
  else return 'https://apicardcapture.samba.com.pk/api/'
  // else return 'https://cardcapture.samba.com.pk/api/'
  // else return window.location.protocol + "//apicardcapture.samba.com.pk/api"
})()

export const BASE_URL = BASE_DOMAIN
export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  httpAgent: true,
})
