/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import BranchSelect from './BranchSelect'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {AppDispatch, useAppSelector} from '../../../../utils/hook'
import {getLogin} from './Slices/auth.slice'
import {isPending} from '@reduxjs/toolkit'
import {API} from '../../../../utils/services'
import {ToastContainer} from 'react-toastify'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
type Slices = {authSlice: any; globalSlice: any}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState<any>({isPending: null, isSuccess: null})
  const [isBranch, setIsBranch] = useState(false)
  const [isAuth, setIsAuth] = useState<any>({})
  const {saveAuth, setCurrentUser} = useAuth()

  const {authSlice, globalSlice} = useAppSelector(({Reducers}) => Reducers as Slices)

  const [expirationTime, setExpirationTime] = useState(1000)

  console.log(process.env.REACT_APP_AD_ENABLED, 'process.env.AD_ENABLED')

  useEffect(() => {
    console.log('authSlice////////////////////', authSlice)

    if (!globalSlice.isPending && authSlice.loginSuccess) {
      // if (!init.isPending && init.isSuccess) {
      // console.log('TESTT==========>', formik)
      let {values} = formik
      setLoading(true)
      let auth: any = {
        id: 2,
        type: authSlice.userType,
        first_name: values.email,
        // "last_name": "Stark",
        email: values.email,
        email_verified_at: '2022-07-14T11:37:39.000000Z',
        created_at: '2022-07-14T11:37:39.000000Z',
        updated_at: '2022-07-14T11:37:39.000000Z',
        api_token: authSlice.token,
        refreshToken: authSlice.refreshToken,
        statusCode: authSlice.statusCode,
      }
      if (
        authSlice.userType === 'branch' ||
        authSlice.userType == 'branch maker' ||
        authSlice.userType === 'branch checker'
      ) {
        setIsBranch(true)
        setIsAuth(auth)
      } else {
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // console.log('data==>', auth)
        setCurrentUser(auth)
      }

      // }
      // else {
      //   setStatus('The login detail is incorrect')
      // }
      // setInit({
      //   ...init,
      //   isPending:false,
      //   isSuccess:true
      // })
    }
  }, [globalSlice.isPending, authSlice.success])

  const dispatch = useDispatch<AppDispatch>()
  const formik = useFormik({
    initialValues,
    // validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const {email, password} = values
      let users: any = ['cfc', 'branch', 'sambaphone', 'amd']
      if (values.email !== '' && values.password !== '') {
        dispatch(getLogin({email, password}))
      } else {
        setStatus('Please enter your credentials to proceed!')
      }
    },
  })

  const handleSelectBranch = () => {
    saveAuth(isAuth)
    setCurrentUser(isAuth)
  }

  return isBranch ? (
    <BranchSelect onSelect={() => handleSelectBranch()} />
  ) : (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Enter Your Credentials to login
            {/*<Link to='/auth/registration' className='link-primary fw-bolder'>*/}
            {/*  Create an Account*/}
            {/*</Link>*/}
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div />
          // <div className='mb-10 bg-light-info p-8 rounded'>
          //   <div className='text-info'>
          //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          //     continue.
          //   </div>
          // </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid'
              // { 'is-invalid': formik.touched.email && formik.errors.email },
              // {
              //   'is-valid': formik.touched.email && !formik.errors.email,
              // }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {/* {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )} */}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/*<Link*/}
              {/*  to='/auth/forgot-password'*/}
              {/*  className='link-primary fs-6 fw-bolder'*/}
              {/*  style={{marginLeft: '5px'}}*/}
              {/*>*/}
              {/*  Forgot Password ?*/}
              {/*</Link>*/}
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid'
              // {
              //   'is-invalid': formik.touched.password && formik.errors.password,
              // },
              // {
              //   'is-valid': formik.touched.password && !formik.errors.password,
              // }
            )}
          />
          {/* {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )} */}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!globalSlice.isPending && <span className='indicator-label'>Sign-in</span>}
            {globalSlice.isPending && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            )}
          </button>

          {/* begin::Separator */}
          {/*<div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>*/}
          {/* end::Separator */}

          {/* begin::Google link */}
          {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
          {/*  <img*/}
          {/*    alt='Logo'*/}
          {/*    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
          {/*    className='h-20px me-3'*/}
          {/*  />*/}
          {/*  Continue with Google*/}
          {/*</a>*/}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
          {/*  <img*/}
          {/*    alt='Logo'*/}
          {/*    src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}*/}
          {/*    className='h-20px me-3'*/}
          {/*  />*/}
          {/*  Continue with Facebook*/}
          {/*</a>*/}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>*/}
          {/*  <img*/}
          {/*    alt='Logo'*/}
          {/*    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
          {/*    className='h-20px me-3'*/}
          {/*  />*/}
          {/*  Continue with Apple*/}
          {/*</a>*/}
          {/* end::Google link */}
          {/* <div>
            <h5>usernames : </h5>
            <div className="in-flx">
              <h6 className="my-3 me-5"><span>1.</span><strong> cfc </strong></h6>
              <h6 className="my-3 me-5"><span>2.</span><strong> branch </strong></h6>
              <h6 className="my-3 me-5"><span>3.</span><strong> sambaphone </strong></h6>
              <h6 className="my-3 me-5"><span>4.</span><strong> amd </strong></h6>
            </div>
            <h5 className="my-3">Password : (admin123!@#)</h5>
          </div> */}
        </div>
        {/* end::Action */}
      </form>
      <ToastContainer />
    </>
  )
}
