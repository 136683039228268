import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {cardHoldSchema} from '../../../../../schemas'
import {useDispatch, useSelector} from 'react-redux'
import {CardActiveModal} from '../../Slices/CFC.slice'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'

const initialValues = {
  reason: '',
  days_to_hold: '',
}

let user = GetCurrentUser()

const CardActive = (props) => {
  const [init, setInit] = useState({
    cardNumber: '',
    customerName: '',
    capturedCardId: '',
    note: '',
  })

  const [toBeHot, setToBeHot] = useState(false)

  const {values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues: initialValues,
    validationSchema: cardHoldSchema,
    onSubmit: (values, action) => {
      dispatch(
        CardActiveModal({
          note: init.note,
          id: init.capturedCardId,
          token: user.api_token,
          toBeHot: toBeHot,
        })
      )

      action.resetForm()
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.cardId !== '' && props.cardId !== null && props.cardId !== undefined) {
      setInit({
        ...init,
        cardNumber: props.cardId.cardNumber,
        capturedCardId: props.cardId.id,
        note: props.cardId.notes,
        customerName: props.cardId.customerName,
      })
    }
  }, [props])

  const CardActiveButton = (values) => {
    dispatch(
      CardActiveModal({
        note: init.note,
        id: init.capturedCardId,
        token: props.token,
        toBeHot: toBeHot,
      })
    )
  }

  // cfcSlice.cardHoldResponse && navigate(`${STRINGS.ROUTES.BRANCH.MAIN}`)

  useEffect(() => {
    return () => {
      console.log('unmount modal')
    }
  }, [])

  return (
    <div
      class='modal fade'
      id='cardActive'
      tabindex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog'>
        <div class='modal-content'>
          <div class='modal-header' style={{backgroundColor: '#18A689'}}>
            <h1 class='modal-title fs-5' id='exampleModalLabel' style={{color: 'white'}}>
              Card Active
            </h1>
            <button
              type='button'
              class='btn-close'
              style={{color: 'white'}}
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div class='modal-body'>
            {/* <!--begin::input--> */}
            <form onSubmit={handleSubmit}>
              <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label class='fs-6 fw-semibold required form-label mb-2'>Customer Name</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div class='position-relative'>
                  {/* <!--begin::Input--> */}
                  <input
                    type='text'
                    class='form-control form-control-solid'
                    placeholder=''
                    name='card_number'
                    value={init.customerName}
                  />
                  {/* <!--end::Input--> */}
                </div>
                <div class='fv-plugins-message-container invalid-feedback'></div>
              </div>
              {/* <!--end::Input--> */}

              {/* <!--begin input--> */}
              {/* <!--begin::Input group--> */}
              <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label class='fs-6 fw-semibold required form-label mb-2'>CARD NUMBER</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div class='position-relative'>
                  {/* <!--begin::Input--> */}
                  <input
                    type='text'
                    class='form-control form-control-solid'
                    placeholder=''
                    name='card_number'
                    value={init.cardNumber}
                  />
                  {/* <!--end::Input--> */}
                </div>
                <div class='fv-plugins-message-container invalid-feedback'></div>
              </div>
              {/* <!--end::Input group--> */}
              {/* <!--end input--> */}
              {/* <!--begin::input--> */}
              <div class='form-check form-check-custom form-check-solid mb-2'>
                <input
                  class='form-check-input'
                  type='checkbox'
                  checked={toBeHot}
                  onChange={(e) => setToBeHot(!toBeHot)}
                />
                <label className='form-check-label me-3'>
                  <strong>Is Hot?</strong>
                </label>
              </div>
              {/* <!--end::Input--> */}
              <div class='modal-footer'>
                <button
                  type=''
                  class='btn btn-secondary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    console.log('falsee')
                    setToBeHot(false)
                  }}
                >
                  Close
                </button>
                <button
                  onClick={() => CardActiveButton(values)}
                  type='onsubmit'
                  class='btn btn-primary'
                  // data-bs-dismiss={cfcSlice.cardActiveData.success? "modal":""}
                  data-bs-dismiss='modal'
                  disabled={!toBeHot} //submit button will be disable when to be hot check is unchecked
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardActive
