import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {recievedThroughSchema, riderValidation} from '../../../../../schemas'
import {useDispatch, useSelector} from 'react-redux'
import {ReceiveAtBranch, ReceiveAtCfc} from '../../Slices/CFC.slice'
import {useNavigate} from 'react-router-dom'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import {toast, ToastContainer} from 'react-toastify'

const initialValues = {
  courier_num: '',
  rider_name: '',
}

let user = GetCurrentUser()

const CardFromBranch = (props) => {
  let courierName = props.courierCompanyName
  console.log('d123', props.courierCompanyName);
  const [submitSuccess, setSubmitSucess] = useState(false)
  const [init, setInit] = useState({
    selectedRec: 0,
    isSearchPending: false,
    cardNumber: '',
    courier_num: '',
    courierCompany: props.courierCompanyName,
    capturedBranch: '',
    riderName: '',
  })

  useEffect(() => {
    return () => {
      console.log('unmount')
    }
  }, [])

  let {selectedRec, isSearchPending} = init
  const dispatch = useDispatch()
  let navigate = useNavigate()

  let user = GetCurrentUser()

  const onSubmitBtn = (values) => {
    setSubmitSucess(false)
    if (selectedRec === 1) {
      if (props.type == 'branch') {
        if (values.courier_num >= 5 || init.courier_num) {
          dispatch(
            ReceiveAtBranch(
              props.cardId,
              values,
              selectedRec,
              props.branchId,
              token,
              init.courier_num,
              courierName
            )
          )
          console.log('log1', values);
          console.log('log2', init);
          console.log('log3', props);
          // setSubmitSucess(true)
        } else {
          toast.error('Courier number is required', {
            position: 'bottom-right',
            theme: 'colored',
          })
          return
        }
      }
      if (props.type == 'cfc') {
        if (values.courier_num >= 5) {
          //payload true is set for conditional payload for rider name and courier num
          dispatch(ReceiveAtCfc(props.cardId, values, selectedRec, props.branchId, token, true))
          setSubmitSucess(true)
        } else {
          toast.error('Courier number is required', {
            position: 'bottom-right',
            theme: 'colored',
          })
          return
        }
      }
    } else if (selectedRec === 2) {
      if (props.type == 'branch') {
        if (values.rider_name) {
          dispatch(ReceiveAtBranch(props.cardId, values, selectedRec, props.branchId, token, false))
          setSubmitSucess(true)
        } else {
          toast.error('Rider name is requried', {
            position: 'bottom-right',
            theme: 'colored',
          })
          return
        }
      }
      if (props.type == 'cfc') {
        if (values.rider_name) {
          dispatch(ReceiveAtCfc(props.cardId, values, selectedRec, props.branchId, token, false))
          setSubmitSucess(true)
        } else {
          toast.error('Rider name is required!!', {
            position: 'bottom-right',
            theme: 'colored',
          })
          return
        }
      }
    }
  }

  const validationFunc = (selected) => {
    if (selected === 1) {
      return recievedThroughSchema
    } else if (selected === 2) {
      return riderValidation
    }
  }

  const {values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues: initialValues,
    validationSchema: validationFunc(selectedRec),
    onSubmit: (values) => {
      onSubmitBtn(values)
      resetForm()
    },
  })

  useEffect(() => {
    if (props.cardId !== '' && props.cardId !== null && props.cardId !== undefined) {
      setInit({
        ...init,
        cardNumber: props.cardId.cardNumber,
        courier_num: props.cardId.courierNumber,
        acquirerBranch: props.cardId.acquirerBranch,
        riderName: props.cardId.riderName,
      })
    }
  }, [props.cardId])

  let token = user.api_token

  const handleSwitchRecivedThrow = (val) => {
    setInit({
      ...init,
      selectedRec: val,
    })
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_new_card'
      tabindex='-1'
      aria-modal='true'
      role='dialog'
      data-bs-backdrop='static'
    >
      {/* <!--begin::Modal dialog--> */}
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        {/* <!--begin::Modal content--> */}
        <div className='modal-content'>
          {/* <!--begin::Modal header--> */}
          <div className='modal-header'>
            {/* <!--begin::Modal title--> */}
            <h2>Are you Sure, You want to recieve this card?</h2>

            {/* <!--end::Modal title--> */}
            {/* <!--begin::Close--> */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg--> */}
              <span className='svg-icon svg-icon-1'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='currentColor'
                  ></rect>
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='currentColor'
                  ></rect>
                </svg>
              </span>
              {/* <!--end::Svg Icon--> */}
            </div>
            {/* <!--end::Close--> */}
          </div>
          {/* <!--end::Modal header--> */}
          {/* <!--begin::Modal body--> */}
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            {/* <!--begin::Form--> */}
            <form
              id='kt_modal_new_card_form'
              className='form fv-plugins-bootstrap5 fv-plugins-framework'
              action='#'
              onSubmit={handleSubmit}
            >
              {/* <!--begin::Input group--> */}
              <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label className='fs-6 fw-semibold form-label mb-2'>Card Number</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div className='position-relative'>
                  {/* <!--begin::Input--> */}
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='2784 0018 267 (Auto Populated Field)'
                    name='card_number'
                    value={init.cardNumber}
                    // readonly
                  />
                  {/* <!--end::Input--> */}
                  {/* <!--end::Input wrapper--> */}
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
                {/* <!--end::Input group--> */}
                <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                  {/* <!--begin::Label--> */}
                  <label className='fs-6 fw-semibold form-label mb-2'>Sender</label>
                  {/* <!--end::Label--> */}
                  {/* <!--begin::Input wrapper--> */}
                  <div className='position-relative'>
                    {/* <!--begin::Input--> */}
                    <input
                      type='text'
                      name='company'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=''
                      value={init.acquirerBranch ? init.acquirerBranch : 'CFC'}
                      readonly
                    />
                    {/* <!--end::Input--> */}
                  </div>
                  {/* <!--end::Input wrapper--> */}
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
                {/* <!--end::Input group--> */}

                {/* <!--end::Input group--> */}
                <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                  {/* <!--begin::Label--> */}
                  <div class='d-flex'>
                    <label className='fs-6 fw-semibold form-label mb-2 me-3 required'>
                      Received through{' '}
                      <span>
                        <strong>:</strong>
                      </span>
                    </label>
                    <div>
                      <input
                        class='form-check-input me-3 '
                        type='radio'
                        onClick={() => {
                          handleSwitchRecivedThrow(1)
                        }}
                        name='yesno'
                        id='yesCheck'
                        value='1'
                        required
                      />
                      <label className='fs-6 fw-semibold form-label mb-2 me-3'>Courier</label>
                    </div>

                    <div>
                      <input
                        class='form-check-input'
                        type='radio'
                        onClick={() => {
                          handleSwitchRecivedThrow(2)
                        }}
                        name='yesno'
                        id='noCheck'
                        value='2'
                      />

                      {/* <input class="form-check-input me-2" type="radio" onClick={() => {
                                                handleSwitchRecivedThrow(2)
                                            }} name="yesno" id="noCheck" value="2" /> */}
                      <label className='fs-6 fw-semibold form-label mb-2 me-3'>Rider</label>
                    </div>
                  </div>

                  {/* <!--end::Label--> */}
                  {/* <!--begin::Input wrapper--> */}
                  <div className='position-relative my-4'>
                    {/* <!--begin::Input--> */}
                    {selectedRec === 1 && (
                      <div id='ifYes' class='hidediv active'>
                        <div className='d-flex'>
                          <div className='me-2'>
                            <label
                              className=' fw-semibold mb-2 required'
                              style={{fontSize: '12px'}}
                            >
                              Courier Number
                            </label>
                            <input
                              type='number'
                              autoComplete='off'
                              name='courier_num'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='Enter Courier number'
                              id='courier_num'
                              value={values.courier_num}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.courier_num && touched.courier_num ? (
                              <p className='form-error text-danger my-3'>{errors.courier_num}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedRec === 2 && (
                      <div id='ifNo' class='hidediv active'>
                        <div className='d-flex'>
                          <div className='me-2'>
                            <label
                              className=' fw-semibold mb-2 required'
                              style={{fontSize: '12px'}}
                            >
                              Rider Name
                            </label>
                            <input
                              type='text'
                              name='rider_name'
                              className='form-control form-control-lg form-control-solid'
                              placeholder='Enter Rider Name'
                              id='rider_name'
                              value={values.rider_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.rider_name && touched.rider_name ? (
                              <p className='form-error text-danger my-3'>{errors.rider_name}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <!--end::Input--> */}
                  </div>
                  {/* <!--end::Input wrapper--> */}
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
                {/* <!--end::Input group--> */}

                {/* <!--begin::Actions--> */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                    onClick={() => {
                      resetForm()
                      setInit({
                        ...init,
                        selectedRec: 0,
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type='onSubmit'
                    className='btn btn-primary'
                    // data-bs-dismiss={cfcSlice.receiveAtBranchResp ? "modal":""}
                    data-bs-dismiss={'modal'}
                    // onClick={() => {
                    //   onSubmitBtn(values)
                    // }}
                    id='skicka'
                  >
                    <span className='indicator-label'>Recieve</span>
                    {/* <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span> */}
                  </button>
                </div>
                {/* <!--end::Actions--> */}
              </div>
            </form>
            {/* <!--end::Form--> */}
          </div>
          {/* <!--end::Modal body--> */}
        </div>
        {/* <!--end::Modal content--> */}
      </div>
      {/* <!--end::Modal dialog--> */}
      <ToastContainer />
    </div>
  )
}

export default CardFromBranch
