import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {sameBank} from '../../../../../schemas'
import {useSelector} from 'react-redux'
import {
  convertISODateWithSlashed,
  formatAMPM,
  GetCurrentUser,
} from '../../../../../utils/DefaultFunctions'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {API} from '../../../../../utils/services'
import {useLocation, useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'
import '../../../Branch/items/index.css'
import {STRINGS} from '../../../../../utils/Enums'

const EditFormInventory = () => {
  const navigate = useNavigate()
  const {branchSlice} = useSelector(({Reducers}) => Reducers)

  const location = useLocation()
  const {data} = location.state

  console.log('ganduuu', data);
  let initialValues = {
    $id: '',
    capturedDate: '',
    capturedBranchId: '',
    capturedBranchName: '',
    cfcId: '',
    customerName: '  ',
    cardNumber: '',
    cardExpiry: '',
    cifNumber: '',
    cardTypeId: '',
    cardType: '',
    cardChannelId: '',
    cardChannel: '',
    cnic: '',
    nativeBranchId: '',
    nativeBranchName: '',
    cardOwnerBankId: '',
    cardOwnerBankName: '',
    organizationId: '',
    organization: '',
    cardAcquirerBankId: '',
    cardAcquirerBankBranchId: '',
    cardStatusId: '',
    cardStatus: '',
    reasonForCaptured: '',
    notes: '',
    receivingDate: '',
    atmId: '',
    Sno: '',
  }
  const [formState, setFormState] = useState(initialValues)
  const [cardStatus, setCardStatus] = useState()

  useEffect(() => {
    if (data) {
      const {
        $id,
        SNo,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardAcquirerBankBranchId,
        cardAcquirerBankId,
        cardChannel,
        cardChannelId,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cardTypeIdl,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        id,
        nativeBranchName,
        nativeBranchId,
        notes,
        organizationName,
        organizationId,
        reasonForCaptured,
        atmId,
        receivingDate,
      } = data
      setFormState({
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardAcquirerBankBranchId,
        cardAcquirerBankId,
        cardChannel,
        cardChannelId,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cardTypeIdl,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        id,
        nativeBranchName,
        nativeBranchId,
        notes,
        organization: organizationName,
        organizationId,
        reasonForCaptured: 'Annonymous',
        receivingDate,
        $id,
        atmId,
        SNo,
      })
    }
  }, [data])

  let current_id = new URL(window.location).searchParams.get('id')

  let user = GetCurrentUser().api_token

  const [date, setDate] = useState(dayjs().format())
  const [dateSelected, setDateSelected] = useState(false)
  const [convertedDate, setConvertedDate] = useState(dayjs('2023-01-01T00:00:00'))
  const [cardTypes, setCardTypes] = useState()
  const [bankList, setBankList] = useState([])

  // const handleDateChange = (newValue) => {
  //   setDateSelected(true)
  //   console.log('handleDateChange', newValue)
  //   let selectedDate = convertISODateWithSlashed(new Date(newValue))
  //   let selectedTime = formatAMPM(new Date(newValue))
  //   let convertedDateTime = new Date(`${selectedDate} ${selectedTime} UTC`).toISOString()
  //   setDate(newValue)
  //   setConvertedDate(convertedDateTime)

  //   console.log(convertedDate)
  // }

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  useEffect(() => {
    API.get('/Card/cardchannels', {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
      .then((res) => {
        setCardTypes(res.data.result.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })

    API.get('/Card/cardstatus', {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
      .then((res) => {
        setCardStatus(res.data.result.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
    const body = {
      pageNumber: 1,
      pageSize: 100,
    }
    API.post('/Bank/Search', body, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
      .then((res) => {
        setBankList(res.data.result.data.$values)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [])

  useEffect(() => {
    if (formState.capturedBranchId) {
      API.post(
        '/Branch/Search',
        {
          pageNumber: 1,
          pageSize: 100,
        },
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
        .then((res) => {
          res.data.result.data.$values.forEach((element) => {
            if (element.id === formState.nativeBranchId) {
              setFormState((prevState) => ({
                ...prevState,
                issuerBranchCity: element.city,
              }))
            }
          })
          res.data.result.data.$values.forEach((element) => {
            if (element.id === formState.capturedBranchId) {
              setFormState((prevState) => ({
                ...prevState,
                acquirerBranchCity: element.city,
              }))
            }
          })
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [formState.nativeBranchId])

  useEffect(() => {
    if (formState.organization) {
    }
  }, [formState.organization])

  const updateApiCall = (bpcData) => {
    console.log(bpcData, 'update function')
    API.put('/Card/bpc/update', bpcData, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
      .then((res) => {
        console.log(res, 'sucess')
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const formSubmit = (values) => {
    //CASE 1: SAME BANK
    if (!formState.notes) {
      toast.error('Error: Notes is Required!!', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }
    if (formState.nativeBranchId) {
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardAcquirerBankBranchId,
        cardAcquirerBankId,
        cardChannel,
        cardChannelId,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        nativeBranchName,
        nativeBranchId,
        notes,
        reasonForCaptured,
        receivingDate,
        issuerBranchCity,
        acquirerBranchCity,
        $id,
        atmId,
        id,
      } = formState

      let updatedData = {
        $id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardStatus,
        cardStatusId,
        cfcId,
        cifNumber,
        cardType,
        cnic,
        customerName,
        nativeBranchName,
        nativeBranchId,
        notes,
        atmId,
        id,
        cardImported: false,
      }
      updateApiCall(updatedData)
      //TODO: navigate back
      navigate(`${STRINGS.ROUTES.BRANCH.INVENTORY}?id=${current_id}`)
    }

    //CASE 2: OTHER BANK
    if (formState.cardOwnerBankName) {
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        $id,
        id,
        atmId,
      } = formState
      let updatedData = {
        $id,
        id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        cardOwnerBankName,
        cardOwnerBankId,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        atmId,
        cardImported: false,
      }

      updateApiCall(updatedData)
      // //TODO: navigate back
      navigate(`${STRINGS.ROUTES.BRANCH.INVENTORY}?id=${current_id}`)
    }

    //CASE 2: OTHER ORGANIZATION
    if (formState.organization) {
      let {
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        organizationId,
        organization,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        $id,
        atmId,
        id,
      } = formState
      let updatedData = {
        $id,
        id,
        capturedBranchName,
        capturedBranchId,
        capturedDate,
        cardExpiry,
        cardNumber,
        organizationId,
        organizationName: organization,
        cardStatus,
        cardStatusId,
        cardType,
        cfcId,
        cifNumber,
        cnic,
        customerName,
        notes,
        atmId,
        cardImported: false,
      }

      updateApiCall(updatedData)
      //TODO: navigate back
      navigate(`${STRINGS.ROUTES.BRANCH.INVENTORY}?id=${current_id}`)
    }
  }

  //   const formSubmit = (values) => {
  //     let {acq_bank, note, captureReason, number, customerName, expiry, cif, cnic} = values
  //     let {cardTypeId, cardStatusId, nativeBranchId, cardChannelId} = branchSlice
  //     // const isoDate = date.toISOString()

  //     let body = {
  //       convertedDate,
  //       current_id,
  //       acquirerBank,
  //       customerName,
  //       number,
  //       expiry,
  //       cif,
  //       cnic,
  //       cardTypeId,
  //       cardStatusId,
  //       nativeBranchId,
  //       cardChannelId,
  //       captureReason,
  //       note,
  //       user,
  //     }
  //     // if(date){
  //     if (dateSelected && note && customerName && expiry && number && nativeBranchId) {
  //       dispatch(AddCardBranch(body))
  //     }

  //     if (!note || !customerName || !expiry || !number) {
  //       toast.error('Fill all mandatory fields!', {
  //         position: 'bottom-right',
  //         theme: 'colored',
  //       })
  //     }
  //     if (!dateSelected) {
  //       toast.error('Select Captured Date!', {
  //         position: 'bottom-right',
  //         theme: 'colored',
  //       })
  //     }
  //     ResetState()
  //   }

  //   useEffect(() => {
  //     if (branchSlice.cardAddResult) {
  //       toast.success('Card Added Successfully', {
  //         position: 'bottom-right',
  //         theme: 'colored',
  //       })

  //       navigate(`${STRINGS.ROUTES.BRANCH.MAIN}?id=${current_id}`)
  //       //reset func
  //     }
  //   }, [branchSlice.cardAddResult])

  //   const onChange = (event) => {
  //     setValue(event.target.value)
  //   }

  //   const onSearch = (searchTerm) => {
  //     setValue(searchTerm)
  //     // our api to fetch the search result
  //     console.log('search ', searchTerm)
  //   }

  //   const minDate = new Date()
  //   minDate.setDate(minDate.getDate() - 1)

  //   useEffect(() => {
  //     if (data) {
  //       setCurrentCardStatusId(data.cardStatusId ? data.cardStatusId : '')
  //     }
  //   }, [data])\

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: formState,
    validationSchema: sameBank,
    onSubmit: (values) => {
      console.log('values', values)
    },
  })

  return (
    <div id='kt_app_content' class='app-content flex-column-fluid'>
      {/*<!--begin::Content container-->*/}
      <div id='kt_app_content_container' class='app-container container-xx'>
        {/*<!--begin::Navbar-->*/}
        <div class='card mb-6 mb-xl-9'>
          <div class='card-body pt-9 pb-0'>
            {/*<!--begin::Details-->*/}
            <div class='d-flex flex-wrap flex-sm-nowrap mb-6'>
              {/*<!--begin::Nav-->*/}

              {/*<!--end::Nav-->*/}
            </div>
            {/*<!--end::Details-->*/}
          </div>
        </div>
        {/*<!--end::Navbar-->*/}
        {/*<!--begin::Row-->*/}
        {/*<!--Mytabcontent start-->*/}
        <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}

          <div
            className='container me-3'
            style={{backgroundColor: 'white', border: '1px solid #E4E4E4', borderRadius: '10px'}}
          >
            <div className='my-5' style={{marginLeft: '-2rem', padding: '15px'}}>
              <form class='form fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit}>
                <div className='d-flex mb-5'>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Capture Date / Time</strong>
                    </label>
                    <input
                      type='string'
                      class='form-control form-control-solid'
                      name='date'
                      id='date'
                      value={dayjs(formState.capturedDate).format('YYYY-MM-DD HH:mm:ss A')}
                      disabled
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DateTimePicker
                          // label='Date&Time picker'
                          maxDate={date}
                          minDate={minDate}
                          className='form-control form-control-lg form-control-solid'
                          value={formState.capturedDate}
                          onChange={handleDateChange}
                          renderInput={(params) => <TextField {...params} disabled />}
                        />
                      </Stack>
                    </LocalizationProvider> */}
                  </div>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Card Number</strong>
                    </label>
                    <input
                      type='string'
                      class='form-control form-control-solid'
                      name='cardNumber'
                      id='cardNumber'
                      value={formState?.cardNumber}
                      // maxLength={16}
                      onKeyPress={(e) => {
                        if (e.target.value[0] == 3) {
                          //   setCardChannelValue('Visa')
                          console.log(`setCardChannelValue('Visa')`)
                        }
                        if (e.target.value.length === 20) {
                          e.preventDefault()
                        }
                      }}
                      //   value={} //todo
                      onChange={(e) => setFormState({...formState, cardNumber: e.target.value})}
                      placeholder='1111 2222 3333 4444 5555'
                      //   onBlur={handleBlur}
                      disabled
                    ></input>
                    {!formState?.cardNumber && errors.cardNumber && touched.cardNumber ? (
                      <p className='form-error text-danger my-3'>{errors.cardNumber}</p>
                    ) : null}
                  </div>
                </div>

                <div className='d-flex my-10'>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Customer Name</strong>
                    </label>
                    <input
                      type='text'
                      name='customerName'
                      id='customerName'
                      value={formState.customerName}
                      //   onBlur={handleBlur}
                      onChange={(e) => setFormState({...formState, customerName: e.target.value})}
                      class='form-control form-control-lg form-control-solid'
                      // placeholder=' Muhammad Juanid Ahmed'
                      // onChange={(e) => setCustomerName(e.target.value)}
                      readOnly={!formState.nativeBranchId ? false : true}
                    />
                    {errors.customerName && touched.customerName ? (
                      <p className='form-error text-danger my-1'>{errors.customerName}</p>
                    ) : null}
                  </div>

                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>CNIC</strong>
                    </label>
                    <input
                      type='number'
                      name='cnic'
                      class='form-control form-control-lg form-control-solid'
                      placeholder='4210178912345'
                      value={formState?.cnic}
                      maxLength={13}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 13) {
                          e.preventDefault()
                        }
                      }}
                      //   value={values.cnic}
                      onChange={(e) => setFormState({...formState, cnic: e.target.value})}
                      //   onBlur={handleBlur}
                      // onChange={(e) => setCnic(e.target.value)}
                    />
                    {!formState?.cnic && errors.cnic && touched.cnic ? (
                      <p className='form-error text-danger my-3'>{errors.cnic}</p>
                    ) : null}
                    {/* {errors.cnic && touched.cnic ? (
                      <p className='form-error text-danger my-3'>{errors.cnic}</p>
                    ) : null} */}
                  </div>

                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Card Expiry</strong>
                    </label>
                    <input
                      type='text'
                      name='cardExpiry'
                      id='cardExpiry'
                      maxLength={4}
                      class='form-control form-control-lg form-control-solid'
                      placeholder='0223'
                      value={formState.cardExpiry}
                      //   onBlur={handleBlur}
                      onChange={(e) => setFormState({...formState, cardExpiry: e.target.value})}
                      onKeyDown={(e) => {
                        const currentDate = new Date()

                        if (e.target.value.length <= 4) {
                          console.log('checkk')
                          const userInputMonth = parseInt(e.target.value.substring(0, 2))
                          const userInputYear = parseInt('20' + e.target.value.substring(2))
                          if (
                            userInputYear < currentDate.getFullYear() ||
                            (userInputYear === currentDate.getFullYear() &&
                              userInputMonth < currentDate.getMonth() + 1)
                          ) {
                            console.log('Expired. Format: mmyy')
                            // setCurrentCardStatusId('rL6BbdV1e')
                            console.log(`setCurrentCardStatusId('rL6BbdV1e')`)
                          } else {
                            console.log('NOT Expired.')
                            // setCurrentCardStatusId('')
                            console.log(`setCurrentCardStatusId('')`)
                          }
                        }

                        // extract month and year from user input date
                      }}
                      readOnly
                    />
                    {errors.cardExpiry && touched.cardExpiry ? (
                      <p className='form-error text-danger my-1'>{errors.cardExpiry}</p>
                    ) : null}
                  </div>
                </div>
                {formState.nativeBranchId && (
                  <>
                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={'Samba Bank'}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Branch</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Branch Name'
                          value={formState.capturedBranchName}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring City</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={formState.acquirerBranchCity} //todo
                          readOnly
                          //   onChange={(e) => console.log(e.target.value, 'acq city')}
                        />
                      </div>
                    </div>

                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Issuer Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='issueBank'
                          id='issueBank'
                          value={'Samba Bank'} //todo
                          // onBlur={handleBlur}
                          // onChange={(e) => console.log(e.target.value, 'issuer bank')}
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          readOnly
                          // onChange={(e) => setIssuerBank(e.target.value)}
                        />
                        {/* <select
                      name='issuerBank'
                      //   onChange={(e) => {
                      //     let temp = e.target.value.split(',')
                      //     setCurrentnativeBranchId(temp[0])
                      //     setIssuerCity(temp[1])
                      //   }}

                      data-select2-id='select2-data-121-2l6u'
                      tabIndex='-1'
                      aria-hidden='true'
                      className='form-select form-select-solid form-select-lg '
                      data-control='select2'
                      id='issuerData'
                      //   value={formState?.issueBank}
                      onChange={(e) => setFormState({...formState, issuerBankId: e.target.value})}
                    >
                      <option selected hidden>
                        Select Branch
                      </option>
                      {bankList &&
                        bankList.map((item, index) => {
                          return (
                            <>
                              <option value={`${item.id}`}>{item.bank}</option>
                            </>
                          )
                        })}
                    </select> */}
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Issuer Branch</strong>
                        </label>
                        <input
                          type='text'
                          name='issueBranch'
                          id='issueBranch'
                          value={formState.nativeBranchName} //todo
                          // onBlur={handleBlur}
                          // onChange={(e) => console.log(e.target.value, 'issuer bank')}
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          readOnly
                          // onChange={(e) => setIssuerBank(e.target.value)}
                        />
                        {/* <select
                        name='issueBranch'
                        onChange={(e) => console.log(e.target.value, 'issuer branch')}
                        data-select2-id='select2-data-121-2l6u'
                        tabIndex='-1'
                        aria-hidden='true'
                        className='form-select form-select-solid form-select-lg '
                        data-control='select2'
                        id='issuerData'
                        value={formState.nativeBranchId}
                      >
                        <option selected hidden>
                          Select Branch
                        </option>
                        {/* {issuerBranch &&
                        issuerBranch.map((item, index) => {
                          return (
                            <>
                              <option value={`${item.id},${item.city}`}>{item.branchName}</option>
                            </>
                          )
                        })} */}
                        {/* </select> */}
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1 required' className='form-label required'>
                          <strong>Issuer City</strong>
                        </label>
                        <input
                          type='text'
                          name='issuerCity'
                          id='issuerCity'
                          value={formState.issuerBranchCity}
                          // onChange={(e) => console.log(e.target.value, 'issuer city')}
                          readOnly
                          class='form-control form-control-lg form-control-solid'
                        />
                      </div>
                    </div>
                  </>
                )}

                {formState.cardOwnerBankName && (
                  <>
                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={'Samba Bank'}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Branch</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Branch Name'
                          value={formState.capturedBranchName}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring City</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={formState.acquirerBranchCity} //todo
                          readOnly
                          //   onChange={(e) => console.log(e.target.value, 'acq city')}
                        />
                      </div>
                    </div>

                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Issuer Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='issueBank'
                          id='issueBank'
                          value={formState.cardOwnerBankName && formState.cardOwnerBankName} //todo
                          // onBlur={handleBlur}
                          // onChange={(e) => console.log(e.target.value, 'issuer bank')}
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          readOnly
                          // onChange={(e) => setIssuerBank(e.target.value)}
                        />
                        {!formState.cardOwnerBankName && (
                          <>
                            <select
                              name='issuerBank'
                              //   onChange={(e) => {
                              //     let temp = e.target.value.split(',')
                              //     setCurrentnativeBranchId(temp[0])
                              //     setIssuerCity(temp[1])
                              //   }}

                              data-select2-id='select2-data-121-2l6u'
                              tabIndex='-1'
                              aria-hidden='true'
                              className='form-select form-select-solid form-select-lg '
                              data-control='select2'
                              id='issuerData'
                              //   value={formState?.issueBank}
                              onChange={(e) =>
                                setFormState({...formState, cardOwnerBankId: e.target.value})
                              }
                            >
                              <option selected hidden>
                                Select Branch
                              </option>
                              {bankList &&
                                bankList.map((item, index) => {
                                  return (
                                    <>
                                      <option value={`${item.id}`}>{item.bank}</option>
                                    </>
                                  )
                                })}
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {formState.organization && (
                  <>
                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={'Samba Bank'}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring Branch</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Branch Name'
                          value={formState.capturedBranchName}
                          readOnly
                        />
                      </div>

                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Acquiring City</strong>
                        </label>
                        <input
                          type='text'
                          name='company'
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          value={formState.acquirerBranchCity} //todo
                          readOnly
                        />
                      </div>
                    </div>

                    <div className='d-flex my-10'>
                      <div className='me-3' id='w-500'>
                        <label for='exampleInputEmail1' className='form-label required'>
                          <strong>Issuer Bank</strong>
                        </label>
                        <input
                          type='text'
                          name='issueBank'
                          id='issueBank'
                          value={formState.organization && formState.organization} //todo
                          // onBlur={handleBlur}
                          // onChange={(e) => console.log(e.target.value, 'issuer bank')}
                          class='form-control form-control-lg form-control-solid'
                          placeholder='Bank Name'
                          readOnly
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className='d-flex my-5'>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>Card Type</strong>
                    </label>
                    <select
                      name='cardType'
                      id='cardType'
                      value={formState.cardTypeId}
                      // defaultValue={cardChannelValue}
                      // onBlur={handleBlur}
                      onChange={(e) => setFormState({...formState, cardTypeId: e.target.value})}
                      class='form-select form-select-solid'
                      data-control='select2'
                      data-placeholder='Select Card Type'
                      data-hide-search='true'
                    >
                      <option hidden>Select Card Type</option>
                      {cardTypes &&
                        cardTypes.map((item, index) => {
                          return (
                            <>
                              <option value={item.id}>{item.description}</option>
                            </>
                          )
                        })}
                    </select>
                  </div>

                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>CIF</strong>
                    </label>
                    <input
                      type='number'
                      onKeyPress={(e) => {
                        console.log(e.target.value.length)
                        if (e.target.value.length === 6) {
                          e.preventDefault()
                        }
                      }}
                      name='cif'
                      id='cif'
                      value={formState.cifNumber}
                      class='form-control form-control-lg form-control-solid'
                      placeholder='12345'
                      //   value={values.cif} //todo
                      //   onBlur={handleBlur}
                      //   onChange={handleChange}
                      onChange={(e) => setFormState({...formState, cifNumber: e.target.value})}
                      // onclick = {() => (change)}
                      // onChange={(e) =>
                      //   setCIFNumber(e.target.value)
                      //   (change)
                      // }
                      disabled
                    />
                  </div>
                </div>

                <div className='d-flex my-10'>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>Reason For Captured</strong>
                    </label>
                    <input
                      type='text'
                      name='captureReason'
                      id='captureReason'
                      value={formState.reasonForCaptured}
                      // onBlur={handleBlur}
                      // onChange={handleChange}
                      //   readOnly
                      class='form-control form-control-lg form-control-solid'
                      placeholder='Reason For Captured'
                      //   onChange={(e) => SetReasonForCaptured(e.target.value)}
                      // value={'Annonymouse'}
                      reasonForCaptured
                      onChange={(e) =>
                        setFormState({...formState, reasonForCaptured: e.target.value})
                      }
                      disabled
                    />
                  </div>

                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Card Status</strong>
                    </label>
                    <select
                      name='cardStatusId'
                      id='cardStatusId'
                      onChange={(e) => setFormState({...formState, cardStatusId: e.target.value})}
                      //   disabled={currentCardStatusId && true}
                      class='form-select form-select-solid'
                      data-control='select2'
                      data-placeholder='Select Card Type'
                      data-hide-search='true'
                      value={formState.cardStatusId}
                      disabled
                    >
                      {cardStatus &&
                        cardStatus.map((item, index) => {
                          return <>{<option value={item.id}>{item.description}</option>}</>
                        })}
                    </select>
                    {errors.cardStatus && touched.cardStatus ? (
                      <p className='form-error text-danger my-1'>{errors.cardStatus}</p>
                    ) : null}
                  </div>
                </div>

                <div className='d-flex ' style={{marginBottom: '5rem'}}>
                  <div className='me-3' id='w-500'>
                    <label for='exampleInputEmail1' className='form-label required'>
                      <strong>Note</strong>
                    </label>
                    <textarea
                      class='form-control form-control-solid'
                      name='note'
                      id='note'
                      aria-label='With textarea'
                      spellcheck='false'
                      value={formState.notes}
                      //   onChange={handleChange}
                      onChange={(e) => setFormState({...formState, notes: e.target.value})}
                      //   onBlur={handleBlur}
                    ></textarea>
                    {errors.notes && touched.notes ? (
                      <p className='form-error text-danger my-3'>{errors.notes}</p>
                    ) : null}
                  </div>
                </div>

                <button
                  onClick={(value) => {
                    values && formSubmit(values)
                    console.log(value, 'values')
                  }}
                  type='onSubmit'
                  class='btn btn-primary btn-sm'
                  style={{float: 'right', marginBottom: '2rem'}}
                >
                  Submit
                </button>
              </form>
            </div>
            <div className=' my-3 mb-5'>{/* <CityTable data={country} type="country" /> */}</div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}

export default EditFormInventory
