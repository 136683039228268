import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const BranchTable = ({
  data,
  type,
  PageSize,
  getDataById,
  PageNumber,
  pageNumber,
  pageSize,
  totalRecords,
  deleteRecord = () => {},
}) => {
  // const [data, setData] = useState()

  console.log(`DATAA ${type}===========`, data)

  const handleClick = (event, cellValues) => {
    console.log(cellValues)
  }

  const banks = []
  const organization = []
  const branches = []
  const provinces = []
  const regions = []
  const atms = []
  const tats = []
  const cardTypes = []
  const cardChannel = []

  let user = GetCurrentUser()

  const bankColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'bank', headerName: 'Bank', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'bank')
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ]

  const OrganizationColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'organization', headerName: 'Organization Name', width: 100},
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'organization')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]
  const branchColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'bank', headerName: 'Bank Name', width: 150},
    {field: 'branch', headerName: 'Branch Name', width: 180},
    {field: 'managerName', headerName: 'Manager Name', width: 180},
    {field: 'city', headerName: 'City', width: 100},
    {field: 'branchCode', headerName: 'Branch Code', width: 100},
    {field: 'branchAccountNumber', headerName: 'Branch Account Number', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'branch')
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ]

  const atmColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'branch', headerName: 'Branch Name', width: 180},
    {field: 'atmCode', headerName: 'ATM Code', width: 70},
    {field: 'atmip', headerName: 'ATM IP', width: 100},
    {field: 'isOffSite', headerName: 'Is OffSite', width: 70},
    {field: 'atmAccountNumber', headerName: 'ATM Account Number', width: 130},
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'atm')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const TatColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'duration', headerName: 'TAT Duration', width: 180},
    {field: 'interCity', headerName: 'Inter City', width: 180},
    {field: 'intraCity', headerName: 'Intra City', width: 180},
    {field: 'remoteBranch', headerName: 'Remote Branch', width: 180},
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'tat')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const cardTypeColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'description', headerName: 'Description', border: '2px solid black'},
    {field: 'isActive', headerName: 'IsActive', border: '2px solid black'},
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'cardType')
            }}
          >
            Edit
          </Button>
          <Button
            className='ms-3'
            variant='contained'
            color='error'
            size='small'
            onClick={() => {
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              // AddButton(false)
              if (userConfirmed) {
                deleteRecord(e.row.id)
              }
              console.log(e.row.id, 'id of record')
            }}
            // disabled={e.row.userName === user.first_name ? true : false}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const cardChannelColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'description', headerName: 'Description', border: '2px solid black'},
    {field: 'isActive', headerName: 'IsActive', border: '2px solid black'},
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'cardChannel')
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ]

  if (type == 'bank') {
    data?.forEach((element, index) => {
      banks.push({
        id: element.id,
        SNo: index + 1,
        bank: element.bank,
      })
    })
  }

  if (type == 'organization') {
    data?.forEach((element, index) => {
      organization.push({
        id: element.id,
        SNo: index + 1,
        organization: element.organization,
      })
    })
  }

  if (type == 'branch') {
    data?.forEach((element, index) => {
      branches.push({
        id: element.id,
        SNo: index + 1,
        bank: element.bank,
        branch: element.branchName,
        managerName: element.managerName,
        city: element.city,
        branchCode: element.branchCode,
        branchAccountNumber: element.branchAccountNumber,
      })
    })
  }

  if (type == 'atm') {
    data?.forEach((element, index) => {
      atms.push({
        id: element.id,
        SNo: index + 1,
        branch: element.branch,
        atmCode: element.atmCode,
        atmContactNumber: element.atmContactNumber,
        assetNumber: element.assetNumber,
        isOffSite: element.isOffSite,
        atmip: element.atmip,
        atmAccountNumber: element.atmAccountNumber,
      })
      // console.log(element, 'element')
    })
  }

  if (type == 'tat') {
    data?.forEach((element, index) => {
      tats.push({
        id: element.id,
        SNo: index + 1,
        duration: element.duration,
        interCity: element.interCity ? 'YES' : 'NO',
        intraCity: element.intraCity ? 'YES' : 'NO',
        remoteBranch: element.remoteBranch ? 'YES' : 'NO',
      })
      // console.log(element, 'element')
    })
  }

  if (type === 'cardType') {
    data?.forEach((element, index) => {
      cardTypes.push({
        id: element.id,
        SNo: index + 1,
        description: element.description,
        isActive: element.isActive,
      })
    })
  }

  if (type === 'cardChannel') {
    data?.forEach((element, index) => {
      cardChannel.push({
        id: element.id,
        SNo: index + 1,
        description: element.description,
        isActive: element.isActive,
      })
    })
  }

  console.log(cardTypes, 'helloo')

  return (
    <div style={{height: 420, width: '100%'}}>
      {type == 'bank' && (
        <DataGrid
          rows={banks}
          columns={bankColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}
      {type == 'branch' && (
        <DataGrid
          rows={branches}
          columns={branchColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}
      {type == 'organization' && (
        <DataGrid
          rows={organization}
          columns={OrganizationColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}
      {type === 'atm' && (
        <DataGrid
          rows={atms}
          columns={atmColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type === 'tat' && (
        <DataGrid
          rows={tats}
          columns={TatColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type === 'cardType' && (
        <DataGrid
          rows={cardTypes}
          columns={cardTypeColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}

      {type === 'cardChannel' && (
        <DataGrid
          rows={cardChannel}
          columns={cardChannelColumns}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          // getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          showCellRightBorder={true}
        />
      )}
      {/* 
      {type == 'province' && (
        <DataGrid rows={provinces} columns={provinceColumns} showCellRightBorder={true} />
      )}

      {type == 'region' && (
        <DataGrid rows={regions} columns={regionColumns} showCellRightBorder={true} />
      )} */}
    </div>
  )
}

export default BranchTable
