import React, {useState, useEffect} from 'react'
import CardSearch from '../../../../SharedComponent/CardSearch/CardSearch'
import {useFormik} from 'formik'
import {addNewCardCourierDetail, addNewCardRiderDetail} from '../../../../../schemas'
import {useDispatch, useSelector} from 'react-redux'
import CFCSlice, {AddCardCFC, setResetState} from '../../Slices/CFC.slice'
import {
  convertISODateWithSlashed,
  formatAMPM,
  GetCurrentUser,
} from '../../../../../utils/DefaultFunctions'
import {API} from '../../../../../utils/services'
import {sameBank} from '../../../../../schemas'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {STRINGS} from '../../../../../utils/Enums'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'

const AddCFCForm = () => {
  // const [date, setDate] = useState(dayjs('2022-12-26T00:00:00'))
  const [dateReceiving, setDateReceiving] = useState(dayjs('2022-12-26T00:00:00'))

  const {cfcSlice} = useSelector(({Reducers}) => Reducers)
  const [expiryState, setExpiryState] = useState()
  const [number, setNumber] = useState()

  const [date, setDate] = useState(dayjs().format())
  const [dateSelected, setDateSelected] = useState(false)
  const [convertedDate, setConvertedDate] = useState(dayjs('2023-01-04T00:00:00'))

  // const handleReceivingDateChange = (newValue) => {
  //   setDateReceiving(newValue)
  // }
  const dispatch = useDispatch()

  const initialValues = {
    acq_bank: '',
    acq_branch: '',
    note: '',
    dispatchTime: '',
    dispatchTimeRider: '',
    // cardNumber: '',
    // number: '',
    customerName: '',
    expiry: '',
    cnic: '',
    cardType: '',
    cif: '',
    cardStatus: '',
    reason: '',
    issueBank: '',
    issueBranch: '',
    captureDate: '',
    note: '',
  }

  let user = GetCurrentUser()

  const [init, setInit] = useState({
    selectedRec: 2,
    isSearchPending: false,
    captureDate: '',
    captureTime: '',
    receivingTime: '',
    receivingDate: '',
    expiryDate: '',
    acquirerBank: '',
    acquirerBranchId: '',
    acquirerCityName: '',
    // cardNumber: '',
    customerCIF: '',
    customerName: '',
    cardType: [],
    cnic: '',
    cardStatus: [],
    currentCardTypeId: '',
    acquirerBankId: '',
    currentCardChannelId: '',
    cardStatusId: '',
    issuerBankId: '',
    issuerBranchId: '',
    branchCity: '',
    issuerBranch: '',
    riderName: '',
  })

  const handleDateChange = (newValue) => {
    setDateSelected(true)

    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let selectedTime = formatAMPM(new Date(newValue))
    let convertedDateTime = new Date(`${selectedDate} ${selectedTime} UTC`).toISOString()
    setDate(newValue)
    setConvertedDate(convertedDateTime)
  }

  const [cardStatus, setCardStatus] = useState()
  // const [cardStatusId, setCardStatusId] = useState()
  const [cardTypes, setCardTypes] = useState()
  const [issuerCity, setIssuerCity] = useState()
  const [cardChannel, setCardChannel] = useState()
  const [issuerBranch, setIssuerBranch] = useState()
  const [dispatchTime, setDispatchTime] = useState()
  const [acquirerBank, setAcquirerBank] = useState()
  const [cfcId, setCfcId] = useState()
  const [currentCardStatusId, setCurrentCardStatusId] = useState('')

  const handleSwitchRecivedThrow = (val) => {
    setInit({
      ...init,
      selectedRec: val,
    })
  }
  let Bbody = {
    pageNumber: 1,
    pageSize: 100,
  }

  let navigate = useNavigate()

  useEffect(() => {
    dispatch(setResetState())

    if (user !== null) {
      API.get('/card/cardtypes', {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          setCardTypes(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/Card/cardchannels', {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          setCardChannel(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/card/cardstatus', {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          setCardStatus(res.data.result.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.post('/Bank/Search', Bbody, {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          console.log(res)
          setAcquirerBank(res.data.result.data.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.get('/CFC/getSambaCFC', {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          console.log(res.data)
          setCfcId(res.data.result.id)
        })
        .catch((err) => {
          console.log(err, 'err')
        })

      API.post('/Branch/Search', Bbody, {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      })
        .then((res) => {
          console.log(res)
          setIssuerBranch(res.data.result.data.$values)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [])

  useEffect(() => {
    if (cfcSlice.cardAddCFCResult) {
      navigate(`${STRINGS.ROUTES.CFC.MAIN}`)
      //reset func
    }
  }, [cfcSlice.cardAddCFCResult])

  let {
    selectedRec,
    currentCardTypeId,
    cardStatusId,
    currentCardChannelId,
    issuerBranchId,
    reason,
    acquirerBankId,
    acquirerBranchId,
    issuerBankId,
  } = init

  const {values, errors, touched, handleChange, handleBlur, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: selectedRec === 1 ? addNewCardCourierDetail : addNewCardRiderDetail,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const handleChangeExpiry = (e) => {
    console.log(e.target.value)
    setExpiryState(e.target.value)
    if (e.target.value.length !== 4) {
      setCurrentCardStatusId('')
    }
    const currentDate = new Date()
    if (e.target.value.length === 4) {
      console.log('checkk')
      const userInputMonth = parseInt(e.target.value.substring(0, 2))
      console.log(userInputMonth, 'checkk month')
      const userInputYear = parseInt('20' + e.target.value.substring(2))
      console.log(userInputYear, 'checkk year')
      if (
        userInputYear < currentDate.getFullYear() ||
        (userInputYear === currentDate.getFullYear() && userInputMonth < currentDate.getMonth() + 1)
      ) {
        console.log('Expired. Format: mmyy')
        setCurrentCardStatusId('rL6BbdV1e')
      } else {
        console.log('NOT Expired.')
        setCurrentCardStatusId('')
      }
    }
  }

  const onSubmitBtn = (values) => {
    let {expiry, customerName, cif, cnic, note} = values

    const receivingDateISO = dateReceiving.toISOString()

    let body = {
      convertedDate,
      receivingDateISO,
      customerName,
      number,
      expiryState: expiryState?.toString(),
      cif,
      cnic,
      cardStatusId,
      currentCardStatusId,
      currentCardTypeId,
      issuerBranchId,
      cfcId,
      currentCardChannelId,
      reason,
      acquirerBankId,
      acquirerBranchId,
      issuerBankId,
      note,
    }

    console.log(body, 'body')
    //validation for date
    if (!dateSelected) {
      toast.error('Select Captured Date!', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for card number
    if (!number) {
      toast.error('Card number is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (!(number?.length == 16) && !(number?.length == 20)) {
      console.log('another if')
      toast.error('Card Number must be of 16 or 20 digits', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for customer name
    if (!customerName) {
      toast.error('Customer name is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validations for card expiry
    if (!expiryState) {
      toast.error('Card Expiry is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length !== 4) {
      toast.error('Card Expiry is not correct', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (expiryState?.length == 4) {
      const firstTwoChars = expiryState?.slice(0, 2)
      if (firstTwoChars == '00') {
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
      if (Number(firstTwoChars) > 12) {
        console.log('ttt')
        toast.error('Month must be between one and twelve', {
          position: 'bottom-right',
          theme: 'colored',
        })
        return
      }
    }

    //validation for acquiring bank
    if (!acquirerBankId) {
      toast.error('Acquiring bank is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation is card status
    if (!currentCardStatusId) {
      toast.error('Card Status is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for acquiring branchId
    if (!issuerBankId) {
      toast.error('Issuer bank is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    //validation for issuer branchId
    if (!issuerBranchId) {
      toast.error('Issuer branch is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    if (!note) {
      toast.error('Notes is required', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return
    }

    dispatch(AddCardCFC(body, user.api_token))
    return

    // if (number.length > 0) {
    //   if (!number?.length === 16 || !number?.length === 20) {
    //     toast.error('Card number must be 16 or 20 digits', {
    //       position: 'bottom-right',
    //       theme: 'colored',
    //     })
    //     return
    //   }
    // }

    // if (
    //   customerName &&
    //   expiryState &&
    //   acquirerBankId &&
    //   currentCardStatusId &&
    //   issuerBankId &&
    //   issuerBranchId &&
    //   note
    // ) {
    //   const firstTwoChars = expiryState.slice(0, 2)

    //   if (firstTwoChars != '00') {
    //     dispatch(AddCardCFC(body, user.api_token))
    //   } else {
    //     toast.error('Month must be between one and twelve', {
    //       position: 'bottom-right',
    //       theme: 'colored',
    //     })
    //   }
    // } else {
    //   toast.error('Fill all mandatory fields!', {
    //     position: 'bottom-right',
    //     theme: 'colored',
    //   })
    // }
  }

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 10)

  console.log('addcfccard componene')

  return (
    <div class='card mb-5 mb-xl-10'>
      <div
        class='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        {/*<!--begin::Card title-->*/}
        <div class='card-title m-0'>
          <h3 class='fw-bold m-0'>Add Card (CFC)</h3>
        </div>
        <div class='card-toolbar'>
          {/*<!--begin::Menu-->*/}
          <div class='col-lg-'>
            {/*<!--begin::Search-->*/}
            <CardSearch />
            {/*<!--end::Search -->*/}
          </div>
          {/*<!--begin::Menu 2-->*/}

          {/*<!--end::Menu 2-->*/}
          {/*<!--end::Menu-->*/}
        </div>
        {/*<!--end::Card title-->*/}
      </div>
      {/*<!--begin::Content-->*/}
      <div
        className=''
        style={{backgroundColor: 'white', border: '1px solid #E4E4E4', borderRadius: '10px'}}
      >
        <div className='my-5' style={{marginLeft: '-2rem', padding: '25px'}}>
          <form class='form fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit}>
            <div className='d-flex mb-5'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Capture / Letter Date & Time</strong>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DateTimePicker
                      // label='Date&Time picker'
                      maxDate={date}
                      minDate={minDate}
                      className='form-control form-control-lg form-control-solid'
                      value={date}
                      // format="MM/dd/yyyy HH:mm"
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Card Number</strong>
                </label>
                <input
                  type='number'
                  class='form-control form-control-solid'
                  name='number'
                  id='number'
                  // maxLength={16}
                  maxLength={20}
                  onKeyPress={(e) => {
                    if (e.target.value.length === 20) {
                      e.preventDefault()
                    }
                  }}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder='1111 2222 3333 4444 5555'
                  onBlur={handleBlur}
                ></input>
              </div>
            </div>

            <div className='d-flex my-10'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Customer Name</strong>
                </label>
                <input
                  type='text'
                  name='customerName'
                  id='customerName'
                  value={values.customerName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  class='form-control form-control-lg form-control-solid'
                  placeholder=' Muhammad Juanid Ahmed'
                  // onChange={(e) => setCustomerName(e.target.value)}
                />
                {/* {errors.customerName && touched.customerName ? (
                  <p className='form-error text-danger my-1'>{errors.customerName}</p>
                ) : null} */}
              </div>

              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>CNIC</strong>
                </label>
                <input
                  type='number'
                  name='cnic'
                  class='form-control form-control-lg form-control-solid'
                  placeholder='4210178912345'
                  maxLength={13}
                  onKeyPress={(e) => {
                    if (e.target.value.length === 13) {
                      e.preventDefault()
                    }
                  }}
                  value={values.cnic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // onChange={(e) => setCnic(e.target.value)}
                />
                {/* {errors.cnic && touched.cnic ? (
                  <p className='form-error text-danger my-3'>{errors.cnic}</p>
                ) : null} */}
              </div>

              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Card Expiry</strong>
                </label>
                <input
                  type='number'
                  maxLength={4}
                  onKeyPress={(e) => {
                    if (e.target.value.length === 4) {
                      e.preventDefault()
                    }
                  }}
                  class='form-control form-control-lg form-control-solid'
                  placeholder='0223'
                  value={expiryState}
                  onChange={handleChangeExpiry}
                />
              </div>
            </div>

            <div className='d-flex my-10'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Acquiring Bank</strong>
                </label>
                <select
                  name='cardStatus'
                  id='cardStatus'
                  // value={values.cardStatus}
                  onChange={(e) => setInit({...init, acquirerBankId: e.target.value})}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  class='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select Card Type'
                  data-hide-search='true'
                >
                  <option selected hidden>
                    Select Acquirer Bank
                  </option>
                  {acquirerBank &&
                    acquirerBank.map((item, index) => {
                      return (
                        <>
                          <option value={item.id}>{item.bank}</option>
                        </>
                      )
                    })}
                </select>
              </div>
            </div>

            <div className='me-3' id='w-500'>
              <label for='exampleInputEmail1' className='form-label required'>
                <strong>Card Status</strong>
              </label>
              <select
                name='cardStatus'
                id='cardStatus'
                onChange={(e) => setCurrentCardStatusId(e.target.value)}
                // disabled={currentCardStatusId && true} why disabled?
                class='form-select form-select-solid'
                data-control='select2'
                data-placeholder='Select Card Type'
                data-hide-search='true'
              >
                {currentCardStatusId ? (
                  <option selected hidden>
                    Expired
                  </option>
                ) : (
                  <option selected hidden>
                    Select Card Status
                  </option>
                )}
                {cardStatus &&
                  cardStatus.map((item, index) => {
                    return (
                      <>
                        {!item.description.includes('xpire') && (
                          <option value={item.id}>{item.description}</option>
                        )}
                      </>
                    )
                  })}
              </select>
              {/* {errors.cardStatus && touched.cardStatus ? (
                <p className='form-error text-danger my-1'>{errors.cardStatus}</p>
              ) : null} */}
            </div>
            <div className='d-flex my-10'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Issuer Bank</strong>
                </label>
                <select
                  name='issueBranch'
                  // value={values.issueBranch}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => setInit({...init, issuerBankId: e.target.value})}
                  // onChange={(e) => {
                  //   setIssuerData(e.target.value)
                  //   console.log(e)
                  // }}
                  class='form-select form-select-solid'
                  data-control='select2'
                  id='issuerData'
                  // data-placeholder='Select Card Type'
                  data-hide-search='true'
                >
                  <option selected hidden>
                    Select Bank
                  </option>
                  {acquirerBank &&
                    acquirerBank.map((item, index) => {
                      return (
                        <>
                          {item.bank.includes('amba') && (
                            <option value={item.id}>{item.bank}</option>
                          )}
                        </>
                      )
                    })}
                </select>
              </div>

              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Issuer Branch</strong>
                </label>
                <select
                  name='issueBranch'
                  id='issueBranch'
                  // value={values.issueBranch}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => {
                    let temp = e.target.value.split(',')
                    setInit({...init, issuerBranchId: temp[0]})
                    setIssuerCity(temp[1])
                    console.log(temp)
                  }}
                  class='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select Card Type'
                  data-hide-search='true'
                >
                  <option selected hidden>
                    Select Branch
                  </option>
                  {issuerBranch &&
                    issuerBranch.map((item, index) => {
                      return (
                        <>
                          <option value={`${item.id},${item.city}`}>{item.branchName}</option>
                        </>
                      )
                    })}
                </select>
              </div>

              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1 required' className='form-label required'>
                  <strong>Issuer City</strong>
                </label>
                <input
                  type='text'
                  // name='cif'
                  // id='cif'
                  class='form-control form-control-lg form-control-solid'
                  value={issuerCity}
                  // onChange={handleChange}
                  placeholder='Issuer City'
                  // onBlur={handleBlur}
                />

                {/* <select
                  // name='cardType'
                  // id='cardType'
                  // value={values.cardType}
                  onChange={(e) => setInit({...init, branchCity: e.target.value})}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  class='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select Card Type'
                  data-hide-search='true'
                >
                  <option selected hidden>
                    Select Issuer Branch City
                  </option>
                  <option value=''>Karachi</option>
                </select> */}
              </div>
            </div>

            <div className='d-flex my-5'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>Card Type</strong>
                </label>
                <select
                  name='cardType'
                  id='cardType'
                  // value={values.cardType}
                  onChange={(e) => setInit({...init, currentCardChannelId: e.target.value})}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  class='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select Card Type'
                  data-hide-search='true'
                >
                  <option selected hidden>
                    Select Card Type
                  </option>
                  {cardChannel &&
                    cardChannel.map((item, index) => {
                      return (
                        <>
                          <option value={item.id}>{item.description}</option>
                        </>
                      )
                    })}
                </select>
              </div>

              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>CIF</strong>
                </label>
                <input
                  type='number'
                  name='cif'
                  id='cif'
                  class='form-control form-control-lg form-control-solid'
                  maxLength={6}
                  onKeyPress={(e) => {
                    console.log(e.target.value.length)
                    if (e.target.value.length === 6) {
                      e.preventDefault()
                    }
                  }}
                  value={values.cif}
                  onChange={handleChange}
                  placeholder='xxxxxx'
                  onBlur={handleBlur}
                />
              </div>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label'>
                  <strong>Reason For Captured</strong>
                </label>
                <select
                  name='reasonForCaptured'
                  id='reasonForCaptured'
                  // value={values.reasonForCaptured}
                  // value={values.issueBranch}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => setInit({...init, reason: e.target.value})}
                  class='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select Card Type'
                  data-hide-search='true'
                  disabled
                >
                  {/* <option hidden>
                    {' '}
                    Select Reason
                  </option> */}
                  <option value='Anonymous' selected>
                    Anonymous
                  </option>
                  {/* <option>Testing Card</option> */}
                </select>
              </div>
            </div>

            <div className='d-flex my-5'>
              <div className='me-3' id='w-500'>
                <label for='exampleInputEmail1' className='form-label required'>
                  <strong>Note</strong>
                </label>
                <textarea
                  class='form-control form-control-solid'
                  name='note'
                  id='note'
                  aria-label='With textarea'
                  spellcheck='false'
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {/* {errors.note && touched.note ? (
                  <p className='form-error text-danger my-3'>{errors.note}</p>
                ) : null} */}
              </div>
            </div>

            <button
              type='onSubmit'
              class='btn btn-primary btn-sm'
              onClick={() => values && onSubmitBtn(values)}
              style={{float: 'right', marginBottom: '1rem'}}
            >
              Submit
            </button>
          </form>
        </div>
        <div className=' my-3 mb-5'>{/* <CityTable data={country} type="country" /> */}</div>
      </div>
      {/*<!--end::Content-->*/}
      <ToastContainer />
    </div>
  )
}

export default AddCFCForm
