import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {cardHoldSchema} from '../../../../../schemas'
import {useDispatch, useSelector} from 'react-redux'
import {CardHoldModal} from '../../Slices/CFC.slice'
import {useNavigate} from 'react-router-dom'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'

const initialValues = {
  reason: '',
  days_to_hold: '',
}

let user = GetCurrentUser()

const CardHold = (props) => {
  const [init, setInit] = useState({
    cardNumber: '',
  })

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: cardHoldSchema,
    onSubmit: (values, action) => {
      // console.log("BUILT IN FUNC",user.api_token)

      dispatch(CardHoldModal({values: values, id: props.cardId.id, token: user.api_token}))
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.cardId !== '' && props.cardId !== null && props.cardId !== undefined) {
      setInit({
        ...init,
        cardNumber: props.cardId.cardNumber,
      })
    }
  }, [])

  const CardHoldSubmit = (values) => {
    dispatch(CardHoldModal({values: values, id: props.cardId.id, token: props.token}))
  }

  return (
    <div
      class='modal fade'
      id='exampleModal'
      tabindex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog'>
        <div class='modal-content'>
          <div class='modal-header' style={{backgroundColor: '#18A689'}}>
            <h1 class='modal-title fs-5' id='exampleModalLabel' style={{color: 'white'}}>
              Card Hold
            </h1>
            <button
              type='button'
              class='btn-close'
              style={{color: 'white'}}
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div class='modal-body'>
            {/* <!--begin::input--> */}
            <form onSubmit={handleSubmit}>
              <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label class='fs-6 fw-semibold form-label mb-2'>CARD NUMBER</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div class='position-relative'>
                  {/* <!--begin::Input--> */}
                  <input
                    type='text'
                    class='form-control form-control-solid'
                    placeholder=''
                    name='card_number'
                    value={props.cardId.cardNumber}
                  />
                  {/* <!--end::Input--> */}
                </div>
                <div class='fv-plugins-message-container invalid-feedback'></div>
              </div>
              {/* <!--end::Input--> */}

              {/* <!--begin input--> */}
              {/* <!--begin::Input group--> */}
              <div class='d-flex flex-column fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label class='fs-6 fw-semibold form-label mb-2'>Reason</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div class='position-relative'>
                  {/* <!--begin::Input--> */}
                  <textarea
                    class='form-control'
                    name='reason'
                    id='reason'
                    aria-label='With textarea'
                    placeholder='Write Reason Here...'
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.reason && touched.reason ? (
                    <p className='form-error text-danger my-3'>{errors.reason}</p>
                  ) : null}
                  {/* <!--end::Input--> */}
                </div>
                <div class='fv-plugins-message-container invalid-feedback'></div>
              </div>
              {/* <!--end::Input group--> */}
              {/* <!--end input--> */}
              {/* <!--begin::input--> */}
              <br />
              <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                {/* <!--begin::Label--> */}
                <label class='fs-6 fw-semibold form-label mb-2'>Days to Hold</label>
                {/* <!--end::Label--> */}
                {/* <!--begin::Input wrapper--> */}
                <div class='position-relative'>
                  {/* <!--begin::Input--> */}
                  <input
                    name='days_to_hold'
                    id='days_to_hold'
                    type='number'
                    class='form-control form-control-solid'
                    placeholder='Enter Days to Hold'
                    value={values.days_to_hold}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.days_to_hold && touched.days_to_hold ? (
                    <p className='form-error text-danger my-3'>{errors.days_to_hold}</p>
                  ) : null}
                  {/* <!--end::Input--> */}
                </div>
                <div class='fv-plugins-message-container invalid-feedback'></div>
              </div>
              {/* <!--end::Input--> */}
              <div class='modal-footer'>
                <button type='' class='btn btn-secondary' data-bs-dismiss='modal'>
                  Close
                </button>
                <button
                  onClick={() => CardHoldSubmit(values)}
                  type='onsubmit'
                  class='btn btn-primary'
                  data-bs-dismiss={values.days_to_hold && values.reason ? 'modal' : ''}
                  disabled={!values.days_to_hold ? true : false}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardHold
