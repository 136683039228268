import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {resetlogin} from '../../../../app/modules/auth/components/Slices/auth.slice'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {AUTH_LOCAL_STORAGE_KEY} from '../../../../app/modules/auth'
import {resetDashboardData} from '../../../../app/GlobalSlices/global.slice'

const HeaderUserMenu = () => {
  const {currentUser, logout} = useAuth()
  const dispatch = useDispatch()

  //function to check inactivity and logout
  const checkForInactivity = () => {
    // Get expire time from local storage
    const expireTime = localStorage.getItem('expireTime')

    // if expire time is earlier than now log out user
    if (expireTime < Date.now()) {
      console.log('user is Idle')
      // logout()
      document.location.href = '/auth'
      sessionStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
      // alert('idle user')
      // setLoggedIn(false)
    }
  }

  // function to update expire time
  const updateExpieTime = () => {
    //set expiretime to 1 hour from now
    const expireTime = Date.now() + 5 * 60 * 1000

    //set ecpire time in local storage
    localStorage.setItem('expireTime', expireTime)
  }

  // set interval to check for inactivity
  useEffect(() => {
    //check for inactivit ever 5 seconds
    const interval = setInterval(() => {
      checkForInactivity()
    }, 5 * 60 * 1000)

    //clear interval on unmount
    return () => clearInterval(interval)
  }, [])

  // update expire time on any user activity
  useEffect(() => {
    //set initial expire time
    updateExpieTime()

    //set event listners
    window.addEventListener('click', updateExpieTime)
    window.addEventListener('keypress', updateExpieTime)
    window.addEventListener('scroll', updateExpieTime)
    window.addEventListener('mousemove', updateExpieTime)

    //cleanup
    return () => {
      window.removeEventListener('click', updateExpieTime)
      window.removeEventListener('keypress', updateExpieTime)
      window.removeEventListener('scroll', updateExpieTime)
      window.removeEventListener('mousemove', updateExpieTime)
    }
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            logout()
            dispatch(resetlogin())
            dispatch(resetDashboardData())
          }}
          className='menu-link px-5'
        >
          Sign Out
        </a>
      </div>
    </div>
  )
}

export default HeaderUserMenu
