import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {STRINGS} from '../../utils/Enums'
import {GetCurrentUser} from '../../utils/DefaultFunctions'
import AddCFCForm from '../pages/CFC/CFCScreen/forms/AddCFCForm'
import AddFormInventory from '../pages/BranchMain/Inventory/forms/AddFormInventory'
import {useAppSelector} from '../../utils/hook'
import CreateGroup from '../pages/Administrator/configuration/GroupCreate'
import CardTimeline from '../pages/Administrator/configuration/CardsTimelne/CardTimeline'
import SambaCareHistory from '../pages/SambaCare/history/SambaCareHistory'
import AdminCalendar from '../pages/Administrator/configuration/Calender/AdminCalendar'
import CreateRoles from '../pages/Administrator/configuration/CreateRoles/CreateRoles'
import ConfigureOrganizationInfo from '../pages/Administrator/configuration/Organization'
import ConfigureTat from '../pages/Administrator/configuration/TatConfig'
import ConfigureCardType from '../pages/Administrator/configuration/CardTypes/index'
// import ConfigureCardChannel from '../pages/Administrator/configuration/CardChannel'
// import HistoryDefault from '../pages/History'

import UpdatInventoryForm from '../pages/BranchMain/Inventory/forms/EditFormInventory'

import SambaCheckerDashboard from '../pages/Samba Checker/Dashboard'

type Slices = {authSlice: any; branchSlice: any}

const PrivateRoutes = () => {
  const auth = GetCurrentUser()
  // const ProcessDetailPage = lazy(() => import('../modules/processDetail/ProcessDetailPage'))
  const {branchSlice} = useAppSelector(({Reducers}) => Reducers as Slices)

  //Management Screens
  const ManagementDashboard = lazy(() => import('../pages/Management'))
  const ManagementDefault = lazy(() => import('../pages/Management/ManagementScreen'))

  // CFC Screens
  const CFCDashboard = lazy(() => import('../pages/CFC'))
  const BranchDefault = lazy(() => import('../pages/Branch'))
  const CFCDefault = lazy(() => import('../pages/CFC/CFCScreen'))
  const HistoryDefault = lazy(() => import('../pages/History'))
  const SettingsDefault = lazy(() => import('../pages/Settings'))
  const AddCardCFC = lazy(() => import('../pages/CFC/CFCScreen/forms/AddCFCForm'))
  const AtmCapturedCard = lazy(() => import('../pages/CFC/Inventory/InventoryDefault'))

  // Branch Screens
  const BranchMainDashboard = lazy(() => import('../pages/BranchMain'))
  const BranchMain = lazy(() => import('../pages/BranchMain/BranchScreen/index'))
  const InventoryDefault = lazy(() => import('../pages/BranchMain/Inventory/InventoryDefault'))
  const EditForm = lazy(() => import('../pages/BranchMain/Inventory/forms/EditForm'))
  const BranchCalendar = lazy(() => import('../pages/BranchMain/BranchCalendar/BranchCalendar'))
  {
    /*added by jahanzaib*/
  }
  const ShredForm = lazy(() => import('../pages/CFC/Forms/ShredForm'))
  {
    /*added by jahanzaib*/
  }
  const DeliveryForm = lazy(() => import('../pages/CFC/Forms/DeliveryForm'))
  {
    /*added by jahanzaib*/
  }
  const SendToCFC = lazy(() => import('../pages/CFC/Forms/SendToCFC'))
  {
    /*added by jahanzaib*/
  }
  const SendToBranch = lazy(() => import('../pages/CFC/Forms/SendToBranch'))
  {
    /*added by jahanzaib*/
  }
  const Branch = lazy(() => import('../pages/BranchMain/BranchScreen/index'))
  {
    /*added by jahanzaib*/
  }
  const InventoryAddCard = lazy(
    () => import('../pages/BranchMain/Inventory/forms/AddFormInventory')
  )
  {
    /*added by jahanzaib*/
  }

  // console.log('GetCurrentUser', auth)

  // ADMINISTRATOR
  const AdminDashboard = lazy(() => import('../pages/Administrator/Dashboard'))
  const ConfigureInfo = lazy(
    () => import('../pages/Administrator/configuration/CountryConfig/index')
  )
  const ConfigureUserInfo = lazy(
    () => import('../pages/Administrator/configuration/UserConfig/index')
  )
  const ConfigureBranchInfo = lazy(
    () => import('../pages/Administrator/configuration/BranchConfig/index')
  )
  //SAMBACARE
  const SambaCare = lazy(() => import('../pages/SambaCare/Main Screen/index'))

  //Checker
  const CheckerDashboard = lazy(() => import('../pages/Checker/Dashboard/index'))
  const CheckerMainScreen = lazy(() => import('../pages/Checker/item'))
  const ForceTask = lazy(() => import('../pages/Checker/Processes/ForceTask'))
  const CardNotFound = lazy(() => import('../pages/Checker/Processes/NotCardFound'))
  const CardNotFoundApproved = lazy(() => import('../pages/Checker/Processes/CardNotFoundApproved'))
  const CheckerHistory = lazy(() => import('../pages/Checker/Processes/History'))
  const CourierChannelMain = lazy(
    () => import('../pages/Administrator/configuration/CourierChannel/CourierChannel')
  )
  const OnApproval = lazy(() => import('../pages/Administrator/configuration/ForApproval/OnApproval'))

  // import Dashboard from

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='dashboard' element={<DashboardWrapper/>}/> */}
        {auth.type === 'cfc' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.DASHBOARD}`}
              element={
                <SuspensedView>
                  <CFCDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH_SCREEN}`}
              element={
                <SuspensedView>
                  <BranchDefault tableType={'cfcDefault'} />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.ATM_CAPTURED_CARD}`}
              element={
                <SuspensedView>
                  <AtmCapturedCard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC_SCREEN}`}
              element={
                <SuspensedView>
                  <CFCDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.HISTORY_SCREEN}`}
              element={
                <SuspensedView>
                  <HistoryDefault type='cfc' />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}`}
              element={
                <SuspensedView>
                  <CardNotFoundApproved />
                </SuspensedView>
              }
            />
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/:id`}
              element={
                <SuspensedView>
                  <ShredForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/:id`}
              element={
                <SuspensedView>
                  <DeliveryForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToCFC />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToBranch />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.CFC.ADDCARDCFC}`}
              element={
                <SuspensedView>
                  <AddCFCForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
          </>
        )}
        {auth.type === 'management' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.MANAGEMENT.MANAGEMENT_DASHBOARD}`}
              element={
                <SuspensedView>
                  <ManagementDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH_SCREEN}`}
              element={
                <SuspensedView>
                  <BranchDefault tableType='cfcDefault' />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.MANAGEMENT.MAIN}`}
              element={
                <SuspensedView>
                  <ManagementDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.HISTORY_SCREEN}`}
              element={
                <SuspensedView>
                  <HistoryDefault type='management' />
                </SuspensedView>
              }
            />

            {/*added by jahanzaib*/}
          </>
        )}
        {(auth.type === 'branch' || auth.type === 'branch maker') && (
          <>
            <Route
              path={`${STRINGS.ROUTES.DASHBOARD}`}
              element={
                <SuspensedView>
                  <BranchMainDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORY}`}
              element={
                <SuspensedView>
                  <InventoryDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}`}
              element={
                <SuspensedView>
                  <CardNotFoundApproved />
                </SuspensedView>
              }
            />
            {/* <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORY}`}
              element={
                <SuspensedView>
                  <InventoryDefault />
                </SuspensedView>
              }
            /> */}
            {/* <Route
              path={`${STRINGS.ROUTES.BRANCH.CALENDAR}/:id`}
              element={
                <SuspensedView>
                  <BranchCalendar />
                </SuspensedView>
              }
            />{' '} */}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORYADDCARD}`}
              element={
                <SuspensedView>
                  <AddFormInventory />
                </SuspensedView>
              }
            />{' '}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORYUPDATECARD}`}
              element={
                <SuspensedView>
                  <UpdatInventoryForm />
                </SuspensedView>
              }
            />
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/:id`}
              element={
                <SuspensedView>
                  <ShredForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/:id`}
              element={
                <SuspensedView>
                  <DeliveryForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToCFC />
                </SuspensedView>
              }
            />{' '}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToBranch />
                </SuspensedView>
              }
            />{' '}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.MAIN}`}
              element={
                <SuspensedView>
                  <BranchMain />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.CARDDETAILS}`}
              element={
                <SuspensedView>
                  <Branch />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.HISTORY}`}
              element={
                <SuspensedView>
                  <HistoryDefault type='branch' />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.CALENDAR}/:id`}
              element={
                <SuspensedView>
                  <BranchCalendar />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'administrator' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.ADMIN}`}
              element={
                <SuspensedView>
                  <AdminDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.ON_APPROVAL}`}
              element={
                <SuspensedView>
                  <OnApproval />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.COURIER_CHANNEL}`}
              element={
                <SuspensedView>
                  <CourierChannelMain />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CALENDER}`}
              element={
                <SuspensedView>
                  <AdminCalendar />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureUserInfo />
                </SuspensedView>
              }
            />
            {/* <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CARD_EMBOSE_CONFIG}`}
              element={
                <SuspensedView>
                  <CardEmboseConfig />
                </SuspensedView>
              }
            /> */}
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureBranchInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_ORGANIZATION_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureOrganizationInfo />
                </SuspensedView>
              }
            />

            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.TAT}`}
              element={
                <SuspensedView>
                  <ConfigureTat />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CARD_TYPE}`}
              element={
                <SuspensedView>
                  <ConfigureCardType />
                </SuspensedView>
              }
            />
            {/* <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CARD_CHANNEL}`}
              element={
                <SuspensedView>
                  <ConfigureCardChannel />
                </SuspensedView>
              }
            /> */}
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_GROUP}`}
              element={
                <SuspensedView>
                  <CreateGroup />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_ROLES}`}
              element={
                <SuspensedView>
                  <CreateRoles />
                </SuspensedView>
              }
            />
          </>
        )}
        {['samba phone'].includes(auth.type) && (
          <>
            {/* <Route path={`${STRINGS.ROUTES.SAMBACARE.MAIN}`} element={<SuspensedView><SambaCare /></SuspensedView>} /> */}

            <Route
              path={`${STRINGS.ROUTES.SAMBACARE.MAIN}`}
              element={
                <SuspensedView>
                  <SambaCare />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.SAMBACARE.SAMBA_CARE_HISTORY}`}
              element={
                <SuspensedView>
                  <SambaCareHistory />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'branch checker' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.CHECKER.DASHBOARD}`}
              element={
                <SuspensedView>
                  <CheckerDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.INVENTORY}`}
              element={
                <SuspensedView>
                  <InventoryDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.INVENTORYADDCARD}`}
              element={
                <SuspensedView>
                  <AddFormInventory />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.MAIN_SCREEN}`}
              element={
                <SuspensedView>
                  <CheckerMainScreen />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.HISTORY}`}
              element={
                <SuspensedView>
                  <HistoryDefault type='branch' />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.FORCE_TASK}`}
              element={
                <SuspensedView>
                  <ForceTask />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.CARD_NOT_FOUND}`}
              element={
                <SuspensedView>
                  <CardNotFound />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BPC_CARD_NOT_FOUND_APPROVED}`}
              element={
                <SuspensedView>
                  <CardNotFoundApproved />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.HISTORY}`}
              element={
                <SuspensedView>
                  <CheckerHistory />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'sambaphone checker' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.SAMBACARE_CHECKER.DASHBOARD}`}
              element={
                <SuspensedView>
                  <SambaCheckerDashboard />
                </SuspensedView>
              }
            />
          </>
        )}
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.CARD_DES_MEMO}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK_CFC}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.CREATE_DESIGNATION}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.USER_MANAGER}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.GROUP_EMAIL}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path={`${STRINGS.ROUTES.ONE_LINK_PROCESS_DETAIL}/*`}
          element={
            <SuspensedView>
              <ProcessDetailPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='*'
          element={
            <Navigate
              to={`${
                auth.type === 'branch' || auth.type === 'branch maker'
                  ? `${STRINGS.ROUTES.DASHBOARD}?id=${branchSlice.captureBranchId}`
                  : auth.type === 'administrator'
                  ? `${STRINGS.ROUTES.ADMINISTRATOR.ADMIN}`
                  : auth.type === 'samba phone'
                  ? `${STRINGS.ROUTES.SAMBACARE.MAIN}`
                  : auth.type === 'cfc'
                  ? `${STRINGS.ROUTES.CFC.DASHBOARD}`
                  : auth.type === 'branch checker'
                  ? `${STRINGS.ROUTES.CHECKER.DASHBOARD}?id=${branchSlice.captureBranchId}`
                  : auth.type == 'sambaphone checker'
                  ? `${STRINGS.ROUTES.SAMBACARE_CHECKER.DASHBOARD}`
                  : `${STRINGS.ROUTES.MANAGEMENT.MANAGEMENT_DASHBOARD}`
              }`}
            />
          }
        />{' '}
        <Route
          path={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/:id`}
          element={
            <SuspensedView>
              <CardTimeline />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
