import React from 'react';
import Dashboard from './items/Dashboard';

// const tabs = ['Branch', 'Force Task']
const tabs = ['Samba Checker Dashboard']

const SambaCheckerDashboard = () => {

    return (
        <>
            <Dashboard tabs={tabs} />
        </>
    );
};

export default SambaCheckerDashboard;