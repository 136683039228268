import {useState} from 'react'
import {useAppSelector} from '../../../../utils/hook'
import {API} from '../../../../utils/services'
import {AuthModel} from './_models'

type Slices = {authSlice: any; globalSlice: any}

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  // const [expirationTime, setExpirationTime] = useState(1000);

  let expirationTime = 55 * 60 * 60
  // const { authSlice, globalSlice } = useAppSelector(({ Reducers }) => Reducers as Slices)

  if (!localStorage) {
    return
  }

  const lsValue: string | null = sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  // const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also

      console.log('hello')
      setInterval(() => {
        if (!auth.api_token || !expirationTime) return
        const currentTime = new Date().getTime()
        if (currentTime >= expirationTime - 30000) {
          //  refreshToken();
        }
      }, 55 * 60 * 60)

      const refreshToken = async () => {
        try {
          API.post('/Authentication/refreshtoken', {
            token: auth.api_token,
            refreshToken: auth.refreshToken,
          })
            .then((res) => {
              console.log(res.data)
              if (res.data.success) {
                auth.api_token = res.data.result.token
                auth.refreshToken = res.data.result.refreshToken

                setAuth(auth)
              }
              // setExpirationTime(data.expirationTime);
              // setToken(data.token);
            })
            .catch((err) => {
              console.log(err, 'err')
            })
        } catch (error) {
          console.error(error)
        }
      }

      console.log('auth', auth)

      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!sessionStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    console.log(lsValue, '======444')
    sessionStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    // localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!sessionStorage) {
    return
  }

  try {
    sessionStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    // localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
